import { useEffect } from 'react';

import { getMap } from '../utils/map/mapRef';

const styles = {
  map: { height: '100%', width: '100%' },
};

const MapContainer = () => {
  // load map into wrapper div
  useEffect(() => {
    const mapDiv = getMap().getDiv();
    document.getElementById('map-wrapper').appendChild(mapDiv);
  // }, [sidebar, utilityLegend, drawer]);
  }, []);

  return <div id="map-wrapper" style={styles.map} />;
};

export default MapContainer;
