import Box from '@mui/material/Box';

import Directions from './Directions';

const styles = {
  directions: {
    width: '100%',
    padding: '0px',
  },
  contentWrapper: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  link: {
    color: '#5f6a72',
    fontSize: '1.3em',
  },
};

const ParkingContent = ({ details }) => {
  const { directionsUrl } = details;
  let tabIndex = 5;
  return (
    <Box>
      <Box sx={styles.directions}>
        <Directions startIndex={1} url={directionsUrl} />
      </Box>
      <Box sx={styles.contentWrapper}>
        <p>
          <a
            href="http://rudots.rutgers.edu/"
            tabIndex={tabIndex++}
            aria-label="Open Rutgers University Department of Transportation Services site"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Rutgers University Department of Transportation Services
          </a>
        </p>
      </Box>
    </Box>
  );
};

export default ParkingContent;
