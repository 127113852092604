import { createAsyncThunk } from '@reduxjs/toolkit';
import { setPopularDestinations } from '../slices/popularDestinations';
import { openSnackbar } from '../slices/snackbar';
import loading from '../utils/loading';
import config from '../config';
import { getSyncIdAsync } from '../utils/getSyncId';

export const fetchPopularDestinations = createAsyncThunk(
  'popularDestinations/fetch',
  async (_, thunkAPI) => {
    const loadingMsg = 'fetching popular destinations';
    loading.start(loadingMsg);
    try {
      const syncId = await getSyncIdAsync();
      const url = `${config.syncDataBucket}/${syncId}/buildings/popular-destinations.json`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch popular destinations. Server responded with ${response.status}: ${response.statusText}`);
      }

      const destinations = await response.json();
      thunkAPI.dispatch(setPopularDestinations(destinations));
    } catch (e) {
      console.log('ERROR while fetching popular destinations:', e);
      thunkAPI.dispatch(openSnackbar({ msg: 'Sorry, we were unable to fetch popular destinations at this time. Please try again later.' }));
      throw e;
    } finally {
      loading.end(loadingMsg);
    }
  },
);
