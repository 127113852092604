let tokenPromise;
let expiration = 0;
let loading = false;

let rasterToken;

const TWO_MINUTES = 2 * 1000 * 60;

export const getRasterTokenQuery = (internal) => (internal ? `?token=${rasterToken}` : '');

const requestNewToken = async () => {
  loading = true;
  try {
    console.log('requesting new AGOL token');
    const { origin } = window.location;
    const { token, expires } = await fetch(`/api/getToken?referer=${origin}`).then((resp) => resp.json());
    expiration = expires;
    loading = false;
    // set token used for ImageMapType getTileUrl callback function
    rasterToken = token;
    // make sure to refresh the token before it expires
    const timeToRunAgain = expires - Date.now() - TWO_MINUTES;
    setTimeout(() => { tokenPromise = requestNewToken(); }, timeToRunAgain);
    return token;
  } catch (error) {
    console.error({ msg: 'Error fetching AGOL token', error });
  }
};

const THIRTY_SECONDS = 30 * 1000;

export default async function getAgolToken() {
  // wait for token (may be currently loading)
  let token = await tokenPromise;
  // check whether token is expired (add in safety net of 30 seconds)
  const expired = Date.now() >= (expiration - THIRTY_SECONDS);
  if (!expired && token) return token;
  // if expired or no token found, request new token
  if (!loading) {
    // set tokenPromise so that subsequent requests wait for a single promise to resolve
    tokenPromise = requestNewToken();
  }
  // wait for token request to resolve
  token = await tokenPromise;
  return token;
}
