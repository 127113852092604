import Button from '@mui/material/Button';
import selectFeature from '../../../../utils/selectFeature';

const styles = {
  heading: {
    background: '#cc0033',
    color: 'white',
    textAlign: 'center',
    width: '100%',
    borderRadius: 0,
    padding: '2px 5px',
  },
  root: {
    '&:hover': {
      color: 'black',
      background: 'rgba(204, 0, 51, .7)',
    },
  },
};

const ResultCampusHeading = ({ resetSearch, result }) => {
  const handleClick = () => {
    resetSearch();
    selectFeature({ campus: result.campus });
  };
  return (
    <Button
      sx={{ ...styles.heading, ...styles.root }}
      data-campus={result.campus}
      onClick={handleClick}
      aria-label={`Zoom to ${result.heading}`}
    >
      <strong>
        {result.heading}
      </strong>
    </Button>
  );
};

export default ResultCampusHeading;
