import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButtonToggle from './IconButtonToggle';

const CampusLife = () => (
  <Box>
    <Typography variant="h2" align="center">Campus Life</Typography>
    <Box display="flex" justifyContent="center">
      <IconButtonToggle
        id="dining"
        name="Dining"
        Icon={RestaurantIcon}
      />
      <IconButtonToggle
        id="retail"
        name="Retail"
        Icon={ShoppingCartIcon}
      />
    </Box>
  </Box>
);

export default CampusLife;
