import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import IconButtonToggle from './IconButtonToggle';
import BucIcon from './BUCIcon';

const BuildingsUnderConstruction = () => (
  <Box>
    <Typography variant="h2" fontSize="1.3rem" align="center">Buildings Under Construction</Typography>
    <Box display="flex" justifyContent="center">
      <IconButtonToggle
        id="underConstructionState"
        name="Buildings Under Construction"
        Icon={BucIcon}
        color="#fbcb06"
      />
    </Box>
  </Box>
);

export default BuildingsUnderConstruction;
