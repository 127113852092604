import { useDispatch, useSelector } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

import MultiFeatureSelector from './MultiFeatureSelector';

import { selectInfoDrawerFeatures } from '../../slices/infoDrawer';

import { getContrastColor } from '../../utils/accessibility/colorContrast';
import { closeDrawer } from '../../thunks/drawerClose';

const getStyles = ({ color, isMulti }) => ({
  appBar: {
    position: 'sticky',
    background: color,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    // due to when MUI resolves breakpoint styles, have use breakpoints to override minHeight
    minHeight: isMulti ? { xs: '0px' } : { xs: '48px' },
    padding: isMulti ? '5px 0 0 0' : '0px',
  },
  title: {
    flex: 1,
    color: getContrastColor(color),
    textAlign: 'center',
    padding: '0 55px',
  },
  closeButton: {
    position: 'absolute',
    right: '5px',
    width: '50px',
  },
});

const TitleBar = ({ color, name }) => {
  const dispatch = useDispatch();
  const features = useSelector(selectInfoDrawerFeatures);
  const isMulti = features && features.length > 1;
  const styles = getStyles({ color, isMulti });
  let tabIndex = 10;

  const handleClose = () => {
    dispatch(closeDrawer(null));
  };

  return (
    <AppBar sx={styles.appBar}>
      <Toolbar sx={styles.toolbar}>
        <Typography variant="h6" color="inherit" sx={styles.title}>
          {name}
        </Typography>
        <Tooltip title="Close" sx={styles.closeButton}>
          <IconButton
            color="inherit"
            onClick={handleClose}
            aria-label="Close Info Panel"
            id="close-button"
            tabIndex={tabIndex++}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <MultiFeatureSelector />
    </AppBar>
  );
};

export default TitleBar;
