import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Directions from './Directions';
import AlertLink from './AlertLink';
import Image from './Image';
import Show from '../../Show';

import { changeCase } from '../../../utils/changeCase';
import DepartmentsSection from './Building/DepartmentsSection';

const styles = {
  root: {
    background: '#5f6a72',
    color: 'white',
  },
  img: {
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '20px',
    textAlign: 'center',
  },
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  address: {
    fontSize: '1.3rem',
  },
  link: {
    textDecoration: 'none',
    padding: '10px',
    fontSize: '1.1rem',
    fontWeight: 500,
    borderRadius: '10px',
    background: '#cc0033',
    color: 'white',
    width: '150px',
    marginTop: '10px',
  },
  heading: {
    fontSize: '1.4em',
    fontWeight: 400,
    marginTop: '0.5em !important',
    color: '#cc0033',
    marginLeft: '3vw',
    marginBottom: '5px',
  },
};

const BuildingContent = ({ details }) => {
  let tabIndexCounter = 1;
  const [imageExists, setImageState] = useState(true);

  const { name, directionsUrl, address1, zip, city_id, alerts = [], BldgNum, departments, Website, Contact } = details;

  const handleImageError = () => setImageState(false);

  const { xs, sm, md, lg } = {
    xs: 12,
    sm: imageExists ? 6 : 12,
    md: imageExists ? 8 : 12,
    lg: imageExists ? 9 : 12,
  };

  return (
    <Box sx={styles.root}>
      <Show test={alerts.length}>
        {alerts.map(({ title, link }) => (
          <AlertLink title={title} link={link} key={`alert-${title}`} tabIndex={tabIndexCounter++} />
        ))}
      </Show>
      <Show test={directionsUrl}>
        <Directions url={directionsUrl} tabIndex={tabIndexCounter++} />
      </Show>
      <Grid container sx={styles.content}>
        <Show test={imageExists}>
          <Grid item xs={12} sm={6} md={4} lg={3} sx={styles.img}>
            <Image type="building" id={BldgNum} name={name} handleImageError={handleImageError} />
          </Grid>
        </Show>
        <Grid item xs={xs} sm={sm} md={md} lg={lg} sx={styles.details}>
          <Typography sx={styles.address}>
            {address1 ? `${address1},` : ''} {city_id ? `${changeCase(city_id)},` : ''} NJ {zip || ''}
          </Typography>
          <Show test={BldgNum}>
            <Typography>
              Building Number: {BldgNum}
            </Typography>
          </Show>
          <Show test={Contact}>
            <strong>Contact:</strong> {Contact}
          </Show>
          <Show test={Website && Website !== ' '}>
            <a href={Website} aria-label="Open building website in a new tab" tabIndex={1} target="_blank" rel="noopener noreferrer" style={styles.link}>
              Go to Website
            </a>
          </Show>
        </Grid>
        <Show test={departments && departments.length}>
          <DepartmentsSection departments={departments} />
        </Show>
      </Grid>
    </Box>
  );
};

export default BuildingContent;
