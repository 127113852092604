/* eslint-disable no-alert */
import load from '../../loading';
import { getMap } from '../mapRef';

let userPosition;
let geoLocatorMarker;

export default () => {
  const { google } = window;
  const map = getMap();
  // if geolocation not supported, display error in console
  if (!navigator.geolocation) return alert('Unfortunately Your browser doesn\'t support geolocation.');

  if (userPosition) {
    return map.setOptions({ center: userPosition, zoom: 17 });
  }
  // if no marker, create new marker and set to map
  if (!geoLocatorMarker) {
    geoLocatorMarker = new google.maps.Marker({
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillOpacity: 0.5,
        fillColor: '#007fac',
        strokeWeight: 1,
        strokeColor: '#007fac',
      },
      map,
    });
  }

  load.start('geolocation');
  // activate geolocator and track watch ID
  navigator.geolocation.getCurrentPosition(
    // On Success
    ({ coords }) => {
      const pos = { lat: coords.latitude, lng: coords.longitude };
      geoLocatorMarker.setPosition(pos);
      console.log('zooming to position');
      map.setOptions({ center: pos, zoom: 17 });
      // get / create geolocation marker
      console.log('adding geolocation watch / marker');
      navigator.geolocation.watchPosition(({ coords }) => {
        const pos = { lat: coords.latitude, lng: coords.longitude };
        userPosition = pos;
        console.log('user position updated');
        geoLocatorMarker.setPosition(pos);
      }, (error) => {
        console.error('Geolocation watch error', error);
      }, { enableHighAccuracy: true });

      load.end('geolocation');
      userPosition = pos;
    },
    // On Error
    (error) => {
      console.warn('Geolocation error', error);
      // communicate to user why geolocation is failing
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.warn('User denied the request for Geolocation');
          break;
        case error.POSITION_UNAVAILABLE:
          console.warn('Location information is unavailable', error);
          alert('Location information is unavailable');
          break;
        default:
          break;
      }
      load.end('geolocation');
      // return geolocation state to initial values
      geoLocatorMarker = null;
    },
    // Options
    { timeout: 10000, enableHighAccuracy: false },
  );
};
