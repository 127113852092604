import { createAsyncThunk } from '@reduxjs/toolkit';
import { openModal } from '../slices/modal';
import { setDisclaimerContent } from '../slices/disclaimer';
import loading from '../utils/loading';

export const fetchDisclaimerContent = createAsyncThunk(
  'disclaimer/fetchContent',
  async (_, thunkAPI) => {
    const loadingMsg = 'fetching usage disclaimer';
    loading.start(loadingMsg);
    try {
      const response = await fetch('/api/disclaimer');
      if (!response.ok) {
        throw new Error(`Fetching disclaimer failed with status ${response.status}`);
      }

      const { html, show } = await response.json();
      thunkAPI.dispatch(setDisclaimerContent(html));
      // if user needs to acknowledge disclaimer, force them to immediately
      if (show) {
        thunkAPI.dispatch(openModal({ category: 'DISCLAIMER', forceConfirm: true }));
      }
    } catch (e) {
      console.log('ERROR fetching disclaimer content', e);
    } finally {
      loading.end(loadingMsg);
    }
  },
);
