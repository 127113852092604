import { addTileLayer, isTileLayerVisible, removeTileLayer, setBasemap } from '../layers/rasterLayers';
import { restyleVectorLayer } from '../layers/vectorLayers';
import store from '../../store';
import { getMap } from '../map/mapRef';
import syncUrlWithState from '../syncUrl';
import { setUi } from '../../slices/ui';

export default function onMapTypeChange(e) {
  const { value } = e.target;
  const map = getMap();
  map.setMapTypeId(value);

  const imageryOn = isTileLayerVisible('aerialImagery');
  const satelliteOn = value === 'hybrid';
  const { utilitiesOpen } = store.getState().ui;

  // if 2019 Aerial Imagery is being toggled on
  if (satelliteOn) {
    store.dispatch(setUi({ id: 'satellite', value: true }));
    if (!imageryOn) {
      setBasemap('planimetric_ortho_basemap');
      if (utilitiesOpen) {
        removeTileLayer('buildingsParking');
        addTileLayer('buildingsParkingOutlines');
      }
    }
  } else { // if 2019 Aerial Imagery is being toggled off
    store.dispatch(setUi({ id: 'satellite', value: false }));
    if (!imageryOn) {
      setBasemap('planimetric_basemap');
      // if in utilities mode, add the proper raster layer
      if (utilitiesOpen) {
        removeTileLayer('buildingsParkingOutlines');
        addTileLayer('buildingsParking');
      }
    }
  }

  restyleVectorLayer('main');
  syncUrlWithState();
}
