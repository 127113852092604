import qs from 'query-string';
import { toggleVectorLayer } from '../layers/vectorLayers';

import history from '../../history';
import { utilityList } from '../../config/layers/raster';
import store from '../../store';
import { getUiState } from '../../slices/ui';
import { setBasemap, addTileLayer } from '../layers/rasterLayers';
import { turnOnUtilities } from '../workflows/toggleUtilities';
import onImageryToggle from '../listeners/imageryToggle';
import onMapTypeChange from '../listeners/mapTypeSelect';
import { getMap } from '../map/mapRef';
import getViewLevel from '../boundsChange/getViewLevel';
import selectFeature from '../selectFeature';
import selectFeatureOnLoad from './selectFeatureOnLoad';
import { showMarkerLayer } from '../layers/markerLayers';
import { isWithinPhoneLevel } from '../boundsChange/getPhoneBUCLevel';

// used to store search state during multiple calls to this function
let cachedSearchState = null;

export const getUrlState = () => {
  let { search } = history.location;
  const _search = global.localStorage.getItem('rutgers_search') || cachedSearchState;
  if (_search) {
    search = _search;
    global.localStorage.removeItem('rutgers_search');
  }
  // cache search state since this function gets called multiple times
  cachedSearchState = search;
  const queryState = qs.parse(search, { arrayFormat: 'comma' });
  // turn "true" strings into booleans
  const keys = Object.keys(queryState);
  keys.forEach((key) => {
    // convert true strings to booleans
    if (queryState[key] === 'true') {
      queryState[key] = true;
    }
    // convert utilities into an object
    if (key === 'utilities') {
      // get utilities and convert into an array if there is a single value
      const utilitiesFromUrl = Array.isArray(queryState.utilities) ? queryState.utilities : [queryState.utilities];
      queryState.utilities = {};
      utilityList.forEach(({ layerId }) => {
        queryState.utilities[layerId] = false;
      });
      // set utiltiies to toggled on
      utilitiesFromUrl.forEach((utility) => {
        queryState.utilities[utility] = true;
      });
    }
  });

  return queryState;
};

export const setStateFromUrl = async () => {
  const uiState = getUiState(store.getState());

  const { utilitiesOpen, utilities, satellite, aerialImagery, parcel, bike, walk, emergencyPhones, selected } = uiState;

  if (satellite) {
    const selectEl = document.querySelector('.maptype-control-is-map > select');
    selectEl.value = 'hybrid';
    onMapTypeChange({ target: selectEl });
  }

  // set AGOL basemap
  if (aerialImagery) {
    // addTileLayer('aerialImagery');
    onImageryToggle({ target: document.querySelector('.imagery-control > img') });
    // this will also toggle on the road names raster layer automatically
    // setBasemap('planimetric_ortho_basemap');
  }

  if (!satellite && !aerialImagery) setBasemap('planimetric_basemap');

  const taskQueue = [];
  const zoom = getMap().getZoom();
  const level = getViewLevel(zoom);

  // console.log('ON LOAD level', level);
  // load in markers if toggled
  if (emergencyPhones && isWithinPhoneLevel(zoom)) {
    showMarkerLayer('emergencyPhones');
  }

  if (level === 'features') {
    // load in basic tile layers if toggled
    if (bike) addTileLayer('bike');
    if (walk) addTileLayer('walk');

    // * ------------------------ IF UTILITES OPEN ------------------------
    if (utilitiesOpen) {
      if (!aerialImagery && !satellite) addTileLayer('buildingsParking');
      if (parcel) addTileLayer('parcel');
      if (utilities) turnOnUtilities();
    } else {
      // * ------------------------ IF UTILITES NOT OPEN ------------------------
      const loadBuildings = toggleVectorLayer('main', true);
      taskQueue.push(loadBuildings);

      if (parcel) {
        taskQueue.push(toggleVectorLayer('parcel', true));
      }

      if (aerialImagery || satellite) {
        // set main layer style to outlines
        const waitForMainLayerToLoadAndThenSetStyle = async () => {
          await loadBuildings;
          // setVectorLayerStyle('main', 'outlines');
        };
        taskQueue.push(waitForMainLayerToLoadAndThenSetStyle());
      }
    }
  } else if (level === 'districts') {
    taskQueue.push(toggleVectorLayer('districts', true));
  }

  await Promise.all(taskQueue);

  // console.log('ON LOAD: selected:', selected);
  if (selected) {
    // if it is a utility feature that's selected
    if (Array.isArray(selected)) {
      const [category, id] = selected;
      await selectFeature({ id, category, onLoad: true });
    } else {
      await selectFeatureOnLoad(selected, parcel);
    }
  }
};
