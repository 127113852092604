import { configureStore } from '@reduxjs/toolkit';

import urlSyncMiddleware from './middleware/urlSyncMiddleware';

import busRoutes from './slices/busRoutes';
import disclaimer from './slices/disclaimer';
import infoDrawer from './slices/infoDrawer';
import modal from './slices/modal';
import popularDestinations from './slices/popularDestinations';
import search from './slices/search';
import snackbar from './slices/snackbar';
import ui from './slices/ui';
import view from './slices/view';

const store = configureStore({
  reducer: {
    busRoutes,
    disclaimer,
    infoDrawer,
    modal,
    popularDestinations,
    search,
    snackbar,
    ui,
    view,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(urlSyncMiddleware),
  devTools: process.env.REACT_APP_SHOW_REDUX_DEV_TOOLS,
});

export default store;
