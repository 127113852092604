import { useState } from 'react';
import { useDispatch } from 'react-redux';

// Material UI Components
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ShareIcon from '@mui/icons-material/Share';
import Chat from '@mui/icons-material/Chat';

import DownloadIcon from './DownloadIcon';

import history from '../../history';
import { openModal } from '../../slices/modal';
import { openSnackbar } from '../../slices/snackbar';

import isAuthenticated from '../../utils/isAuthenticated';

const styles = {
  buttonsWrapper: {
    display: 'flex',
  },
  icon: {
    color: 'white',
  },
};

const HeaderMenuItems = () => {
  const [userMenu, setUserMenu] = useState(null);
  const userMenuTitle = isAuthenticated() ? 'Logout' : 'Login';
  const userMenuLabel = `${userMenuTitle} / User Options`;

  const dispatch = useDispatch();

  const handleFeedbackClick = () => dispatch(openModal({ category: 'FEEDBACK' }));

  const handleShareClick = () => {
    dispatch(openSnackbar({ msg: 'URL copied to clipboard' }));
    const tempText = document.createElement('textarea');
    tempText.innerText = window.location.href;
    document.body.appendChild(tempText);
    tempText.select();
    document.execCommand('copy');
    tempText.remove();
  };

  const handleDownloadClick = () => dispatch(openModal({ category: 'DOWNLOAD_MAPS' }));

  const handleUserMenuClick = (event) => setUserMenu(event.currentTarget);
  const handleUserMenuClose = () => setUserMenu(null);

  const handleLoginClick = () => {
    global.localStorage.setItem('rutgers_search', history.location.search);
    window.location = 'login';
  };

  const handleLogoutClick = () => {
    window.location = 'logout';
  };

  return (
    <Box sx={styles.buttonsWrapper}>
      <Tooltip title="Give Feedback">
        <IconButton onClick={handleFeedbackClick} aria-label="Give Feedback">
          <Chat sx={styles.icon} label="Give Feedback" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Share Map URL">
        <IconButton onClick={handleShareClick} aria-label="Share Map URL">
          <ShareIcon sx={styles.icon} label="Share Map URL" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Download Local Maps">
        <IconButton onClick={handleDownloadClick} aria-label="Download Local Maps">
          <DownloadIcon sx={styles.icon} />
        </IconButton>
      </Tooltip>

      <Tooltip title={userMenuTitle}>
        <IconButton
          sx={styles.icon}
          aria-owns={userMenu ? 'user-menu' : null}
          aria-haspopup="true"
          label={userMenuLabel}
          aria-label={userMenuLabel}
          onClick={handleUserMenuClick}
        >
          <KeyboardArrowDown />
        </IconButton>
      </Tooltip>

      <Menu
        id="user-menu"
        anchorEl={userMenu}
        open={Boolean(userMenu)}
        onClose={handleUserMenuClose}
      >
        { isAuthenticated()
          ? <MenuItem onClick={handleLogoutClick} aria-label="Logout">Logout</MenuItem>
          : <MenuItem onClick={handleLoginClick} aria-label="Login">Login</MenuItem> }
      </Menu>
    </Box>
  );
};

export default HeaderMenuItems;
