import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const styles = {
  button: {
    width: '100%',
    boxShadow: '0px 1px black',
    borderRadius: 0,
    color: 'white',
    background: '#007fac',
    '&:hover': {
      background: '#116688',
    },
  },
};

const AlertLink = ({ title, link, tabIndex }) => (
  <Tooltip title={title}>
    <Button
      sx={styles.button}
      TouchRippleProps={{ classses: { root: styles.ripple } }}
      size="small"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      color="secondary"
      tabIndex={tabIndex}
      aria-label={title}
      label={title}
    >{title}
    </Button>
  </Tooltip>
);

export default AlertLink;
