// add border when focused
window.focus = (toggled, background) => ({ target }) => {
  const el = target.parentElement.parentElement;
  el.style.border = toggled && !background ? '#ddd 3px solid' : 'none';
  if (background) el.style.backgroundColor = toggled ? 'rgba(187,187,187,0.7)' : 'inherit';
};

// remove border when unfocused (blur)
window.blur = (background) => ({ target }) => {
  const el = target.parentElement.parentElement;
  el.style.border = 'none';
  if (background) el.style.backgroundColor = 'inherit';
};
