export default {
  totalResults: 12,
  minimumLocalResults: 12,
  options: {
    keys: [
      { name: 'district', weight: 0.21 },
      { name: 'abbr', weight: 0.21 },
      { name: 'aliases', weight: 0.18 },
      { name: 'name', weight: 0.15 },
      { name: 'id', weight: 0.15 },
      { name: 'address', weight: 0.1 },
    ],
    findAllMatches: true,
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 1000,
    maxPatternLength: 32,
    minMatchCharLength: 2,
  },
};
