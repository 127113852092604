import { openSnackbar } from '../../slices/snackbar';
import store from '../../store';
import { getSyncIdAsync } from '../getSyncId';
import config from '../../config';

export default async function getUtiltiesLegend(networks = []) {
  try {
    if (!Array.isArray(networks)) networks = [networks];
    const syncId = await getSyncIdAsync();
    const sections = await fetch(`${config.syncDataBucket}/${syncId}/utilities-legend.json`).then((x) => x.json());
    if (!sections) return;
    return sections.filter(({ network }) => networks.includes(network));
  } catch (e) {
    console.log('ERROR: requesting utility legend details from GCS', e);
    store.dispatch(openSnackbar({ msg: 'Error requesting utility legend icons' }));
  }
}
