import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import IconButtonToggle from './IconButtonToggle';
import config from '../../config';

const styles = {
  buttonsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '20%',
    justifyContent: 'center',
  },
};

const { styles: { highlights }, destinations } = config;

const Destinations = () => (
  <Box>
    <Typography variant="h2" align="center">Destinations</Typography>
    <Box sx={styles.buttonsWrapper}>
      {destinations.map(({ id, title, Icon }) => (
        <IconButtonToggle
          id={id}
          name={title}
          Icon={Icon}
          color={highlights[id].strokeColor}
          key={id}
        />
      ))}
    </Box>
  </Box>
);

export default Destinations;
