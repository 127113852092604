export const initial = (contentType) => contentType.substring(0, contentType.length - 5);
export const more = (contentType) => `${contentType}-more`;
export const isMore = (contentType) => contentType.substring(contentType.length - 5) === '-more';

function getElAtTabIndex(index) {
  return document.querySelector(`[tabindex="${index}"]`);
}

export const focusDrawerControls = () => {
  // find HTML element with the first tabindex > 0
  let tabIndexCounter = 1;
  let el = getElAtTabIndex(tabIndexCounter);
  while (!el && tabIndexCounter < 15) {
    tabIndexCounter++;
    el = getElAtTabIndex(tabIndexCounter);
  }
  // auto-focus the appropriate navigation button when drawer opens
  if (el) el.focus();
};
