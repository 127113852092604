import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { WalkIcon, BikeIcon, BusIcon, ParkingIcon } from './SidebarIcons';
import IconButtonToggle from './IconButtonToggle';
import Show from '../Show';

const GettingAround = () => {
  const zoom = useSelector(({ ui: { zoom } }) => zoom);
  const utilitiesOpen = useSelector(({ ui: { utilitiesOpen } }) => utilitiesOpen);
  const campus = useSelector(({ view: { campus } }) => campus);

  return (
    <Box>
      <Typography variant="h2" align="center">Getting Around</Typography>
      <Box display="flex" justifyContent="center">
        <Show test={zoom >= 16 && campus}>
          <IconButtonToggle
            id="walk"
            name="Walk"
            Icon={WalkIcon}
          />
        </Show>
        <Show test={campus && campus !== 'camden'}>
          <IconButtonToggle
            id="bike"
            name="Bike"
            Icon={BikeIcon}
          />
        </Show>
        <Show test={campus}>
          <IconButtonToggle
            id="bus"
            name="Bus"
            Icon={BusIcon}
          />
        </Show>
        <Show test={!utilitiesOpen}>
          <IconButtonToggle
            id="parking"
            name="Parking"
            Icon={ParkingIcon}
          />
        </Show>
      </Box>
    </Box>
  );
};

export default GettingAround;
