import Button from '@mui/material/Button';
import selectFeature from '../../../../utils/selectFeature';
import { getSiteIdCrosswalk } from '../../../../utils/siteIds/getSiteIds';

const styles = {
  districtHeading: {
    color: '#555',
    height: '100%',
    overflow: 'auto',
    textAlign: 'center',
    padding: '2px 0',
    width: '100%',
    borderRadius: '0px',
    display: 'inline',
    borderBottom: '0.1px solid #cc0033',
  },
  subtitle: {
    fontSize: '.8em',
  },
};

const ResultDistrictHeading = ({ result, resetSearch }) => {
  const handleClick = () => {
    resetSearch();
    selectFeature({ district: result.district });
  };
  const siteIdCrosswalk = getSiteIdCrosswalk();
  const label = `Zoom to ${result.name} district at ${siteIdCrosswalk[result.campus]}}`;
  return (
    <Button sx={styles.districtHeading} data-district={result.district} onClick={handleClick} aria-label={label}>
      <strong>
        {result.name}
      </strong>
      <br />
      <span style={styles.subtitle}>district</span>
    </Button>
  );
};

export default ResultDistrictHeading;
