import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

// components
import GettingAround from './GettingAround';
import BuildingsUnderConstruction from './BuildingsUnderConstruction';
import EmergencyPhones from './EmergencyPhones';
import Destinations from './Destinations';
import Search from './Search/Search';
import PopularDestinations from './PopularDestinations';
import CampusLife from './CampusLife';
import JumpToLocation from './JumpToLocation';
import Utilities from './Utilities/Utilities';
import Section from './Section';
import Toggle from './Toggle';
import Show from '../Show';
import OffCampusFacilitiesToggle from './OffCampusFacilitiesToggle';
import config from '../../config/index';
import { selectSearchResults } from '../../slices/search';
import { getUtilitiesOpen, selectSidebarOpen, selectZoom } from '../../slices/ui';
import isAuthenticated from '../../utils/isAuthenticated';
import { selectCampus, selectViewLevel } from '../../slices/view';
import BikeLegend from './Legend/BikeLegend';
import BusLegend from './Legend/BusLegend';

const getStyles = ({ results, width }) => ({
  sidebar: {
    boxShadow: '5px 0px 15px rgba(0,0,0,0.15)',
    zIndex: 1,
    overflowY: results ? 'hidden' : 'scroll',
    width,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const { zoomLevel } = config;

const Sidebar = ({ width }) => {
  const searchResults = useSelector(selectSearchResults);
  const campus = useSelector(selectCampus);
  const level = useSelector(selectViewLevel);
  const sidebar = useSelector(selectSidebarOpen);
  const utilitiesOpen = useSelector(getUtilitiesOpen);
  const zoom = useSelector(selectZoom);

  const styles = getStyles({ results: searchResults.length, width });

  if (!sidebar) {
    return (<Search width={width} />);
  }
  const atFeatureLevel = level === 'features';
  const showInternalFeatureLevelControls = atFeatureLevel && isAuthenticated();

  return (
    <Box id="sidebar" sx={styles.sidebar}>
      <Search width={width} />

      <JumpToLocation />

      <Show test={campus && ['districts', 'features'].includes(level)}>
        <PopularDestinations
          campus={campus}
        />
      </Show>

      <Show test={['districts', 'state'].includes(level)}>
        <OffCampusFacilitiesToggle />
      </Show>

      <Show test={atFeatureLevel}>
        <GettingAround />
        <Show test={!utilitiesOpen}>
          <Destinations />
        </Show>
      </Show>

      <Show test={atFeatureLevel && campus}>
        <CampusLife />
      </Show>

      <Show test={zoom >= zoomLevel.emergencyPhones.min && campus}>
        <EmergencyPhones />
      </Show>

      <Show test={zoom >= zoomLevel.underConstruction.min && campus && !utilitiesOpen}>
        <BuildingsUnderConstruction />
      </Show>

      <Show test={showInternalFeatureLevelControls}>
        <Utilities />
        <Section title="Real Estate">
          <Toggle title="Parcel" />
        </Section>
      </Show>

      {/* ------------ BIKE & BUS LEGENDS ------------ */}
      <Show test={campus && atFeatureLevel}>
        <BikeLegend />
        <BusLegend />
      </Show>
    </Box>
  );
};

export default Sidebar;
