import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar, getSnackbarState } from '../slices/snackbar';

const styles = {
  close: {
    padding: 1,
  },
};

const SnackbarMsg = () => {
  const dispatch = useDispatch();
  const { open, msg } = useSelector(getSnackbarState);

  const handleClose = (e, reason) => {
    if (reason !== 'clickaway') dispatch(closeSnackbar());
  };

  const handleClick = () => dispatch(closeSnackbar());

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={msg}
      action={(
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          sx={styles.close}
          onClick={handleClick}
          tabIndex={1}
        >
          <CloseIcon />
        </IconButton>
    )}
    />
  );
};

export default SnackbarMsg;
