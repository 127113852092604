import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    results: [],
    query: '',
  },
  reducers: {
    setQuery: (state, { payload: query }) => {
      state.query = query;
    },
    setSearchResults: (state, { payload: results }) => {
      state.results = results;
    },
  },
});

export const { setQuery, setSearchResults } = searchSlice.actions;

export const selectSearchResults = (state) => state.search.results;

export default searchSlice.reducer;
