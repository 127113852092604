import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import SkipNav from './SkipNav';
import Snackbar from './Snackbar';
import Show from './Show';
import MainPage from './MainPage';
import ModalRouter from './Modal/ModalRouter';
import DataIssuesPage from './DataIssuesPage';
import RedirectOnLoad from './RedirectOnLoad';

import { selectModalOpen } from '../slices/modal';

// add window.focus and window.blur
require('../utils/accessibility/focusHandlers');

const App = () => {
  const modalOpen = useSelector(selectModalOpen);

  return (
    <>
      <SkipNav />
      <Snackbar />
      <Show test={modalOpen}>
        <ModalRouter />
      </Show>
      <Routes>
        <Route path="/" element={<RedirectOnLoad />} />
        <Route path="/map" element={<MainPage />} />
        <Route
          path="/dataIssues"
          element={<DataIssuesPage />}
        />
      </Routes>
    </>
  );
};

export default App;
