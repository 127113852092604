import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Show from '../../Show';

const styles = {
  content: {
    textAlign: 'center',
    paddingTop: '10px',
  },
};

const BusContent = ({ details: { link, routeName, type } }) => (
  <Box>
    <Box sx={styles.content}>
      <Show test={type === 'stop'}>
        <Typography><strong>Route:</strong> {routeName}</Typography>
      </Show>
      <Typography variant="subtitle1">
        <a href={link} target="_blank" rel="noopener noreferrer" tabIndex={1}>Learn more</a>
      </Typography>
    </Box>
  </Box>
);
export default BusContent;
