import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';

import { getUiState, toggleSection } from '../../slices/ui';
import { toCamelCase } from '../../utils/changeCase';

const styles = {
  heading: {
    textAlign: 'center',
    marginBottom: '0px',
  },
  sectionButton: {
    fontSize: '0.75em',
    color: '#5f6a72',
  },
};

const Section = ({ title, handleToggle, children, open, value }) => {
  const dispatch = useDispatch();
  const ui = useSelector(getUiState);
  value = value || toCamelCase(title);
  const isOpen = open || ui[value];
  const openLabel = isOpen ? 'Close' : 'Open';
  const toggleLabel = `${openLabel} ${title}`;
  const fullLabel = `${toggleLabel} Section`;
  const handleSectionToggle = () => {
    dispatch(toggleSection(value));
    if (handleToggle) handleToggle();
  };

  return (
    <Box sx={styles.section}>
      <Typography variant="h2" align="center">
        <Tooltip title={fullLabel} placement="top">
          <Button onClick={handleSectionToggle} sx={styles.sectionButton} label={fullLabel} aria-label={fullLabel}>
            {title}
            {isOpen ? <ExpandLess label={toggleLabel} aria-label={toggleLabel} /> : <ExpandMore label={toggleLabel} aria-label={toggleLabel} />}
          </Button>
        </Tooltip>
      </Typography>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List dense>
          {children}
        </List>
      </Collapse>
    </Box>
  );
};

export default Section;
