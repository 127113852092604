import { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import rastersLayers from '../../config/layers/raster';

const styles = {
  section: {
    border: '1px black solid',
  },
  expanded: {
    margin: '0 0 5px 0 !important',
  },
  heading: {
    fontSize: (theme) => theme.typography.pxToRem(15),
    marginRight: '10px',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: (theme) => theme.typography.pxToRem(15),
    color: (theme) => theme.palette.text.secondary,
  },
  details: {
    width: '100%',
    display: 'flex',
  },
  iconItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  featureName: {
    fontSize: '0.7em',
    paddingLeft: '10px',
    textAlign: 'left',
  },
  icon: {
    width: '25px',
  },
};

const UtilityLegendSection = ({ data, i }) => {
  const { network, points, lines, polygons } = data;
  const { title } = rastersLayers[network];

  // by default, first section starts as open
  const [expanded, setExpanded] = useState(!i);

  const handleChange = (event, isExpanded) => setExpanded(isExpanded);

  const totalLength = points.length + lines.length + polygons.length;

  return (
    <Accordion expanded={expanded} onChange={handleChange} sx={{ ...styles.section, ...styles.expanded }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography sx={styles.heading}>{title}</Typography>
        <Typography sx={styles.secondaryHeading}>({totalLength} items)</Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>
        <Grid container>
          {points.map(({ Display_Name, Image_URL }) => (
            <Grid item xs={12} sm={6} sx={styles.iconItem} key={Display_Name}>
              <img style={styles.icon} alt={`${Display_Name} icon`} src={Image_URL} />
              <Typography sx={styles.featureName}>{Display_Name}</Typography>
            </Grid>
          ))}
          {lines.map(({ Display_Name, Image_URL }) => (
            <Grid item xs={12} sm={6} sx={styles.iconItem} key={Display_Name}>
              <img style={styles.icon} alt={`${Display_Name} icon`} src={Image_URL} />
              <Typography sx={styles.featureName}>{Display_Name}</Typography>
            </Grid>
          ))}
          {polygons.map(({ Display_Name, Image_URL }) => (
            <Grid item xs={12} sm={6} sx={styles.iconItem} key={Display_Name}>
              <img style={styles.icon} alt={`${Display_Name} icon`} src={Image_URL} />
              <Typography sx={styles.featureName}>{Display_Name}</Typography>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UtilityLegendSection;
