import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  camden: [],
  newBrunswick: [],
  newark: [],
};

const busRoutesSlice = createSlice({
  name: 'busRoutes',
  initialState,
  reducers: {
    setBusRoutesForCampus: (state, { payload: { campus, routes } }) => {
      state[campus] = routes;
    },
    toggleBusRoute: (state, { payload: { campus, index, toggled } }) => {
      state[campus][index].on = toggled;
    },
  },
});

export const { setBusRoutesForCampus, toggleBusRoute } = busRoutesSlice.actions;

// selectors
export const selectBusRoutesForCampus = (campus) => (state) => state.busRoutes[campus];

export default busRoutesSlice.reducer;
