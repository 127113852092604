import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Show from '../../Show';

import { addTileLayer, removeTileLayer } from '../../../utils/layers/rasterLayers';
import { setSelectedFeatureOnMap } from '../../../utils/layers/vectorLayers';

const styles = {
  title: {
    display: 'flex',
  },
  helpIcon: {
    marginLeft: 1,
  },
};

const UtilityToggle = ({ layerId, title, toggled, toggleUtility }) => {
  const handleToggle = () => {
    if (toggled) removeTileLayer(layerId);
    else addTileLayer(layerId);
    toggleUtility(layerId);
    setSelectedFeatureOnMap();
  };

  const label = `${toggled ? 'Hide' : 'Show'} ${title}`;
  return (
    <ListItem key={layerId}>
      <ListItemText sx={styles.title}>
        {title}
        <Show test={layerId === 'All_networks'}>
          <Tooltip
            title="Toggle this layer on to see a simplified view of all utility networks in an area."
            placement="right"
            enterTouchDelay={0}
          >
            <HelpOutlineIcon tabIndex={0} aria-hidden="false" sx={styles.helpIcon} fontSize="small" aria-label="Toggle this layer on to see a simplified view of all utility networks in an area." />
          </Tooltip>
        </Show>
      </ListItemText>
      <ListItemSecondaryAction>
        <Tooltip title={label} placement="left">
          <Switch
            checked={toggled}
            onChange={handleToggle}
            onFocus={window.focus(!toggled, true)}
            onBlur={window.blur(true)}
            inputProps={{ label, 'aria-label': label }}
          />
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default UtilityToggle;
