import { useDispatch } from 'react-redux';

// Material UI Components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

import Chat from '@mui/icons-material/Chat';
import ShareIcon from '@mui/icons-material/Share';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import Show from '../Show';
import DownloadIcon from './DownloadIcon';

import history from '../../history';
import { openModal } from '../../slices/modal';
import { openSnackbar } from '../../slices/snackbar';

import isAuthenticated from '../../utils/isAuthenticated';

const styles = {
  menuItemsWrapper: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#8e0023',
    color: 'white',
    zIndex: 2000,
  },
  icon: {
    color: 'white',
  },
};

const MobileMenu = ({ handleClose }) => {
  const dispatch = useDispatch();

  const handleFeedbackClick = () => {
    dispatch(openModal({ category: 'FEEDBACK' }));
    handleClose();
  };

  const handleShareClick = () => {
    dispatch(openSnackbar({ msg: 'URL copied to clipboard' }));
    const tempText = document.createElement('textarea');
    tempText.innerText = window.location.href;
    document.body.appendChild(tempText);
    tempText.select();
    document.execCommand('copy');
    tempText.remove();
    handleClose();
  };

  const handleDownloadClick = () => {
    dispatch(openModal({ category: 'DOWNLOAD_MAPS' }));
    handleClose();
  };

  const loggedIn = isAuthenticated();

  const handleLoginClick = () => {
    global.localStorage.setItem('rutgers_search', history.location.search);
    window.location = 'login';
  };

  const handleLogoutClick = () => {
    window.location = 'logout';
  };

  return (
    <List sx={styles.menuItemsWrapper}>
      <Tooltip title="Give Feedback" aria-label="Give Feedback">
        <ListItem button onClick={handleFeedbackClick}>
          <ListItemIcon>
            <Chat sx={styles.icon} />
          </ListItemIcon>
          <ListItemText primary="Give Feedback" />
        </ListItem>
      </Tooltip>

      <ListItem button onClick={handleShareClick} aria-label="Copy Map URL">
        <ListItemIcon>
          <ShareIcon sx={styles.icon} />
        </ListItemIcon>
        <ListItemText primary="Copy Map URL" />
      </ListItem>

      <ListItem button onClick={handleDownloadClick} aria-label="Download Local Maps">
        <ListItemIcon>
          <DownloadIcon sx={styles.icon} />
        </ListItemIcon>
        <ListItemText primary="Download Local Maps" />
      </ListItem>

      <Divider />

      <Show test={!loggedIn}>
        <ListItem button onClick={handleLoginClick} aria-label="Login">
          <ListItemIcon>
            <PersonIcon sx={styles.icon} />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>
      </Show>

      <Show test={loggedIn}>
        <ListItem button onClick={handleLogoutClick} aria-label="Logout">
          <ListItemIcon>
            <ExitToAppIcon sx={styles.icon} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </Show>

    </List>
  );
};

export default MobileMenu;
