import { toggleSidebar, toggleSection, toggleLayer, toggleUtility, toggleUi, setUi, setSelected } from '../slices/ui';
import syncUrlWithState from '../utils/syncUrl';

const actionTypeSet = new Set([
  toggleSidebar.type,
  toggleSection.type,
  toggleLayer.type,
  toggleUtility.type,
  toggleUi.type,
  setUi.type,
  setSelected.type,
]);

const urlSyncMiddleware = () => (next) => (action) => {
  const result = next(action);

  if (actionTypeSet.has(action.type)) {
    syncUrlWithState();
  }

  return result;
};

export default urlSyncMiddleware;
