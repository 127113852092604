import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import config from '../../config';

const { pdfMaps, assetsBucket } = config;

const styles = {
  link: {
    color: '#5f6a72',
  },
};

const DownloadLink = ({ data }) => {
  const url = `${assetsBucket}/rutgers-pdf-maps/${data.id}.pdf`;
  return (
    <ListItem>
      <a style={styles.link} href={url} target="_blank" rel="noopener noreferrer" aria-label={data.title}>{data.title}</a>
    </ListItem>
  );
};

const Download = ({ onClose }) => (
  <>
    <DialogTitle>Download Campus Maps</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <List>
          {pdfMaps.map((data) => <DownloadLink key={data.id} data={data} />)}
        </List>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" autoFocus onClick={onClose} color="primary">
        close
      </Button>
    </DialogActions>
  </>
);

export default Download;
