import config from '../../config';
import { setInfoDrawerFeatures } from '../../slices/infoDrawer';

import store from '../../store';

/* ----------------------- PARSE ROUTES AND STOPS / ADD TO DATA LAYER ----------------------- */

export default function createBusRouteLayer({ route, campus }) {
  const link = config.busLinks[campus];
  const { google } = window;
  const layer = new google.maps.Data();
  const { route_long_name, route_color } = route;

  route.trips.forEach((trip) => {
    trip.shapes.features.forEach((shape) => {
      shape.properties = { type: 'route', name: shape.properties.route_long_name, ...shape.properties };
      layer.addGeoJson(shape, { type: 'route' });
    });
  });

  // add each stop as point geometry
  route.stops.features.forEach((stop) => {
    const { properties: { stop_name }, geometry: { coordinates } } = stop;
    const feature = new google.maps.Data.Feature({
      geometry: { lat: coordinates[1], lng: coordinates[0] },
      properties: {
        name: `${stop_name} Bus Stop`,
        type: 'stop',
      },
    });
    layer.add(feature);
  });

  // add styling to google maps data layer
  layer.setStyle({
    strokeColor: route_color,
    strokeWeight: 3,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 5,
      fillOpacity: 1.0,
      fillColor: route_color,
      strokeWeight: 1,
      strokeColor: '#5f6a72',
    },
    // zIndex: 1000 - routeJson.index,
    zIndex: 1000,
  });

  // add click listener to the layer to open the drawer
  layer.addListener('click', ({ feature }) => {
    const name = feature.getProperty('name');
    const type = feature.getProperty('type');
    const details = { link, name, type, routeName: route_long_name, color: route_color };
    store.dispatch(setInfoDrawerFeatures([{ category: 'bus', details }]));
  });

  return layer;
}
