export default function asyncDebounce(func, wait, immediate) {
  let timeout;
  return (...args) => new Promise((resolve) => {
    const callNow = immediate && !timeout;

    function later() {
      timeout = null;
      if (!immediate) resolve(func(...args));
    }

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) resolve(func(...args));
  });
}
