import { mainCampuses } from '../../config/locations';
import { getMap } from '../map/mapRef';
import config from '../../config';

const { zoomLevel } = config;

let campusBounds;

const createCampusBounds = () => {
  const bounds = [];
  for (const campus of Object.values(mainCampuses)) {
    const { south, west, north, east } = campus.bounds;
    const sw = { lat: south, lng: west };
    const ne = { lat: north, lng: east };
    bounds.push({
      title: campus.value,
      bounds: new window.google.maps.LatLngBounds(sw, ne),
    });
  }
  return bounds;
};

export default function getCampus(zoom) {
  // if not at the feature level, return undefined
  if (zoom < zoomLevel.features.min) return;
  // get the map instance
  const map = getMap();
  // get the current bounds
  const currentBounds = map.getBounds();
  // if map isn't ready and doesn't have bounds yet, abort
  if (!currentBounds) return;
  // if the campusBounds object hasn't been created yet, do so
  if (!campusBounds) campusBounds = createCampusBounds();
  // loop through the 3 main campuses
  for (const campus of campusBounds) {
    const mapExtentIntersectsCampusBounds = currentBounds && currentBounds.intersects(campus.bounds);
    const mapCenterWithinCampusBounds = currentBounds && campus.bounds.contains(currentBounds.getCenter());
    // determine whether the campus bounds intersect with the current map bounds
    if (mapExtentIntersectsCampusBounds || mapCenterWithinCampusBounds) {
      // if so, return the camelCase campus name
      return campus.title;
    }
  }
}
