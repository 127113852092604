import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { VisuallyHidden } from '@react-aria/visually-hidden';

import { toggleClusterLayer } from '../../utils/layers/clusterLayers';
import { selectOffCampusFacilitiesState, setUi } from '../../slices/ui';

const styles = {
  button: {
    width: '90%',
    marginLeft: '5%',
  },
};

const OffCampusFacilitiesToggle = () => {
  const toggled = useSelector(selectOffCampusFacilitiesState);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setUi({ id: 'ocf', value: !toggled }));
    toggleClusterLayer('ocf', !toggled);
  };

  // Accessibility Labels
  const label = `${toggled ? 'Hide' : 'Show'} Off-Campus Facilities`;

  return (
    <>
      <VisuallyHidden>
        <h2 className="side-menu-heading" aria-label="Show/Hide Off Campus Facilities on the Map">Off Campus Facilities</h2>
      </VisuallyHidden>
      <Tooltip title={`${toggled ? 'Hide' : 'Show'} Off-Campus Facilities`} placement="bottom">
        <Button
          variant="contained"
          onClick={onClick}
          label={label}
          aria-label={label}
          sx={styles.button}
          color="secondary"
        >
          {toggled ? 'Hide' : 'Show'} Off-Campus Facilities
        </Button>
      </Tooltip>
    </>
  );
};

export default OffCampusFacilitiesToggle;
