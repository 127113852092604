import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  color: '#cc0033',
  category: '',
  content: null,
  features: [],
  currentIndex: 0,
};

const infoDrawerSlice = createSlice({
  name: 'infoDrawer',
  initialState,
  reducers: {
    // closeInfoDrawer: (state) => {
    //   state.open = false;
    // },
    resetInfoDrawerState: () => initialState,
    nextSelection: (state) => {
      state.currentIndex += 1;
    },
    prevSelection: (state) => {
      state.currentIndex -= 1;
    },
    setInfoDrawerFeatures: (state, { payload }) => {
      state.features = payload;
      state.currentIndex = 0;
      state.open = true;
    },
  },
});

// action creators
export const {
  openInfoDrawer,
  // closeInfoDrawer,
  resetInfoDrawerState,
  setInfoDrawerContent,
  nextSelection,
  prevSelection,
  setInfoDrawerFeatures,
} = infoDrawerSlice.actions;

// selectors
export const selectInfoDrawerOpen = (state) => state.infoDrawer.open;
export const selectInfoDrawerCurrentIndex = (state) => state.infoDrawer.currentIndex;
export const selectInfoDrawerFeatures = (state) => state.infoDrawer.features;

export default infoDrawerSlice.reducer;
