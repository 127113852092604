import Box from '@mui/material/Box';

const styles = {
  attribute: {
    padding: '5px 10px',
    minWidth: '180px',
    display: 'flex',
    flexDirection: 'column',
  },
};

const UtilityDrawerField = ({ label, value }) => {
  const isLink = label === 'DocumentsLink';
  if (isLink && value) {
    value = <a href={value}>View Document</a>;
  }

  if (label.includes('Date') && value) {
    value = (new Date(value)).toLocaleDateString();
  }

  return (
    <Box sx={styles.attribute} key={label}>
      <strong>{label}</strong> {value}
    </Box>
  );
};

export default UtilityDrawerField;
