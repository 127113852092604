import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Directions from './Directions';
import Show from '../../Show';

const styles = {
  details: {
    padding: '20px',
  },
  attributes: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  attribute: {
    padding: '5px 10px',
    minWidth: '180px',
    display: 'flex',
    flexDirection: 'column',
  },
};

const CampusLifeContent = ({ details }) => {
  const { name, Address, Campus, Meal_Plan, Website } = details;
  let tabIndex = 1;
  const destination = `${Address} ${Campus} NJ`.split(' ').join('+');
  const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
  return (
    <>
      <Directions startIndex={tabIndex++} url={directionsUrl} />
      <Box sx={styles.details}>
        <Typography variant="h6">{Address}</Typography>
        <Box sx={styles.attributes}>
          <Show test={Website !== ' '}>
            <Box sx={styles.attribute}>
              <strong>Website</strong><a tabIndex={tabIndex++} aria-label={`Open ${name} website in new tab`} href={Website} target="_blank" rel="noopener noreferrer">{name}</a>
            </Box>
          </Show>
          <Show test={Meal_Plan}>
            <Box sx={styles.attribute}>
              <strong>Meal Plan</strong>{Meal_Plan}
            </Box>
          </Show>
        </Box>
      </Box>
    </>
  );
};

export default CampusLifeContent;
