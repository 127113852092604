import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import { getUiStateById } from '../../slices/ui';
import { toCamelCase } from '../../utils/changeCase';
import { toggleLayerThunk } from '../../thunks/uiToggle';

const Toggle = ({ title }) => {
  const dispatch = useDispatch();
  const value = toCamelCase(title);
  const checked = useSelector(getUiStateById(value));
  // label for tooltip and accessibility attributes
  const label = `${checked ? 'Hide' : 'Show'} ${title}`;
  const handleToggle = () => dispatch(toggleLayerThunk(value));

  return (
    <ListItem>
      <ListItemText primary={title} />
      <ListItemSecondaryAction>
        <Tooltip title={label} placement="left">
          <Switch
            checked={checked}
            onChange={handleToggle}
            onFocus={window.focus(!checked, true)}
            onBlur={window.blur(true)}
            value={value}
            inputProps={{ label, 'aria-label': label }}
          />
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

Toggle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Toggle;
