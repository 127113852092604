import config from '../../config';
import { getUiStateById } from '../../slices/ui';
import store from '../../store';
import { removeMarkerLayer, showMarkerLayer } from '../layers/markerLayers';

const markerLayers = [
  { id: 'dining', zoomLevel: 'features' },
  { id: 'retail', zoomLevel: 'features' },
  { id: 'emergencyPhones', zoomLevel: 'emergencyPhones' },
];

// toggle marker manager icons based on street view visibility
export const addStreetViewVisibilityListener = (map) => {
  const sv = map.getStreetView();
  sv.addListener('visible_changed', () => {
    const visible = sv.getVisible();
    if (visible) {
      markerLayers.forEach(({ id }) => removeMarkerLayer(id));
    } else {
      const zoom = map.getZoom();
      markerLayers.forEach(({ id, zoomLevel }) => {
        const { min, max } = config.zoomLevel[zoomLevel];
        const toggled = getUiStateById(store.getState(), id);
        if (toggled && zoom <= max && zoom >= min) {
          showMarkerLayer(id);
        }
      });
    }
  });
};
