import { EmergencyPhoneIcon } from '../components/Sidebar/SidebarIcons';
import diningIcon from '../assets/restaurant.png';
import retailIcon from '../assets/shopping.png';

export default {
  highlights: {
    informationCenter: {
      fillColor: '#57C7EF',
      strokeColor: '#035B7A',
      fillOpacity: 0.7,
      strokeWeight: 3,
      strokeOpacity: 1,
    },
    academic: {
      fillColor: '#BDF27D',
      strokeColor: '#478000',
      fillOpacity: 0.7,
      strokeWeight: 3,
      strokeOpacity: 1,
    },
    studentLife: {
      fillColor: '#DDC0E2',
      strokeColor: '#81338D',
      fillOpacity: 0.7,
      strokeWeight: 3,
      strokeOpacity: 1,
    },
    housing: {
      fillColor: '#E6D0B2',
      strokeColor: '#B85500',
      fillOpacity: 0.7,
      strokeWeight: 3,
      strokeOpacity: 1,
    },
    athletic: {
      // fillColor: '#FFB9CA',
      // strokeColor: '#CD0034',
      fillColor: '#F9A79E',
      strokeColor: '#831508',
      fillOpacity: 0.7,
      strokeWeight: 3,
      strokeOpacity: 1,
    },
    healthCare: {
      fillColor: '#FFFEA9',
      strokeColor: '#B88900',
      fillOpacity: 0.7,
      strokeWeight: 3,
      strokeOpacity: 1,
    },
    parking: {
      fillColor: '#cc0033',
      fillOpacity: 0.3,
      strokeColor: '#cc0033',
      strokeWeight: 2,
      strokeOpacity: 0.7,
    },
    selected: {
      strokeWeight: 3,
      strokeOpacity: 0.9,
      strokeColor: '#cc0033',
    },
  },
  polygons: {
    buildings: {
      fillColor: '#5f6a72',
      fillOpacity: 0.9,
      strokeWeight: 2,
      strokeOpacity: 0.2,
      zIndex: 2,
    },
    parking: {
      fillColor: '#5f6a72',
      fillOpacity: 0.25,
      strokeWeight: 2,
      strokeOpacity: 0.1,
      zIndex: 1,
    },
    parcel: {
      fillColor: '#800080',
      fillOpacity: 0.4,
      strokeWeight: 2,
      strokeOpacity: 0.8,
      zIndex: 5,
    },
    districts: {
      default: {
        fillColor: '#ebb600',
        fillOpacity: 0.6,
        strokeWeight: 0,
        strokeOpacity: 0.1,
      },
      hover: {
        fillColor: '#ebb600',
        fillOpacity: 0.8,
        strokeWeight: 0,
        strokeOpacity: 0.1,
      },
    },
  },
  markers: {
    dining: diningIcon,
    retail: retailIcon,
    emergencyPhones: EmergencyPhoneIcon,
  },
  search: {
    heading: {
      background: '#cc0033',
      color: 'white',
      padding: '10px 0',
    },
    result: {
      paddingLeft: '10px',
      textAlign: 'left',
    },
  },
  buses: [
    '#cc0033', '#9ea900', '#ebb600', '#007fac', '#e76f00', '#00626d', '#703221',
  ],
  utilities: {
    // Add styles here based on ids
    gas: 'yellow',
    electric: 'red',
    communication: 'orange',
    telecommunication: 'darkorange',
    fiberoptic: 'coral',
    hightemphotwater: 'midnightblue',
    sanitarysewer: 'forestgreen',
    stormwater: 'mediumseagreen',
    water: 'royalblue',
  },
};
