import { addTileLayer, isTileLayerVisible, removeTileLayer, setBasemap } from '../layers/rasterLayers';
import { restyleVectorLayer } from '../layers/vectorLayers';
import store from '../../store';
import { getMap } from '../map/mapRef';
import { setUi } from '../../slices/ui';
import syncUrlWithState from '../syncUrl';
import { openSnackbar } from '../../slices/snackbar';

export default function onImageryToggle({ target }) {
  const satelliteOn = getMap().getMapTypeId() === 'hybrid';
  const imageryOn = isTileLayerVisible('aerialImagery');
  const { utilitiesOpen } = store.getState().ui;

  //  * if toggling on 2019 Aerial Imagery
  if (!imageryOn) {
    // console.log('%c ----------- IMAGERY TOGGLING ON ----------- ', 'font-weight: bold;')
    store.dispatch(openSnackbar({ msg: 'Aerial imagery on - may not be available in all areas' }));
    store.dispatch(setUi({ id: 'aerialImagery', value: true }));
    target.style.filter = 'invert(1)';
    target.parentElement.style.backgroundColor = 'rgba(204, 0, 51, 0.7)';
    addTileLayer('aerialImagery');
    setBasemap('planimetric_roads');

    if (!satelliteOn && utilitiesOpen) {
      removeTileLayer('buildingsParking');
      addTileLayer('buildingsParkingOutlines');
    }
  } else { // * if toggling off 2019 Aerial Imagery
    // console.log('%c ----------- IMAGERY TOGGLING OFF ----------- ', 'font-weight: bold;')
    store.dispatch(setUi({ id: 'aerialImagery', value: false }));
    target.style.filter = 'invert(0)';
    target.parentElement.style.backgroundColor = 'white';
    removeTileLayer('aerialImagery');
    if (!satelliteOn) {
      setBasemap('planimetric_basemap');
      if (utilitiesOpen) {
        removeTileLayer('buildingsParkingOutlines');
        addTileLayer('buildingsParking');
      }
    } else { // if google satellite is on
      setBasemap('planimetric_ortho_basemap');
    }
  }
  restyleVectorLayer('main');
  syncUrlWithState();
}
