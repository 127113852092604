import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  category: '',
  // optional fields
  fullWidth: false,
  forceConfirm: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, { payload: { category, fullWidth, forceConfirm } }) => {
      state.open = true;
      state.category = category;
      state.fullWidth = !!fullWidth;
      state.forceConfirm = !!forceConfirm;
    },
    closeModal: () => initialState,
  },
});

export const { openModal, closeModal } = modalSlice.actions;

// selectors
export const selectModalOpen = (state) => state.modal.open;
export const selectModalCategory = (state) => state.modal.category;

export default modalSlice.reducer;
