import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import { useSelector } from 'react-redux';
import { BusRouteToggle } from './BusRouteToggle';
import { selectCampus } from '../../../slices/view';
import { selectBusRoutesForCampus } from '../../../slices/busRoutes';
import { getBusState } from '../../../slices/ui';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    alignItems: 'center',
  },
  heading: {
    margin: 0,
    fontFamily: 'Arial,sans-serif',
    color: '#5f6a72',
    fontSize: '1em',
    fontWeight: 'lighter',
  },
  text: {
    textAlign: 'left',
    fontFamily: 'Arial,sans-serif',
    color: '#5f6a72',
    fontWeight: 'lighter',
    fontSize: '0.8em',
    margin: 10,
  },
  formGroup: {
    width: '80%',
  },
};

const BusLegend = () => {
  const busToggled = useSelector(getBusState);
  const campus = useSelector(selectCampus);
  const routes = useSelector(selectBusRoutesForCampus(campus));
  if (!busToggled || !routes) return null;
  return (
    <>
      <hr />
      <Box display="flex" flexDirection="column" alignItems="center">
        <h5 style={styles.heading}>Bus Routes</h5>
        <h5 style={styles.text}>
          Routes run at specific times: <a style={{ color: '#cc0033' }} href={(() => { switch (campus) { case 'newark': return 'http://rudots.rutgers.edu/nwktransport.shtml'; case 'camden': return 'http://rudots.rutgers.edu/campusbuses-cam.shtml'; default: return 'http://rudots.rutgers.edu/campusbuses.shtml'; } })()} target="_blank" rel="noopener noreferrer">learn more</a>
        </h5>
        <FormGroup sx={styles.formGroup}>
          {routes.map(({ route_id, route_long_name, on, route_color }, index) => (
            <BusRouteToggle
              key={route_long_name}
              title={route_long_name}
              on={on}
              color={route_color}
              id={route_id}
              index={index}
            />
          ))}
        </FormGroup>
      </Box>
    </>
  );
};

export default BusLegend;
