import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DirectionsIcon from './DirectionsIcon';

const styles = {
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0',
    width: '100%',
    textDecoration: 'none',
    borderRadius: '0px',
    backgroundColor: '#4c545b',
  },
  text: {
    paddingLeft: '10px',
    color: 'white',
  },
};

const Directions = ({ startIndex, url }) => {
  const tabIndex = startIndex || 1;

  const title = 'Open directions in Google Maps';
  return (
    <Tooltip title={title}>
      <Button
        color="secondary"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={title}
        variant="contained"
        component="a"
        tabIndex={tabIndex}
        sx={styles.link}
      >
        <DirectionsIcon color="white" />
        <Typography variant="h6" sx={styles.text}>Get Directions</Typography>
      </Button>
    </Tooltip>
  );
};

export default Directions;
