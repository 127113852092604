import { Loader } from '@googlemaps/loader';
import isAuthenticated from '../../isAuthenticated';

import loading from '../../loading';

import config from '../../../config';
import createMapOptions from '../../../config/mapOptions';
import { setMap } from '../mapRef';

import initMarkerWithLabel from '../../../libs/markerwithlabel';
import initStyledMarkerClusterer from '../../../libs/styledmarkerclusterer';

// import boundsChangeListener from '../../listeners/boundsChangeListener';
import clusterClickListener from '../../listeners/clusterClickListener';
import customMapControls from './customMapControls';
import store from '../../../store';
import { addStreetViewVisibilityListener } from '../../listeners/streetViewVisibilityListener';

export const initializeMap = async () => {
  const loadingMsg = 'Loading Google Maps API';
  loading.start(loadingMsg);
  // use appropriate credentials
  const credentials = isAuthenticated() ? config.googleMaps.internal : config.googleMaps.public;
  // set up Google Maps API loader with correct options (credentials are either apiKey or clientId)
  const loader = new Loader({ version: config.gmapsVersion, libraries: ['geometry'], ...credentials });
  // trigger loading of Google Maps API
  await loader.load();
  const { google } = window;

  // get zoom, lat, lng from view state (set by URL or default values)
  const { zoom, lat, lng } = store.getState().view;

  // set map options
  const mapOptions = createMapOptions(zoom, lat, lng);
  const map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
  setMap(map);

  // load in custom libraries
  window.MarkerWithLabel = initMarkerWithLabel(google);
  window.StyledMarkerClusterer = initStyledMarkerClusterer();

  /* -------------------------- SET LISTENERS ON THE MAP -------------------------- */

  // set listener for bounds change (shows/hides most layers)
  // boundsChangeListener(map);
  // set listener for 'clusterclick' event (for building labels and Off Campus Facilities)
  // set listener for 'clusterclick' event (for Off Campus Facilities)
  clusterClickListener(map);

  // hacky fix to remove cluster markers still showing up after "Jump to Location"
  // map.addListener('idle', () => {
  //   setTimeout(() => {
  //     const { ui: { zoom }, clusters: { toggled } } = store.getState();
  //     if (toggled && zoom > 14) store.dispatch(removeClusters());
  //   }, 50);
  // });

  // add listener for streetview visibility to toggle marker layer visibility
  addStreetViewVisibilityListener(map);

  // set panorama constrol position (when Streetview enabled)
  const pano = map.getStreetView();
  pano.setOptions({
    addressControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER,
    },
  });

  // add custom map controls
  customMapControls(map);

  loading.end(loadingMsg);
  return map;
};
