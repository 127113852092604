import store from '../../store';
import { setBusRoutesForCampus } from '../../slices/busRoutes';
import createBusRouteLayer from './createBusRouteLayer';
import loading from '../loading';
import config from '../../config';
import { getMap } from '../map/mapRef';
import { openSnackbar } from '../../slices/snackbar';

const { busDataBucket } = config;

const busLayers = {};

const dateFromInt = (dateInt) => {
  const dateString = dateInt.toString();
  return new Date(`${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`);
};

const dateIsToday = (dateString) => {
  const dateToCheck = new Date(dateString);
  const today = new Date();

  return dateToCheck.getFullYear() === today.getFullYear()
  && dateToCheck.getMonth() === today.getMonth()
  && dateToCheck.getDate() === today.getDate();
};

export const toggleOnBusesForCampus = async (campus) => {
  if (!campus) return;
  let routesData = busLayers[campus];
  if (!routesData) {
    const loadingMsg = 'Loading Bus Routes';
    try {
      loading.start(loadingMsg);
      busLayers[campus] = {};
      const url = `${busDataBucket}/routes/${campus}.json`;
      let data = await fetch(url).then((resp) => resp.json());

      const today = new Date();
      const day = today.getDay();
      const dayOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

      data = data.filter((route) => {
        route.trips = route.trips.filter((trip) => {
          const regularService = trip.calendars.some((cal) => {
            if (cal[dayOfWeek[day]] !== 1) return false;
            const startDate = dateFromInt(cal.start_date);
            const endDate = dateFromInt(cal.end_date);
            return startDate <= today && endDate >= today;
          });
          const addedService = trip.calendar_dates.some((cal) => {
            if (cal.exception_type !== 1) return false;
            const date = dateFromInt(cal.date);
            return dateIsToday(date);
          });
          const canceledService = trip.calendar_dates.some((cal) => {
            if (cal.exception_type !== 2) return false;
            const date = dateFromInt(cal.date);
            return dateIsToday(date);
          });
          return addedService || (regularService && !canceledService);
        });

        if (route.trips.length === 0) {
          return false;
        }
        const layer = createBusRouteLayer({ route, campus });
        busLayers[campus][route.route_id] = { ...route, layer };
        return true;
      });

      const reduxList = data.map((routeDetails) => ({ ...routeDetails, on: true }));
      // add array of routes to redux store
      store.dispatch(setBusRoutesForCampus({ campus, routes: reduxList }));
      loading.end(loadingMsg);
    } catch (error) {
      console.error({ msg: 'Error toggling on bus', error });
      const msg = 'Bus data not available for this campus at this time';
      store.dispatch(openSnackbar({ alertType: 'error', msg }));
    } finally {
      loading.end(loadingMsg);
    }
  }

  routesData = busLayers[campus];

  const { busRoutes } = store.getState();
  const routeList = busRoutes[campus];
  const map = getMap();
  routeList.forEach(({ route_id, on }) => {
    if (on) routesData[route_id].layer.setMap(map);
  });
};

export const toggleOffBusesForCampus = (campus) => {
  if (!campus || !busLayers[campus]) return;
  const routes = busLayers[campus];
  Object.values(routes).forEach(({ layer }) => layer.setMap(null));
};

export const toggleBusRouteLayer = ({ campus, id, toggled }) => {
  const layer = busLayers[campus] && busLayers[campus][id] && busLayers[campus][id].layer;
  if (!layer) return;
  layer.setMap(toggled ? getMap() : null);
};
