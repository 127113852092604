import { useSelector } from 'react-redux';
import config from '../../../config';

import shared_use_path from '../../../assets/shared_use_path.png';
import bike_lane from '../../../assets/bike_lane.png';
import bike_rack from '../../../assets/bike_rack.png';
import shared_lane_markings from '../../../assets/shared_lane_markings.png';
import { getBikeState } from '../../../slices/ui';
import { selectCampus } from '../../../slices/view';

const { legends } = config;
const images = { bike_rack, bike_lane, shared_lane_markings, shared_use_path };

const styles = {
  wrapper: {
    padding: 10,
  },
  heading: {
    margin: 0,
    fontFamily: 'Arial,sans-serif',
    color: '#5f6a72',
    fontSize: '1.2em',
    fontWeight: 'lighter',
    textAlign: 'center',
  },
  featureWrapper: {
    display: 'grid',
    gridTemplateColumns: '1.2fr 1fr',
    textAlign: 'left',
    alignItems: 'center',
    margin: 10,
    fontSize: '0.9em',
  },
  img: {
    justifySelf: 'center',
    maxHeight: 20,
    maxWidth: 60,
  },
};

const BikeLegend = () => {
  const bike = useSelector(getBikeState);
  const campus = useSelector(selectCampus);
  if (!campus || !bike) return null;
  return (
    <>
      <hr />
      <div>
        <h5 style={styles.heading}>Biking Legend</h5>
        <div style={styles.wrapper}>
          {legends.bike.map((filename) => {
            const name = filename.split('_').map((word) => word[0].toUpperCase() + word.substring(1)).join(' ');
            return (
              <div key={name} style={styles.featureWrapper}>
                <span>{name}</span>
                <img src={images[filename]} alt={name} style={styles.img} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BikeLegend;
