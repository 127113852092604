import toggleGeolocation from './toggleGeolocation';

import { isMobile } from '../../browserAndDeviceTests';

import myLocationImg from '../../../assets/my_location.svg';
import aerialImageryImg from '../../../assets/satellite-24px.svg';
import onMapTypeChange from '../../listeners/mapTypeSelect';
import onImageryToggle from '../../listeners/imageryToggle';
import { getMap } from '../mapRef';
import store from '../../../store';
import { openModal } from '../../../slices/modal';
import utilityLegendIcon from '../../../assets/layers-icon.svg';
import { toggleUtilitiesSidebar } from '../../../slices/ui';

function initMapTypeControl(map, google) {
  const mapTypeControlDiv = document.querySelector('.maptype-control');

  document.querySelector('.maptype-control-select').onchange = onMapTypeChange;

  map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
    mapTypeControlDiv,
  );
}

function initImageryControl(map, google) {
  const imageryControl = document.querySelector('.imagery-control');
  imageryControl.querySelector('img').src = aerialImageryImg;
  imageryControl.onclick = onImageryToggle;
  map.controls[google.maps.ControlPosition.RIGHT_TOP].push(imageryControl);
}

function initUtilityLegendControl() {
  const map = getMap();
  const utilityLegendControl = document.querySelector('.utility-legend-control');
  utilityLegendControl.querySelector('img').src = utilityLegendIcon;
  utilityLegendControl.onclick = () => {
    if (window.innerWidth >= 960) {
      const { ui: { utilitiesLegendSidebar } } = store.getState();
      return store.dispatch(toggleUtilitiesSidebar(!utilitiesLegendSidebar));
    }
    store.dispatch(openModal({ category: 'UTILITY_LEGEND' }));
  };
  map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(utilityLegendControl);
}

export const toggleUtilityLegendButton = (on) => {
  document.querySelector('.utility-legend-control').style.display = on ? 'block' : 'none';
  // ? this is some junky code that will force the map to properly render the button
  document.getElementById('map-canvas').style.height = '99%';
  setTimeout(() => {
    document.getElementById('map-canvas').style.height = '100%';
  }, 50);
};

function initZoomControl(map, google) {
  document.querySelector('.zoom-control-in').onclick = () => map.setZoom(map.getZoom() + 1);
  document.querySelector('.zoom-control-out').onclick = () => map.setZoom(map.getZoom() - 1);
  map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
    document.querySelector('.zoom-control'),
  );
}

function initGeolocationControl(map, google) {
  document.querySelector('.geolocation-control').onclick = toggleGeolocation;

  document.querySelector('.geolocation-control img').src = myLocationImg;

  map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
    document.querySelector('.geolocation-control'),
  );
}

export default function initMapControls(map) {
  const { google } = window;
  initMapTypeControl(map, google);
  initImageryControl(map, google);
  initUtilityLegendControl();
  initZoomControl(map, google);
  if (isMobile() && navigator.geolocation) {
    initGeolocationControl(map, google);
  }
  window._gmap = map;
}
