import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { VisuallyHidden } from '@react-aria/visually-hidden';

import MenuIconL from '@mui/icons-material/ChevronLeft';
import MenuIconR from '@mui/icons-material/ChevronRight';
import BuildIcon from '@mui/icons-material/Build';

import Show from '../../Show';
import SearchResults from './Results/Results';

import { setSearchResults, setQuery } from '../../../slices/search';
import { toggleSidebar } from '../../../slices/ui';
import { openModal } from '../../../slices/modal';
import { closeDrawer } from '../../../thunks/drawerClose';

import { mainSearchThunk } from '../../../thunks/search';

import isAuthenticated from '../../../utils/isAuthenticated';

const getStyles = ({ sidebar, authenticated }) => ({
  root: {
    position: sidebar ? 'static' : 'absolute',
    width: sidebar ? 'inherit' : '275px',
    zIndex: 1000,
    textAlign: 'left',
    backgroundColor: '#5f6a72',
    boxShadow: '5px 0px 15px rgba(0,0,0,0.15)',
  },
  toggle: {
    minWidth: '45px',
    marginTop: '6px',
  },
  utilitiesSearchButton: {
    minWidth: '45px',
    marginTop: '6px',
    marginLeft: '5px',
  },
  input: {
    margin: (theme) => theme.spacing(0.5),
    padding: (theme) => theme.spacing(0.5),
    backgroundColor: 'white',
    flexGrow: 1,
    width: authenticated ? '160px' : '220px',
  },
});

const Search = ({ width }) => {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.search);
  const sidebar = useSelector((state) => state.ui.sidebar);

  const authenticated = isAuthenticated();
  const styles = getStyles({ sidebar, authenticated });

  const disableUnderline = true;
  const { results, query } = search;
  const inputProps = {
    disableUnderline,
    label: 'Search by Place Name',
    'aria-label': 'Search by Place Name',
  };
  const menuToggleLabel = `${sidebar ? 'Close' : 'Open'} Menu`;

  const handleQueryChange = (event) => {
    dispatch(closeDrawer());
    const query = event.target.value;
    dispatch(mainSearchThunk(query));
  };

  const resetSearch = () => {
    dispatch(closeDrawer());
    dispatch(setSearchResults([]));
    dispatch(setQuery(''));
  };

  const openUtilitiesSearch = () => dispatch(openModal({ category: 'UTILITIES_SEARCH', fullWidth: true }));

  const SidebarIcon = sidebar ? MenuIconL : MenuIconR;

  const handleSidebarToggle = () => dispatch(toggleSidebar(!sidebar));

  return (
    <Box sx={styles.root}>
      <Tooltip id="tooltip-search" title={menuToggleLabel}>
        <Button sx={styles.toggle} size="small" onClick={handleSidebarToggle} aria-label={menuToggleLabel} color="secondary">
          <SidebarIcon />
        </Button>
      </Tooltip>
      <VisuallyHidden>
        <h2 className="side-menu-heading" aria-label="Search for buildings, parkings lots, campuses, and off campus facilities">
          Search
        </h2>
      </VisuallyHidden>
      <TextField
        id="search"
        sx={styles.input}
        placeholder="Search..."
        InputProps={inputProps}
        value={query}
        type="search"
        onChange={handleQueryChange}
        margin="dense"
        size="small"
        variant="standard"
      />
      <Show test={isAuthenticated()}>
        <Tooltip title="Search for utilities via AIM Number, Legacy CAD ID, or RCMS ID">
          <Button
            sx={styles.utilitiesSearchButton}
            size="small"
            onClick={openUtilitiesSearch}
            aria-label="Open utilities search"
            color="secondary"
          >
            <BuildIcon />
          </Button>
        </Tooltip>
      </Show>
      <Show test={query}>
        <SearchResults query={query} results={results} sidebar={sidebar} resetSearch={resetSearch} width={width} />
      </Show>
    </Box>
  );
};

export default Search;
