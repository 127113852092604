export default {
  googleMaps: {
    public: { clientId: 'gme-rutgersuniversity1' },
    internal: { clientId: 'gme-rutgersuniversity2' },
  },
  busDataBucket: 'https://storage.googleapis.com/rutgers-campus-map-prod-bus-sync',
  syncDataBucket: 'https://storage.googleapis.com/rutgers-campus-map-prod-public-sync',
  dataBucket: 'https://storage.googleapis.com/rutgers-campus-map-public-data-prod',
  buildingImagesBucket: 'https://storage.googleapis.com/rutgers-campus-map-building-images-prod',
  assetsBucket: 'https://storage.googleapis.com/rutgers-campus-map-assets-prod',
  imageryBucket: 'https://storage.googleapis.com/rutgers-prod-2019-imagery',
};
