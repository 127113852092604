import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { getUiStateById } from '../../slices/ui';
import { toggleLayerThunk } from '../../thunks/uiToggle';

const getStyles = ({ color }) => ({
  checkbox: {
    borderRadius: '5px',
    padding: '5px',
    margin: '5px',
    '&:hover': { backgroundColor: 'grey !important' },
  },
  white: {
    color: 'white',
  },
  svg: {
    width: '1.1em',
    height: '1.1em',
  },
  checked: {
    backgroundColor: `${color} !important`,
    color: 'white',
  },
});

// Create Accessibility Labels
const createInputProps = (on, title) => {
  const label = `${on ? 'Hide' : 'Show'} ${title}`;
  return { label, 'aria-label': label };
};

const IconButtonToggle = ({ name, id, Icon, color }) => {
  const theme = useTheme();
  color = color || theme.palette.primary.main;
  const styles = getStyles({ color });
  const toggled = useSelector(getUiStateById(id));
  const dispatch = useDispatch();
  const handleChange = (e) => dispatch(toggleLayerThunk(e.target.value));

  return (
    <Tooltip title={name} placement="top">
      <Checkbox
        id={id}
        value={id}
        checked={toggled}
        color="primary"
        onChange={handleChange}
        sx={{ ...styles.checkbox, ...(toggled ? styles.checked : {}) }}
        checkedIcon={<Icon style={{ ...styles.svg, ...styles.white }} />}
        icon={<Icon style={styles.svg} />}
        name={name}
        inputProps={createInputProps(toggled, name)}
      />
    </Tooltip>
  );
};

export default IconButtonToggle;
