import config from '..';
import { getSyncId } from '../../utils/getSyncId';
import vectorStyles from './vectorStyles';

export default {
  districts: {
    level: 'districts',
    idPropertyName: 'name',
    style: vectorStyles.districts,
    getUrl: () => `${config.dataBucket}/districts.json`,
  },
  main: {
    level: 'features',
    idPropertyName: 'id',
    getUrl: () => `${config.syncDataBucket}/${getSyncId()}/buildings-parking-layer.json`,
    style: vectorStyles.main,
  },
  parcel: {
    level: 'features',
    idPropertyName: 'id',
    style: vectorStyles.parcel,
    internal: true,
  },
  // layer to show selected features (when utilities toggled on)
  clickSelection: {
    level: 'features',
    style: vectorStyles.clickSelection,
  },
};
