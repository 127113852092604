import selectFeature from '../selectFeature';

const featureClick = async ({ feature }) => {
  const id = feature.getId() || feature.getProperty('id');
  // console.log('id', id);
  const category = feature.getProperty('category');
  // console.log('category', category);
  await selectFeature({ id, category });
};

export default featureClick;
