import config from '../../config';
import { getSiteIdCrosswalk } from '../siteIds/getSiteIds';

const { search: { totalResults } } = config;

export default function parseSearchResults(results) {
  // seperate out results by campus
  const resultsByCampus = results.reduce((results, { item }) => {
    const { campus } = item;
    if (!results[campus]) results[campus] = [];
    if (results[campus].length < totalResults) {
      // if district entry, add to front of the array, otherwise, push to the back
      results[campus][item.district ? 'unshift' : 'push'](item);
    }
    return results;
  }, {});

  const resultsSortedByCampus = [];
  const campuses = Object.keys(resultsByCampus);

  // add entries for each campus
  campuses.forEach((campus) => {
    // add heading entry
    const siteIdCrosswalk = getSiteIdCrosswalk();
    const heading = siteIdCrosswalk[campus];
    if (!heading) return console.warn('Issue with search data for campus:', campus);

    resultsSortedByCampus.push({ heading, campus });

    // add current campus' entries to array
    resultsByCampus[campus].forEach((entry) => {
      resultsSortedByCampus.push(entry);
    });
  });

  return resultsSortedByCampus;
}
