let syncIdPromise;
let syncId;

const THIRTY_MINUTES = 1000 * 60 * 30;
// check for new sync ID in 30 minutes
let timeToRunAgain = Date.now() + THIRTY_MINUTES;

async function requestNewSyncId() {
  try {
    console.log('requesting new Sync ID');
    // set a 30 minute timer for when to check for a new Sync ID again
    timeToRunAgain = Date.now() + THIRTY_MINUTES;
    // get the latest sync ID
    const { syncId: newSyncId } = await fetch('/api/syncId').then((resp) => resp.json());
    // set new sync ID if there is a new one
    if (syncId !== newSyncId) {
      console.log('Sync ID Updated', newSyncId);
      syncId = newSyncId;
    }
    return syncId;
  } catch (error) {
    console.error({ msg: 'Error fetching Sync ID', error });
  }
}

export const getSyncId = () => {
  if (Date.now() > timeToRunAgain) {
    syncIdPromise = requestNewSyncId();
  }
  return syncId;
};

export const getSyncIdAsync = async () => {
  // if expired or no sync ID found, request new sync ID
  if (!syncIdPromise || Date.now() > timeToRunAgain) {
    // set syncIdPromise so that subsequent requests wait for a single promise to resolve
    syncIdPromise = requestNewSyncId();
  }
  // wait for sync ID request to resolve
  syncId = await syncIdPromise;
  return syncId;
};
