import { useState } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import UtilitiesSearchResult from './UtilitiesSearchResult';
import selectFeature from '../../utils/selectFeature';
import searchForUtilities from '../../utils/internalAgolData/utilitiesSearch';

const styles = {
  autocomplete: {
    width: '100%',
  },
  search: {
    zIndex: 1600,
  },
  statusMsg: {
    fontWeight: 'bold',
  },
};

const renderOption = (props, option) => <UtilitiesSearchResult option={option} {...props} />;

let storedQuery = '';

const UtilitiesSearchModal = ({ onClose }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChange = (e, option) => {
    const { layer_name, unique_id, network_name } = option;
    onClose();
    selectFeature({ data: option, id: unique_id, category: 'utility', layerName: layer_name, NetworkName: network_name });
  };

  const handleTextChange = (e, value) => {
    storedQuery = value;
    setQuery(value);
    if (!value) setResults([]);
    else {
      setLoading(true);
      searchForUtilities(value).then((data) => {
        // check to see if value has been changed or not
        if (value === storedQuery) setResults(data);
        setLoading(false);
      });
    }
  };

  let statusMessage = '';
  if (query && !results.length) statusMessage = 'No Results';
  else if (results.length) statusMessage = `${results.length} Results`;
  else if (!results.length) statusMessage = 'No Results';

  return (
    <>
      <DialogTitle>Search Utilities</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a Unique ID by which to search. Valid IDs include AIM Number, Legacy CAD ID, and RCMS ID.
          <br /><span style={styles.statusMsg}>{statusMessage}</span>
        </DialogContentText>
        <Autocomplete
          id="utilities-search"
          ListboxProps={{ id: 'utilities-search-result-list' }}
          aria-controls="utilities-search-result-list"
          sx={styles.autocomplete}
          options={results}
          getOptionLabel={({ source_id, aim_asset_number, rcms_id }) => `SourceID: ${source_id} AIMAssetNumber: ${aim_asset_number} RCMS_ID: ${rcms_id}`}
          renderOption={renderOption}
          loading={loading}
          popupIcon={<div />}
          onChange={onChange}
          clearOnBlur={false}
          clearOnEscape
          onInputChange={handleTextChange}
          groupBy={({ network_name }) => network_name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search..."
              variant="outlined"
              fullWidth
              autoFocus
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="primary">
          close
        </Button>
      </DialogActions>
    </>
  );
};

export default UtilitiesSearchModal;
