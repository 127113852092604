import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import FeatureResult from './FeatureResult';
import ResultCampusHeading from './ResultCampusHeading';
import ResultDistrictHeading from './ResultDistrictHeading';
import { selectSidebarOpen } from '../../../../slices/ui';
import { selectSearchResults } from '../../../../slices/search';

const getStyles = ({ width, sidebar }) => ({
  results: {
    boxShadow: '2px 2px 4px',
    overflow: 'scroll',
    position: 'absolute',
    width,
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '85vh',
    overflowY: 'scroll',
    top: sidebar ? '124px' : '45px',
    left: '0px',
    background: 'white',
    zIndex: 1000,
  },
  noResults: {
    background: 'white',
    width: '100%',
  },
});

const SearchResults = ({ width, resetSearch }) => {
  const sidebar = useSelector(selectSidebarOpen);
  const results = useSelector(selectSearchResults);
  const styles = getStyles({ sidebar, width });

  // if no results
  if (!results.length) {
    return (
      <div style={styles.noResults}>
        <Typography align="center">No results</Typography>
      </div>
    );
  }

  return (
    <div style={styles.results}>{
      results.map((result) => {
        if (result.heading) {
          return <ResultCampusHeading key={`heading-${result.campus}`} result={result} resetSearch={resetSearch} />;
        }
        if (result.district) {
          return <ResultDistrictHeading key={`district-${result.district}`} result={result} resetSearch={resetSearch} />;
        }
        return <FeatureResult key={`${result.id}-${result.name}-${result.type}-${result.address}`} result={result} resetSearch={resetSearch} />;
      })
}
    </div>
  );
};

export default SearchResults;
