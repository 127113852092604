export function changeCase(string) {
  return string.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function toCamelCase(text) {
  return text
    .toLowerCase()
    .split(' ')
    .map((word, i) => {
      if (!i) return word;
      return `${word[0].toUpperCase()}${word.substring(1)}`;
    })
    .join('');
}
