import { createSlice } from '@reduxjs/toolkit';

const disclaimerSlice = createSlice({
  name: 'disclaimer',
  initialState: {
    content: '',
  },
  reducers: {
    setDisclaimerContent: (state, { payload }) => {
      state.content = payload;
    },
  },
});

export const { setDisclaimerContent } = disclaimerSlice.actions;

// selectors
export const selectDisclaimerContent = (state) => state.disclaimer.content;

export default disclaimerSlice.reducer;
