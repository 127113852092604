import load from '../loading';
import config from '../../config';
import { getSyncId } from '../getSyncId';

async function getBoundsForCampus(id) {
  const url = `${config.syncDataBucket}/${getSyncId()}/boundaries/details/${id}.json`;
  const { bounds } = await fetch(url).then((x) => x.json());
  return bounds;
}

// logic to properly load the boundaries of each cluster and zoom to them
export default function addClusterClickListener(map) {
  const { google } = window;
  google.maps.event.addListener(map, 'clusterclick', async (cluster) => {
    const markers = cluster.getMarkers();
    // if cluster is a building/parking label cluster, exit function
    if (Object.prototype.hasOwnProperty.call(markers[0], 'labelRank')) return;
    load.start('loading cluster bounds');
    // if marker
    if (markers.length <= 5) {
      // TODO: convert this into a utility function for getting extents

      const allEncompassingBounds = new window.google.maps.LatLngBounds();
      const allBounds = await Promise.all(markers.map(({ id }) => getBoundsForCampus(id)));
      allBounds.forEach((bounds) => allEncompassingBounds.union(bounds));
      map.fitBounds(allEncompassingBounds);

      return load.end('loading cluster bounds');
    }

    const bounds = new google.maps.LatLngBounds();
    markers.forEach((marker) => bounds.extend(marker.getPosition()));
    map.fitBounds(bounds);
    load.end('loading cluster bounds');
  });
}
