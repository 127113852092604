import { MarkerManager } from '@googlemaps/markermanager';
import { getMap } from '../map/mapRef';

import markerConfig from '../../config/layers/marker';
import store from '../../store';
import { setInfoDrawerFeatures } from '../../slices/infoDrawer';

const markerLayers = {};
const markersCache = {};
const loading = {};

export const createMarkerManager = () => new Promise((resolve) => {
  const { google } = window;
  const map = getMap();
  const mgr = new MarkerManager(map, {});
  google.maps.event.addListener(mgr, 'loaded', () => resolve(mgr));
  // stupid hack to get marker manager to load properly
  const zoom = map.getZoom();
  map.setZoom(zoom + 1);
  map.setZoom(zoom);
});

export const addMarkerManagedLayer = async (layerId, markers) => {
  const { google } = window;
  let mgr = markerLayers[layerId];
  if (process.env.REACT_APP_LOCAL) console.log('Creating Marker layer', layerId);
  if (!mgr) {
    const { idField, level: { min, max }, getUrl, icon, clickable } = markerConfig[layerId];
    mgr = await createMarkerManager(max);
    markerLayers[layerId] = mgr;
    markers = markers || markersCache[layerId];
    if (!markers) {
      const data = await fetch(getUrl()).then((resp) => resp.json());

      markers = data.features.map(({ geometry, properties }) => {
        const [lng, lat] = geometry.coordinates;
        const id = properties[idField];

        // create marker
        const marker = new google.maps.Marker({
          position: { lat, lng },
          id,
          icon: { url: icon, scaledSize: new google.maps.Size(30, 30) },
          zIndex: 1000,
          title: clickable ? id : undefined,
          clickable,
        });

        // add click handler
        if (clickable) {
          marker.addListener('click', () => {
            store.dispatch(setInfoDrawerFeatures([{ category: layerId, details: { ...properties, name: id } }]));
          });
        }
        return marker;
      });
      markersCache[layerId] = markers;
    }

    mgr.addMarkers(markers, min, max);
  }
  mgr.refresh();
  return mgr;
};

export const removeMarkerLayer = async (layerId) => {
  const mgr = markerLayers[layerId];
  if (!mgr) return;
  mgr.clearMarkers();
  delete markerLayers[layerId];
};

export const getMarkerLayer = (layerId) => markerLayers[layerId];

export const showMarkerLayer = async (layerId) => {
  let layer = getMarkerLayer(layerId);
  if (loading[layerId]) return;
  if (!layer) {
    loading[layerId] = true;
    layer = await addMarkerManagedLayer(layerId);
  }
  markerLayers[layerId].refresh();
  markerLayers[layerId].show();
  loading[layerId] = false;
};
