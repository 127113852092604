import config from '../../config';
import { districts } from '../../config/locations';
import { getMap } from '../map/mapRef';

function setStyles(layer) {
  layer.setStyle((feature) => {
    const hover = feature.getProperty('hover');
    if (hover) {
      return config.styles.polygons.districts.hover;
    }
    return config.styles.polygons.districts.default;
  });
}

export default function addDistrictPolyListeners(layer) {
  const map = getMap();

  layer.addListener('click', ({ feature }) => {
    const district = feature.getId();
    // const source = district === 'camden' ? mainCampuses : districts;
    // const { position, zoom } = source[district];
    const { position, zoom } = districts[district];
    map.panTo(position);
    map.setZoom(zoom);
  });

  // set default layer styles
  setStyles(layer);

  // add hover styles
  layer.addListener('mouseover', ({ feature }) => {
    feature.setProperty('hover', true);
    setStyles(layer);
  });

  // remove hover styles on 'mouseout'
  layer.addListener('mouseout', ({ feature }) => {
    feature.setProperty('hover', false);
    setStyles(layer);
  });
}
