import phoneIcon from '../../assets/phoneIcon.svg';
import config from '..';
import diningIcon from '../../assets/restaurant.png';
import retailIcon from '../../assets/shopping.png';
import { getSyncId } from '../../utils/getSyncId';

const { zoomLevel } = config;

export default {
  campuses: {
    level: zoomLevel.state,
  },
  districts: {
    level: zoomLevel.districts,
  },
  dining: {
    level: zoomLevel.features,
    getUrl: () => `${config.syncDataBucket}/${getSyncId()}/dining.json`,
    idField: 'Dine_Unit',
    icon: diningIcon,
    clickable: true,
  },
  retail: {
    level: zoomLevel.features,
    getUrl: () => `${config.syncDataBucket}/${getSyncId()}/retail.json`,
    idField: 'Retail_Uni',
    icon: retailIcon,
    clickable: true,
  },
  emergencyPhones: {
    level: zoomLevel.emergencyPhones,
    getUrl: () => `${config.syncDataBucket}/${getSyncId()}/emergency-phones-layer.json`,
    idField: 'id',
    clickable: false,
    icon: phoneIcon,
  },
};
