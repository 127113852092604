import { openSnackbar } from '../../slices/snackbar';
import store from '../../store';
import asyncDebounce from '../asyncDebounce';

async function searchForUtilities(query) {
  try {
    const results = await fetch(`/api/utilities/search?q=${encodeURI(query)}`).then((resp) => resp.json());
    return results || [];
  } catch (e) {
    console.log('ERROR searching utilities by ID w/ query of ', query, e);
    store.dispatch(openSnackbar({ msg: 'Error searching utilities. Please try again.' }));
    return [];
  }
}

export default asyncDebounce((query) => searchForUtilities(query));
