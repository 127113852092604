import createOfcMarker from './createOcfMarker';
import ocfClusterStyleFunc from './ocfClusterStyleFunc';
import ocfCalculator from './ocfCalculator';
import { getMap } from '../../map/mapRef';
import load from '../../loading';
import selectFeature from '../../selectFeature';
import { getSyncIdAsync } from '../../getSyncId';
import config from '../../../config';

let loading = false;

export default async function createOfcClusterer() {
  const { StyledMarkerClusterer } = window;
  const map = getMap();

  if (!loading) {
    loading = true;
    const loadingMsg = 'creating off campus facility clusters';
    load.start(loadingMsg);
    try {
      const syncId = await getSyncIdAsync();
      const url = `${config.syncDataBucket}/${syncId}/boundaries/all-boundaries.json`;
      const campusBoundaries = await fetch(url).then((resp) => resp.json());

      const markers = [];

      // create array of markers (campus / off-campus facilities centroids)
      campusBoundaries.forEach(({ campus, site_id, latLng }) => {
        const marker = createOfcMarker({ campus, site_id, latLng });
        if (marker) {
          marker.addListener('click', () => selectFeature({ campus: site_id }));
          markers.push(marker);
        }
      });

      /* ------------------------ CREATE/ADD MARKER CLUSTERS ------------------------ */

      const options = {
        averageCenter: true,
        gridSize: 70,
        styleFunc: ocfClusterStyleFunc,
        zoomOnClick: false,
        ocf: true,
        clusterClass: 'ocfs',
      };

      const clusterer = new StyledMarkerClusterer(map, markers, options);
      clusterer.setCalculator(ocfCalculator);

      return { markers, clusterer };
    } catch (error) {
      console.error('error creating OFC clusterer', error);
      // TODO: alert user
    } finally {
      loading = false;
      load.end(loadingMsg);
    }
  }
}
