import { getSyncIdAsync } from '../getSyncId';
import config from '../../config';

let siteIdCrosswalk;

export const getSiteIdCrosswalk = () => siteIdCrosswalk;

export const fetchSiteIdCrosswalk = async () => {
  if (siteIdCrosswalk) return getSiteIdCrosswalk;

  try {
    const syncId = await getSyncIdAsync();
    const url = `${config.syncDataBucket}/${syncId}/site_ids.json`;
    const resp = await fetch(url);
    siteIdCrosswalk = await resp.json();
  } catch (error) {
    console.log('ERROR: fetching Site ID JSON file');
    throw error;
  }
};
