import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import UtilityLegend from '../UtilityLegend/UtilityLegend';

const UtilityLegendModal = ({ onClose }) => (
  <>
    <DialogContent>
      <UtilityLegend />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose} color="primary">
        close
      </Button>
    </DialogActions>
  </>
);

export default UtilityLegendModal;
