import { createAsyncThunk } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';

import { setQuery, setSearchResults } from '../slices/search';
import config from '../config';

import loading from '../utils/loading';
import parseSearchResults from '../utils/search/parseSearchResults';
import { getSyncId } from '../utils/getSyncId';
import { fetchSiteIdCrosswalk, getSiteIdCrosswalk } from '../utils/siteIds/getSiteIds';
import { openSnackbar } from '../slices/snackbar';

const { search: { options } } = config;

let fuse;

export const mainSearchThunk = createAsyncThunk(
  'search/getResultsByCampus',
  async (query, thunkAPI) => {
    thunkAPI.dispatch(setQuery(query));
    loading.start('search results');

    try {
      // load Site ID -> Campus crosswalk
      const site_id_crosswalk = getSiteIdCrosswalk();
      if (!site_id_crosswalk) {
        await fetchSiteIdCrosswalk();
      }

      // create instance of fuse fuzzy search
      if (!fuse) {
        const url = `${config.syncDataBucket}/${getSyncId()}/search.json`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Failed to fetch search data. Server responded with ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();
        fuse = new Fuse(Object.values(data), options);
      }

      const results = fuse.search(query);
      const resultsSortedByCampus = parseSearchResults(results);

      thunkAPI.dispatch(setSearchResults(resultsSortedByCampus));
    } catch (e) {
      console.log('ERROR DURING SEARCH:', e);
      thunkAPI.dispatch(openSnackbar({ alertType: 'error', msg: 'Error fetching search result data. Please try again.' }));
      throw e;
    } finally {
      loading.end('search results');
    }
  },
);
