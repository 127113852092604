import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import { VisuallyHidden } from '@react-aria/visually-hidden';

import { fetchPopularDestinations } from '../../thunks/fetchPopularDestinations';

import selectFeature from '../../utils/selectFeature';

// Accessibility Labels
const inputProps = {
  select: {
    label: 'Select Destination',
    'aria-label': 'Select Destination',
    role: 'listbox',
  },
};

const styles = {
  root: {
    padding: 1,
  },
};

const PopularDestinations = ({ campus }) => {
  const handleSelect = ({ target: { value } }) => selectFeature({ id: value, category: 'building' });

  const dispatch = useDispatch();
  const popularDestinations = useSelector(({ popularDestinations }) => popularDestinations);

  useEffect(() => {
    dispatch(fetchPopularDestinations());
  }, []);

  const destinations = popularDestinations && popularDestinations[campus];
  if (!destinations) return '';
  return (
    <Box sx={styles.root}>
      <VisuallyHidden>
        <h2 aria-label="Zoom to popular destinations for this campus">Popular Destinations</h2>
      </VisuallyHidden>
      <Select
        native
        value="Popular Destinations"
        onChange={handleSelect}
        input={<Input id="pop-dests-native" inputProps={inputProps.select} />}
        fullWidth
        variant="standard"
      >
        <option value="Select Destination">Popular Destinations</option>
        {destinations.map(({ id, destination }) => <option key={id} value={id} label={destination} aria-label={destination}>{destination}</option>)}
      </Select>
    </Box>
  );
};

export default PopularDestinations;
