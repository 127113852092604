import isMainCampus from '../../isMainCampus';
import { getMap } from '../../map/mapRef';

// styling function for marker clusters
export default function ocfClusterStyleFunc(markers) {
  const map = getMap();
  let size = 10;
  const styles = {
    'background-color': '#007fac',
    'z-index': 2,
    'font-size': '14px',
  };
  const containsCampus = markers.find((marker) => isMainCampus(marker.id));
  if (containsCampus) {
    size = 15;
    styles['background-color'] = '#cc0033';
    styles['z-index'] = 10001;
    if (map.getZoom() > 11) {
      styles.display = 'none';
    }
  }
  return { size, styles };
}
