import config from '..';

const rastersLayers = {
  nj_state_boundary: {
    order: 0,
    url: '/nj-mask/v1',
    opacity: 0.4,
    alwaysOn: true,
  },
  aerialImagery: {
    order: 1,
    ext: '.png',
    swapXY: true,
    url: config.imageryBucket,
  },
  /* -------------------------- basemaps -------------------------- */
  planimetric_basemap: {
    order: 2,
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/planimetric_basemap/MapServer/tile',
    // AGOL layer removed after we agreed to set map max zoom to 21
    // level22Url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/planimetric_basemap_L22/MapServer/tile',
    alwaysOn: true,
  },
  planimetric_ortho_basemap: {
    order: 2,
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/planimetric_ortho_basemap/MapServer/tile',
    // AGOL layer removed after we agreed to set map max zoom to 21
    // level22Url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/planimetric_ortho_basemap_L22/MapServer/tile',
    alwaysOn: true,
  },
  planimetric_roads: {
    order: 2,
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/planimetric_roads/MapServer/tile',
    // AGOL layer removed after we agreed to set map max zoom to 21
    // level22Url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/planimetric_roads_L22/MapServer/tile',
    alwaysOn: true,
  },
  /* -------------------------- transport highlighting (walkways / biking) -------------------------- */
  walk: {
    order: 4,
    url: 'https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/RUTGERS_Walkways_Highlight/MapServer/tile',
  },
  bike: {
    order: 5,
    url: 'https://services1.arcgis.com/ze0XBzU1FXj94DJq/arcgis/rest/services/RUTGERS_Biking/MapServer/tile',
  },
  /* -------------------------- building, parking, parcel layers (for use w/ utilities) -------------------------- */
  buildingsParking: {
    order: 6,
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/internal_buildings_parkinglots/MapServer/tile',
    // AGOL layer removed after we agreed to set map max zoom to 21
    // level22Url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/internal_buildings_parkinglots_L22/MapServer/tile',
  },
  buildingsParkingOutlines: {
    order: 6,
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/buildings_parkinglots_outlines/MapServer/tile',
    // AGOL layer removed after we agreed to set map max zoom to 21
    // level22Url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/buildings_parkinglots_outlines_L22/MapServer/tile',
  },
  parcel: {
    order: 7,
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/internal_parcel_tiles/MapServer/tile',
    // AGOL layer removed after we agreed to set map max zoom to 21
    // level22Url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/internal_parcel_tiles_L22/MapServer/tile',
    internal: true,
  },
  /* ------------- utilities ------------- */
  CompressedGas: {
    NetworkName: 'CompressedGas',
    title: 'Compressed Gas',
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/compressedgas/MapServer/tile',
    order: 8,
    utility: true,
    internal: true,
  },
  Communications: {
    NetworkName: 'Communications',
    title: 'Communications',
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/communications/MapServer/tile',
    order: 9,
    utility: true,
    internal: true,
  },
  Fuel: {
    NetworkName: 'Fuel',
    title: 'Fuel',
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/fuel/MapServer/tile',
    order: 10,
    utility: true,
    internal: true,
  },
  CombinedSewer: {
    NetworkName: 'CombinedSewer',
    title: 'Sewer',
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/combinedsewer/MapServer/tile',
    // AGOL layer removed after we agreed to set map max zoom to 21
    // level22Url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/combinedsewer_L22/MapServer/tile',
    order: 11,
    utility: true,
    internal: true,
  },
  Stormwater: {
    NetworkName: 'Stormwater',
    title: 'Stormwater',
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/stormwater/MapServer/tile',
    order: 12,
    utility: true,
    internal: true,
  },
  Water: {
    NetworkName: 'Water',
    title: 'Water',
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/Water_/MapServer/tile',
    order: 13,
    utility: true,
    internal: true,
  },
  Electric: {
    NetworkName: 'Electric',
    title: 'Electric',
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/electric/MapServer/tile',
    order: 14,
    utility: true,
    internal: true,
  },
  HeatingandCooling: {
    NetworkName: 'HeatingandCooling',
    title: 'Heating & Cooling',
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/heatingandcooling/MapServer/tile',
    order: 15,
    utility: true,
    internal: true,
  },
  All_networks: {
    NetworkName: 'All_networks',
    title: 'All Utilities',
    url: 'https://tiles.arcgis.com/tiles/ze0XBzU1FXj94DJq/arcgis/rest/services/all_networks/MapServer/tile',
    order: 16,
    utility: true,
    internal: true,
  },
};

export default rastersLayers;

export const utilityList = Object.entries(rastersLayers)
  .filter(([, { utility }]) => utility)
  .sort((a, b) => {
    if (a[1].order > b[1].order) return -1;
    if (a[1].order < b[1].order) return 1;
    return 0;
  }).map(([layerId, { title }]) => ({ layerId, title }));
