export default function ocfCalculator(markers, numStyles) {
  let index = 0;
  const count = markers.length;
  let dv = count;
  while (dv !== 0) {
    dv = parseInt(dv / 10, 10);
    index++;
  }

  index = Math.min(index, numStyles);

  // allow clusters to be selected via keyboard and include a list of names for each location within cluser
  const listOfLocationNames = markers.map((marker) => marker.campus);
  listOfLocationNames.sort();
  listOfLocationNames[listOfLocationNames.length - 1] = ` and ${listOfLocationNames[listOfLocationNames.length - 1]}`;
  const locationNamesText = listOfLocationNames.join(', ');
  const labelContent = `
    <button
      tabindex="0"
      class="ocf-cluster"
      aria-label="Zoom to cluster of ${count} locations that include ${locationNamesText}"
      onfocus="this.parentElement.classList.add('shadow')"
      onblur="this.parentElement.classList.remove('shadow')"
    >
      ${count}
    </button>
  `;

  return {
    text: labelContent,
    index,
  };
}
