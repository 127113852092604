import { getMap } from '../map/mapRef';

import rasterConfig from '../../config/layers/raster';
import { getRasterTokenQuery } from '../internalAgolData/agolToken';

const rasterLayers = {};

// track which layers should display when raster/tile layers are enabled
// this allows these layers to be tracked, even after removing them from the map at lower zoom levels
const enabledLayers = new Set();

const createTileFunc = ({ url, internal, level22Url, ext, swapXY }) => {
  const fileExtension = ext || '';

  // AGOL uses /z/y/x but aerial imagery in GCS is /z/x/y
  if (swapXY) {
    if (!level22Url) return (coord, zoom) => `${url}/${zoom}/${coord.x}/${coord.y}${fileExtension}`;
    // if there is a separate layer for level 22 tiles, return conditional function
    return (coord, zoom) => {
      if (zoom === 22) return `${level22Url}/${zoom}/${coord.x}/${coord.y}${fileExtension}`;
      return `${url}/${zoom}/${coord.x}/${coord.y}${fileExtension}`;
    };
  }

  /* ------- for AGOL URLs ------- */
  if (!level22Url) return (coord, zoom) => `${url}/${zoom}/${coord.y}/${coord.x}${fileExtension}${getRasterTokenQuery(internal)}`;
  // if there is a separate layer for level 22 tiles, return conditional function
  return (coord, zoom) => {
    if (zoom === 22) return `${level22Url}/${zoom}/${coord.y}/${coord.x}${fileExtension}${getRasterTokenQuery(internal)}`;
    return `${url}/${zoom}/${coord.y}/${coord.x}${fileExtension}${getRasterTokenQuery(internal)}`;
  };
};

function createTileLayer(layerId, details) {
  const { google } = window;
  const { opacity, alwaysOn, order } = details;
  const imageMapType = new google.maps.ImageMapType({
    getTileUrl: createTileFunc(details),
    tileSize: new google.maps.Size(256, 256),
    // minZoom: category === 'bike' ? 15 : 16,
    // minZoom: 16,
    opacity: opacity || 1,
    name: layerId,
    alt: { order, alwaysOn },
  });
  return imageMapType;
}

// create Google Maps ImageMapType layers for each raster layer
export const createTileLayers = () => {
  Object.entries(rasterConfig).forEach(([layerId, details]) => {
    const imageMapType = createTileLayer(layerId, details);
    rasterLayers[layerId] = imageMapType;
  });
};

export const isTileLayerVisible = (layerId) => {
  const map = getMap();
  const { overlayMapTypes } = map;
  let alreadyExists;
  overlayMapTypes.forEach((existingLayer) => {
    if (existingLayer.name === layerId) alreadyExists = true;
  });
  return alreadyExists;
};

// ? use for debugging
// const testColors = ['#035B7A', '#478000', '#81338D', '#B85500', '#CD0034', '#831508', '#B88900', '#cc0033', '#800080', '#9ea900', '#ebb600', '#007fac', '#e76f00', '#00626d', '#703221'];

export const addTileLayer = (layerId) => {
  // if (process.env.REACT_APP_LOCAL) console.log('toggling on raster layer', layerId);
  const map = getMap();
  const { overlayMapTypes } = map;
  if (!rasterConfig[layerId]) console.log('BAD LAYER ID', layerId);
  const { order, alwaysOn } = rasterConfig[layerId];
  if (!alwaysOn) enabledLayers.add(layerId);
  const layerToAdd = rasterLayers[layerId];

  // console.log(`%c${layerId} ON - ${order} - always on? ${alwaysOn}`, `color: ${testColors[order]}; font-weight: bold; font-family:monospace;`);
  // console.log('RASTER ON', `%c${order}`, 'font-weight: bold', `%c${layerId}`, `color: ${testColors[order]}; font-weight: bold; font-family:monospace;`);

  let insertIndex;
  let alreadyExists;
  overlayMapTypes.forEach((existingLayer, i) => {
    // if (existingLayer.name === layerId || existingLayer.alt.order === order) alreadyExists = true;
    if (existingLayer.name === layerId) alreadyExists = true;
    if (!insertIndex && order < existingLayer.alt.order) insertIndex = i;
  });
  // if layer is already present, exit function
  if (alreadyExists) {
    return;
  }

  if (insertIndex === undefined) {
    // if no insert index was found, add as the top-most layer
    overlayMapTypes.push(layerToAdd);
  } else {
    // otherwise insert at the index
    overlayMapTypes.insertAt(insertIndex, layerToAdd);
  }

  // const types = overlayMapTypes.getArray().map(({ alt, name }) => `${alt.order}: ${name}`).join('\n');
  // console.log(types, '\n\n');
};

export const removeTileLayer = (layerId, options = {}) => {
  const { preserveEnabledStatus, isBasemap } = options;
  // console.log('remove___', layerId);
  const { overlayMapTypes } = getMap();
  let index;
  overlayMapTypes.forEach((layer, i) => {
    // set index if it's a match
    if (layer.name === layerId && (!layer.alt.alwaysOn || isBasemap)) index = i;
  });
  // if match found, remove it
  if (index !== undefined) {
    // console.log(`%c${layerId} REMOVING - ${index}`, `color: ${testColors[index]}; font-weight: bold; font-family:monospace;`);
    // const selected = overlayMapTypes.getAt(index);
    // const { name, alt: { order } } = selected;
    // console.log(`%c${order} ${name} DELETE - ${index}`, `color: ${testColors[index]}; font-family:monospace;`);

    overlayMapTypes.removeAt(index);
    if (!preserveEnabledStatus) enabledLayers.delete(layerId);
  }

  // const types = overlayMapTypes.getArray().map(({ alt, name }) => `${alt.order}: ${name}`).join('\n');
  // console.log(types, '\n\n');
};

export const hideTileLayers = () => {
  Array.from(enabledLayers).forEach((layerId) => removeTileLayer(layerId, { preserveEnabledStatus: true }));
};

export const showTileLayers = () => {
  Array.from(enabledLayers).forEach((layerId) => addTileLayer(layerId));
};

let settingBasemap = false;

export const setBasemap = (layerId) => {
  if (!settingBasemap) {
    // console.log('\nset basemap', layerId, '\n');
    settingBasemap = true;
    const baseMaps = new Set(['planimetric_ortho_basemap', 'planimetric_basemap', 'planimetric_roads']);
    // get other potential basemaps
    baseMaps.delete(layerId);
    // remove any other basemaps from the map
    Array.from(baseMaps).forEach((layerId) => {
      // console.log('set basemap - remove', layerId);
      removeTileLayer(layerId, { isBasemap: true });
    });
    addTileLayer(layerId);
    settingBasemap = false;
  }
};

window.setAgolBasemapOpacity = (opacity) => {
  const basemaps = ['planimetric_ortho_basemap', 'planimetric_basemap', 'planimetric_roads'];
  for (const basemap of basemaps) {
    if (isTileLayerVisible(basemap)) {
      return rasterLayers[basemap].setOpacity(opacity);
    }
  }
};
