export const mainCampuses = { // approximations
  camden: {
    id: 1,
    title: 'Camden',
    value: 'camden',
    position: {
      lat: 39.9474302294818,
      lng: -75.12167062822732,
    },
    bounds: { west: -75.14957427978516, south: 39.92119090708097, east: -75.08451461791992, north: 39.972647446014925 },
    zoom: 16,
    mobileZoom: 15,
    abbr: 'CAM',
  },
  newark: {
    id: 2,
    title: 'Newark',
    value: 'newark',
    position: {
      lat: 40.73761581685023,
      lng: -74.17992107392593,
    },
    bounds: { west: -74.19891357421875, south: 40.73281984465465, east: -74.13196563720703, north: 40.77950154452172 },
    zoom: 14,
    mobileZoom: 13,
    abbr: 'NWK',
  },
  newBrunswick: {
    id: 3,
    title: 'New Brunswick',
    value: 'newBrunswick',
    position: {
      lat: 40.500753,
      lng: -74.447925,
    },
    // bounds: [{ lat: 40.46000934086916, lng: -74.50172424316406 }, { lat: 40.53832969648848, lng: -74.38602447509766 }],
    bounds: { west: -74.49726104736328, south: 40.463405118067236, east: -74.39495086669922, north: 40.535198637933945 },
    zoom: 13,
    mobileZoom: 12,
    abbr: 'NB',
  },
};

export const districts = {
  busch: {
    title: 'Busch',
    id: 'busch',
    campus: 'newBrunswick',
    campusId: 'NB',
    labelPosition: { lat: 40.525825, lng: -74.465742 },
    position: { lat: 40.523965604683035, lng: -74.46209419573974 },
    zoom: 15,
  },
  livingston: {
    title: 'Livingston',
    id: 'livingston',
    campus: 'newBrunswick',
    campusId: 'NB',
    labelPosition: { lat: 40.522746, lng: -74.438919 },
    position: { lat: 40.521767332360724, lng: -74.43625824865721 },
    zoom: 15,
  },
  collegeAve: {
    title: 'College Avenue',
    id: 'collegeAve',
    campus: 'newBrunswick',
    campusId: 'NB',
    labelPosition: { lat: 40.506291, lng: -74.447393 },
    position: { lat: 40.501518878103745, lng: -74.44983917462169 },
    zoom: 15,
  },
  cookDouglass: {
    title: 'Cook / Douglass',
    id: 'cookDouglass',
    campus: 'newBrunswick',
    campusId: 'NB',
    labelPosition: { lat: 40.479699, lng: -74.430356 },
    position: { lat: 40.480906778546604, lng: -74.4337892275391 },
    zoom: 15,
  },
  newark: {
    title: 'Rutgers University - Newark',
    labelText: 'Rutgers<br>University -<br>Newark',
    id: 'newark',
    campus: 'newark',
    campusId: 'NWK',
    labelPosition: { lat: 40.744, lng: -74.178 },
    position: { lat: 40.741302061366, lng: -74.17304183120677 },
    zoom: 16,
  },
  newarkRBHS: {
    title: 'RBHS at Newark',
    labelText: 'RBHS<br>at<br>Newark',
    id: 'newarkRBHS',
    campus: 'newark',
    campusId: 'NWK',
    labelPosition: { lat: 40.744, lng: -74.195 },
    position: { lat: 40.74133457677241, lng: -74.19005776519725 },
    zoom: 16,
  },
  camden: {
    id: 'camden',
    position: {
      lat: 39.9474302294818,
      lng: -75.12167062822732,
    },
    zoom: 16,
    title: 'Camden',
    labelPosition: {
      lat: 39.9474302294818,
      lng: -75.12167062822732,
    },
  },
};
