import { getMap } from '../map/mapRef';
import store from '../../store';
import { getUiState } from '../../slices/ui';
import loading from '../loading';
import { setInfoDrawerFeatures } from '../../slices/infoDrawer';
import utilityClickQuery from '../internalAgolData/utilityClickQuery';
import { closeDrawer } from '../../thunks/drawerClose';

async function clickQuery(event) {
  const loadingMsg = 'Searching for features';
  loading.start(loadingMsg);
  store.dispatch(closeDrawer());
  const { lat, lng } = event.latLng.toJSON();
  const zoom = this.getZoom();
  const { parcel, utilities } = getUiState(store.getState());
  const { All_networks, ...utilityNetworks } = utilities;

  const networks = [];
  if (All_networks) {
    networks.push(...Object.keys(utilityNetworks));
  } else {
    Object.entries(utilityNetworks).forEach(([NetworkName, toggled]) => {
      if (toggled) networks.push(NetworkName);
    });
  }

  try {
    // console.time('Click Query Duration:');
    const data = await utilityClickQuery({ zoom, lat, lng, parcel, networks });
    // console.timeEnd('Click Query Duration:');

    if (data.length) {
      store.dispatch(setInfoDrawerFeatures(data));
    } else {
      store.dispatch(closeDrawer());
    }
  } catch (e) {
    // TODO: notify user that something went wrong
    console.log('Error on click query', e);
  } finally {
    loading.end(loadingMsg);
  }
}

let clickBoxListener;

export const addClickBoxListener = () => {
  if (!clickBoxListener) {
    const { google } = window;
    const map = getMap();
    clickBoxListener = google.maps.event.addListener(map, 'click', clickQuery);
  }
};

export const removeClickBoxListener = () => {
  if (clickBoxListener) {
    const { google } = window;
    clickBoxListener = google.maps.event.removeListener(clickBoxListener);
  }
};
