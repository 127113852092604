import store from '../../store';
import { getMap } from '../../utils/map/mapRef';

import vectorStaticStyles from './vector-static-styles.json';

const getHighlightStyle = (category) => vectorStaticStyles.highlights[category];

const getStyle = (isOutlined, style, altStyle) => {
  if (isOutlined) return altStyle || { ...style, fillOpacity: 0 };
  return style;
};

const mainLayerStyle = (feature) => {
  const { ui } = store.getState();
  const { aerialImagery } = ui;
  const map = getMap();

  const isOultined = aerialImagery || map.getMapTypeId() === 'hybrid';
  // get highlight categories if applicable
  const categories = feature.getProperty('categories') || [];
  // loop through categories to determine if any of them are highlighted and style accordingly
  for (const cat of categories) {
    if (ui[cat]) {
      return getHighlightStyle(cat);
    }
  }
  const category = feature.getProperty('category');
  // if feature is a parking lot
  if (category === 'parking') {
    // if parking highlighting is turned on
    if (ui.parking) return getHighlightStyle('parking');
    return getStyle(isOultined, vectorStaticStyles.parking, vectorStaticStyles.outlines);
  }
  // otherwise return proper default building style
  return getStyle(isOultined, vectorStaticStyles.buildings, vectorStaticStyles.outlines);
};

const clickSelectionStyle = () => ({
  strokeColor: '#ff6ec7',
  // strokeColor: 'black',
  strokeWeight: 4,
  fillColor: 'black',
  fillOpacity: 0.2,
  icon: {
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 10,
    fillOpacity: 0.8,
    fillColor: '#ff6ec7',
    strokeWeight: 4,
    strokeColor: 'black',
  },
  zIndex: 1000,
  clickable: false,
});

export default {
  ...vectorStaticStyles,
  main: mainLayerStyle,
  clickSelection: clickSelectionStyle,
};
