import { createSlice } from '@reduxjs/toolkit';

const popularDestinationsSlice = createSlice({
  name: 'popularDestinations',
  initialState: {},
  reducers: {
    setPopularDestinations: (state, { payload }) => payload,
  },
});

export const { setPopularDestinations } = popularDestinationsSlice.actions;

export default popularDestinationsSlice.reducer;
