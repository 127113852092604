import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { nextSelection, prevSelection, selectInfoDrawerCurrentIndex, selectInfoDrawerFeatures } from '../../slices/infoDrawer';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '0px',
  },
  prevNextButton: { padding: '0 12px' },
};

const FeatureSelectButton = ({ onClick, direction, show }) => {
  if (!show) return <Box style={{ width: 48 }} />;
  const Icon = direction === 'previous' ? ArrowBackIcon : ArrowForwardIcon;
  return (
    <Tooltip title={`${direction} feature`}>
      <IconButton
        color="inherit"
        onClick={onClick}
        aria-label={`Select ${direction} feature`}
        sx={styles.prevNextButton}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
};

const MultiFeatureSelector = () => {
  const currentIndex = useSelector(selectInfoDrawerCurrentIndex);
  const features = useSelector(selectInfoDrawerFeatures);
  const dispatch = useDispatch();
  const handlePrevClick = () => dispatch(prevSelection());
  const handleNextClick = () => dispatch(nextSelection());

  if (features.length < 2) return '';
  return (
    <Toolbar sx={styles.root} variant="dense">{/* ! IMPORTANT: can only override minHeight when variant="dense" */}

      <FeatureSelectButton
        direction="previous"
        onClick={handlePrevClick}
        show={currentIndex}
      />
      <Typography variant="subtitle1" align="center">
        {currentIndex + 1} of {features.length}
      </Typography>
      <FeatureSelectButton
        direction="next"
        onClick={handleNextClick}
        show={currentIndex + 1 < features.length}
      />
    </Toolbar>
  );
};

export default MultiFeatureSelector;
