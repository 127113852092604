const calculator = (markers, numStyles) => {
  let index = 0;
  const count = markers.length;
  // TODO: find out what DV stands for and change variable name
  let dv = count;
  // TODO: explain what is happening here
  while (dv !== 0) {
    dv = parseInt(dv / 10, 10);
    index++;
  }

  // TODO: explain what is happening here
  index = Math.min(index, numStyles);

  /** // ? set marker based on label ranking system that takes into account...
   * popular destinations
   * building categories
   * course abbreviations
   * whether feature is a building or a parking lot
   *  */
  let chosenMarker = markers[0];
  for (const marker of markers) {
    if (marker.labelRank > chosenMarker.labelRank) chosenMarker = marker;
  }

  const { labelContent } = chosenMarker;

  return { text: labelContent, index };
};

export default calculator;
