import { useDispatch, useSelector } from 'react-redux';

import Section from '../Section';
import UtilityToggle from './UtilityToggle';

import { getUtilitiesOpen, getUtilitiesState, toggleUtility } from '../../../slices/ui';
import { openSnackbar } from '../../../slices/snackbar';

import { turnOffUtilities, turnOnUtilities } from '../../../utils/workflows/toggleUtilities';

import { utilityList } from '../../../config/layers/raster';

const UtilityList = () => {
  const dispatch = useDispatch();
  const utilitiesOpen = useSelector(getUtilitiesOpen);
  const utilities = useSelector(getUtilitiesState);

  const handleSectionToggle = () => {
    if (!utilitiesOpen) {
      dispatch(openSnackbar({ msg: 'Entering utilities mode' }));
      turnOnUtilities();
    } else {
      turnOffUtilities();
      dispatch(openSnackbar({ msg: 'Leaving utilities mode' }));
    }
  };

  const handleToggleUtility = (layerId) => () => dispatch(toggleUtility(layerId));

  return (
    <Section title="Utilities" handleToggle={handleSectionToggle} open={utilitiesOpen} value="utilitiesOpen">
      {utilityList.map(({ layerId, title }) => (
        <UtilityToggle
          key={layerId}
          layerId={layerId}
          title={title}
          toggled={utilities[layerId]}
          toggleUtility={handleToggleUtility(layerId)}
        />
      ))}
    </Section>
  );
};

export default UtilityList;
