import createLabelClusterer from '../clusters/labels/createLabelClusterer';
import createLabelContent from '../clusters/labels/createLabelContent';
import createOfcClusterer from '../clusters/offCampusFacilities/createOcfClusterer';

const clusterLayers = {};

export const getClusterer = (layerId) => clusterLayers[layerId];

function getClustererFunc(layerId) {
  switch (layerId) {
    case 'mainLabels':
      return createLabelClusterer;
    case 'ocf':
      return createOfcClusterer;
    default:
  }
}

export const addClusterLayer = async (layerId, data) => {
  if (clusterLayers[layerId]) return;
  const createClusterer = getClustererFunc(layerId);
  const clusterData = await createClusterer(data);
  if (!clusterData) return;
  clusterLayers[layerId] = clusterData;
};

export const toggleClusterLayer = async (layerId, toggled) => {
  // if (process.env.REACT_APP_LOCAL) console.log(`cluster: toggling ${toggled ? 'ON' : 'OFF'}: ${layerId}`);
  // if layer doesnt' exist
  if (!clusterLayers[layerId]) {
    // ignore layer unless it is for Off Campus Facilities
    if (!toggled || layerId !== 'ocf') return;
    await addClusterLayer('ocf');
    if (!clusterLayers[layerId]) return;
  }
  const { markers, clusterer } = clusterLayers[layerId];
  if (toggled) {
    return clusterer.addMarkers(markers);
  }
  return clusterer.clearMarkers();
};

export const reDrawClusterLayer = (layerId, addBUC) => {
  const layerToReDraw = clusterLayers[layerId];
  // fix issue with labels cluster layer not existing yet
  if (!layerToReDraw) return;
  const { markers } = layerToReDraw;
  markers.forEach((marker) => {
    if (marker.alt.underConstruction) {
      const { labelContent } = createLabelContent(marker.alt);
      marker.labelContent = labelContent;
      if (addBUC) {
        marker.labelRank += 20;
      } else {
        marker.labelRank -= 20;
      }
      marker.markerLabel.draw();
    }
  });
  layerToReDraw.clusterer.clearMarkers();
  layerToReDraw.clusterer.addMarkers(markers);
};
