import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { toggleBusRoute } from '../../../slices/busRoutes';
import { toggleBusRouteLayer } from '../../../utils/buses/busLayers';
import { selectCampus } from '../../../slices/view';

const getCheckboxStyle = (color) => ({
  '&.Mui-checked': {
    color,
  },
});

export const BusRouteToggle = ({ title, on, id, index, color }) => {
  const style = getCheckboxStyle(color);
  const dispatch = useDispatch();
  const campus = useSelector(selectCampus);

  const handleRouteToggle = ({ target }) => {
    const { checked } = target;
    dispatch(toggleBusRoute({ campus, index, toggled: checked }));
    toggleBusRouteLayer({ campus, id, toggled: checked });
  };

  return (
    <FormControlLabel
      control={(
        <Checkbox
          sx={style}
          value={title}
          name={title}
          checked={on}
          onChange={handleRouteToggle}
          inputProps={{
            label: `Toggle ${title}`,
            'aria-label': `Toggle ${title}`,
          }}
        />
      )}
      label={title}
    />
  );
};
