import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UtilityDrawerField from './UtilityDrawerField';

const styles = {
  root: {
    padding: '20px',
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  additional: {
    textAlign: 'center',
    fontVariant: 'all-petite-caps',
    fontWeight: 'normal',
  },
};

const UtilityDrawer = ({ details: { main, additional } }) => (
  <Box sx={styles.root}>
    <Box id="main-details" sx={styles.section}>
      {main.map(({ label, value }) => (
        <UtilityDrawerField key={label} label={label} value={value} />
      ))}
    </Box>
    <Typography variant="h6" sx={styles.additional}>Additional Details</Typography>
    <hr />
    <Box id="additional-details" sx={styles.section}>
      {additional.map(({ label, value }) => (
        <UtilityDrawerField key={label} label={label} value={value} />
      ))}
    </Box>
  </Box>
);

export default UtilityDrawer;
