function getCookies() {
  const pairs = document.cookie.split(';');
  const cookies = {};
  pairs.forEach((raw) => {
    // if no cookies, user is not authenticated
    if (!raw) return {};
    const [rawKey, value] = raw.split('=');
    const key = String(rawKey).trim();

    cookies[key] = window.unescape(value);
  });

  return cookies;
}

export default function isAuthenticated() {
  const cookies = getCookies();
  return process.env.REACT_APP_LOCAL || cookies['rutgers.map'];
}
