import { utilityList } from '../../config/layers/raster';
import { getUiState } from '../../slices/ui';
import store from '../../store';
import { closeDrawer } from '../../thunks/drawerClose';
import { toggleClusterLayer } from '../layers/clusterLayers';
import { addTileLayer, removeTileLayer } from '../layers/rasterLayers';
import { toggleVectorLayer } from '../layers/vectorLayers';
import { addClickBoxListener, removeClickBoxListener } from '../listeners/utilityClickBoxListener';

import loading from '../loading';

export const turnOnUtilities = () => {
  const loadingMsg = 'transitioning back from utility mode';
  loading.start(loadingMsg);

  store.dispatch(closeDrawer());

  // get UI state
  const uiState = getUiState(store.getState());
  const { aerialImagery, satellite, parcel, utilities } = uiState;

  // toggle off main building/parking layer
  toggleVectorLayer('main', false);
  // toggle off building/parking labels
  toggleClusterLayer('mainLabels', false);

  // add click selection layer
  toggleVectorLayer('clickSelection', true);

  // toggle on building/parking tile layer based on whether aerial imagery or satellite is on
  if (aerialImagery || satellite) {
    addTileLayer('buildingsParkingOutlines');
  } else {
    addTileLayer('buildingsParking');
  }

  // toggle on/off parcel layers (if toggled)
  if (parcel) {
    toggleVectorLayer('parcel');
    addTileLayer('parcel');
  }

  // add any toggled utility layers
  Object.entries(utilities).forEach(([utility, toggled]) => {
    if (toggled) addTileLayer(utility);
  });

  // add map click listener
  addClickBoxListener();

  loading.end(loadingMsg);
};

export const turnOffUtilities = () => {
  const loadingMsg = 'transitioning to utility mode';
  loading.start(loadingMsg);

  store.dispatch(closeDrawer());
  // get UI state
  const uiState = getUiState(store.getState());
  const { aerialImagery, satellite, parcel } = uiState;

  // toggle on building/parking tile layer based on whether aerial imagery or satellite is on
  if (aerialImagery || satellite) {
    removeTileLayer('buildingsParkingOutlines');
  } else {
    removeTileLayer('buildingsParking');
  }

  // toggle off vector layers
  toggleVectorLayer('main', true);
  // toggle on building/parking labels
  toggleClusterLayer('mainLabels', true);
  // toggle on/off parcel layers (if toggled)
  if (parcel) {
    toggleVectorLayer('parcel', true);
    removeTileLayer('parcel');
  }
  // toggle off any toggled on utilities
  utilityList.forEach(({ layerId }) => removeTileLayer(layerId));

  // remove click selection layer
  toggleVectorLayer('clickSelection');

  // remove map click listener
  removeClickBoxListener();

  loading.end(loadingMsg);
};
