import loading from '../loading';
import { addTileLayer, createTileLayers } from '../layers/rasterLayers';
import { initializeMap } from '../map/setup/mapSetup';
import { setStateFromUrl } from './setStateFromUrl';
import addCampusMarkers from '../map/setup/addCampusMarkers';
import addDistrictMarkers from '../map/setup/addDistrictMarkers';
import boundsChangeListener from '../listeners/boundsChangeListener';
import debounce from '../debounce';
import disableFeaturesOutsideofBounds from '../clusters/disableOutOfBoundsFeatures';
import isAuthenticated from '../isAuthenticated';
import getAgolToken from '../internalAgolData/agolToken';
import { getSyncIdAsync } from '../getSyncId';

// app setup: load data, load Google Maps, update app state based on URL
export default async function onAppLoad() {
  loading.start('Loading necessary libraries & data');
  loading.end('Loading Application');
  // load in everything necessary for the app at startup
  try {
    // load Google Maps API, initialize map, set default map listeners (bounds change / cluster clicks)
    // get sync ID from server (to access resources in GCS sync folder)
    const startupTasks = [initializeMap(), getSyncIdAsync()];
    // get initial limited access AGOL token so that ImageMapType urls will work
    if (isAuthenticated()) startupTasks.push(getAgolToken());

    const [map] = await Promise.all(startupTasks);

    // create ImageMapType objects for each tile layer
    createTileLayers();
    // load state boundary raster layer
    addTileLayer('nj_state_boundary');
    // add icons/labels for 3 main campuses
    addCampusMarkers();
    // add label markers for 7 main campus districts (including camden)
    addDistrictMarkers();

    // trigger layers showing/hiding zooming etc toggles based on URL
    await setStateFromUrl();

    // add bounds change listener to map
    // ? note: it must happen after state has been set to prevent triggering things twice
    await boundsChangeListener(map);

    window.google.maps.event.trigger(map, 'bounds_changed');

    // add listener to hide cluster/marker labels outside of viewport extent after clusters draw
    map.addListener('clusters_drawn', debounce(disableFeaturesOutsideofBounds, 100));
  } catch (e) {
    console.log('Uh oh, app did not load properly', e);
  } finally {
    loading.end('Loading necessary libraries & data');
  }
}
