import Button from '@mui/material/Button';
import selectFeature from '../../../../utils/selectFeature';

const styles = {
  button: {
    color: 'black',
    width: '100%',
    borderRadius: '0px',
    display: 'inline',
    borderBottom: '0.1px solid #cc0033',
  },
  address: {
    fontSize: '.8em',
    color: '#911E3B',
  },
  abbr: {
    color: '#911E3B',
    float: 'right',
    lineHeight: '2em',
    borderLeft: '1px solid #911E3B',
    fontWeight: 'bold',
    paddingLeft: '5px',
    width: '16%',
  },
};

const FeatureResult = ({ result, resetSearch }) => {
  const handleClick = () => {
    resetSearch();
    const { id, category } = result;
    selectFeature({ id, category });
  };

  return (
    <Button
      sx={styles.button}
      size="small"
      data-id={result.id}
      onClick={handleClick}
      aria-label={`Zoom to ${result.name}${result.abbr ? ` (${result.abbr})` : ''} at ${result.address}`}
    >
      <span style={styles.abbr}>{result.abbr}</span>
      <span>{result.name}</span>
      <br />
      <span style={styles.address}>{result.address}</span>
    </Button>
  );
};

export default FeatureResult;
