// necessary since this icon was discontinued in later versions of material-ui icons
import SvgIcon from '@mui/material/SvgIcon';

const DownloadIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
  </SvgIcon>
);

export default DownloadIcon;
