import selectFeature, { getFeatureDetails } from '../selectFeature';
import config from '../../config';
import { getSyncId } from '../getSyncId';

// make requests to see if a feature exists with given id
export default async function selectFeatureOnLoad(id, parcelsEnabled) {
  let match;
  try {
    const requests = [];

    const baseUrl = `${config.syncDataBucket}/${getSyncId()}`;

    // see if building w/ ID exists
    const buildingRequest = fetch(`${baseUrl}/buildings/details/${id}.json`)
      .then((resp) => resp.json())
      .catch(() => null); // fail silently on purpose
    requests.push(buildingRequest);

    // see if parking lot w/ ID exists
    const parkingRequest = fetch(`${baseUrl}/parking/details/${id}.json`)
      .then((resp) => resp.json())
      .catch(() => null); // fail silently on purpose
    requests.push(parkingRequest);

    if (parcelsEnabled) {
      console.log('parcelsEnabled', parcelsEnabled);
      requests.push(getFeatureDetails({ id, category: 'parcel' }));
    }

    const responses = await Promise.all(requests);
    // console.log('responses', responses);

    for (const data of responses) {
      if (data) match = data;
    }
  } catch (e) {
    console.log('ERROR: failed to determine category from ID', id, e);
  }

  if (match) {
    await selectFeature({ data: match, onLoad: true });
  } else {
    // TODO: alert user that a match was not found?
  }
}
