import { useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import { VisuallyHidden } from '@react-aria/visually-hidden';

import Box from '@mui/material/Box';
import { mainCampuses } from '../../config/locations';

import { isMobile } from '../../utils/browserAndDeviceTests';
import { toggleSidebar } from '../../slices/ui';
import { getMap } from '../../utils/map/mapRef';

const inputProps = {
  select: {
    label: 'Select Rutgers Location',
    'aria-label': 'Select Rutgers Location',
    role: 'listbox',
  },
};

const styles = {
  root: {
    padding: 1,
  },
};

// create array of 3 main campuses + NJ state
const locations = [{ title: 'New Jersey', value: 'nj' }, ...Object.values(mainCampuses)];

const JumpToLocation = () => {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { value } = event.target;
    const map = getMap();
    const onMobile = isMobile();
    // let bounds;
    if (value === 'nj') {
      map.fitBounds({ south: 38.928514, west: -75.563586, north: 41.357423, east: -73.893979 });
    } else {
      // bounds = mainCampuses[value].bounds;
      const { position, zoom, mobileZoom } = mainCampuses[value];
      map.setOptions({ center: position, zoom: onMobile ? mobileZoom : zoom });
    }
    // map.fitBounds(bounds);
    if (isMobile()) dispatch(toggleSidebar(false));
  };

  return (
    <Box sx={styles.root}>
      <VisuallyHidden>
        <h2 aria-label="Zoom to New Jersey, Camden, New Brunswick, or Newark locations">Jump To Location</h2>
      </VisuallyHidden>
      <Select
        native
        value="Select Location"
        onChange={handleChange}
        inputProps={inputProps.select}
        fullWidth
        variant="standard"
      >
        <option value="Select Location">Jump to Location</option>
        {locations.map(
          ({ value, title }) => (
            <option key={value} value={value} aria-label={title}>{title}</option>
          ),
        )}
      </Select>
    </Box>
  );
};

export default JumpToLocation;
