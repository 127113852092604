export const Red = {
  50: '#cc0033',
  100: '#cc0033',
  200: '#cc0033',
  300: '#cc0033',
  400: '#cc0033',
  500: '#cc0033',
  600: '#cc0033',
  700: '#cc0033',
  800: '#cc0033',
  900: '#cc0033',
  A100: '#cc0033',
  A200: '#cc0033',
  A400: '#cc0033',
  A700: '#cc0033',
  contrastDefaultColor: 'light',
};

export const LightGrey = {
  50: '#e0e0e0',
  100: '#e0e0e0',
  200: '#e0e0e0',
  300: '#e0e0e0',
  400: '#e0e0e0',
  500: '#e0e0e0',
  600: '#e0e0e0',
  700: '#e0e0e0',
  800: '#e0e0e0',
  900: '#e0e0e0',
  A100: '#e0e0e0',
  A200: '#e0e0e0',
  A400: '#e0e0e0',
  A700: '#e0e0e0',
  contrastDefaultColor: 'light',
};

export const DarkGrey = '#5f6a72';
