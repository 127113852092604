import bbox from '@turf/bbox';
import { getMap } from '../map/mapRef';

import vectorLayersConfig from '../../config/layers/vector';
import config from '../../config';
import featureClick from '../listeners/featureClick';
import { addClusterLayer } from './clusterLayers';
import addDistrictPolyListeners from '../listeners/districtsPolyListeners';
import vectorStyles from '../../config/layers/vectorStyles';
import { fetchParcelsVector } from '../internalAgolData/fetchParcels';

const vectorLayers = {};

// used to track whether a layer is already in the process of loading
const loading = {};

export const createVectorLayer = async (layerId) => {
  // if (process.env.REACT_APP_LOCAL) console.log('Vector layer CREATE:', layerId);
  const { google } = window;
  // create data layer
  const layer = new google.maps.Data();

  // add layer to layers storage object
  vectorLayers[layerId] = layer;

  // get layer config options
  const layerOptions = vectorLayersConfig[layerId];
  if (!layerOptions) return layer;

  const { getUrl, style, idPropertyName } = layerOptions;
  let data;

  // set styles for layer
  if (style) layer.setStyle(style);
  // load parcels
  if (layerId === 'parcel') {
    data = await fetchParcelsVector();
  } else if (getUrl) { // load data if it needs to be
    try {
      data = await fetch(getUrl()).then((x) => x.json());
    } catch (e) {
      // TODO: alert user that something went wrong
      // return displaySnackbarAlert({ type: 'alert', msg: `Error loading ${} data` })
      console.log('Failed to fetch vector data for', layerId, e);
      return;
    }
  }

  if (data) {
    layer.addGeoJson(data, { idPropertyName });
    if (layerId === 'main') {
      layer.addListener('click', featureClick);
      await addClusterLayer('mainLabels', data);
    } else if (layerId === 'districts') {
      addDistrictPolyListeners(layer);
    } else if (layerId === 'parcel') {
      layer.addListener('click', featureClick);
    }
  }

  return layer;
};

export const getVectorLayer = (layerId) => vectorLayers[layerId];

export const toggleVectorLayer = async (layerId, toggled) => {
  // if (process.env.REACT_APP_LOCAL) console.log('Vector layer TOGGLE:', toggled ? 'ON' : 'OFF', layerId);

  let layer = vectorLayers[layerId];

  // if toggling layer off
  if (!toggled) {
    // if layer wasn't created yet, exit function
    if (!layer) return;
    // otherwise, remove it from the map
    return layer.setMap(null);
  }
  // if toggling on and layer doesn't exist yet, create it
  if (!layer) {
    // if layer isn't loading, assign its loading promise
    if (!loading[layerId]) loading[layerId] = createVectorLayer(layerId);
    // wait for it to load
    layer = await loading[layerId];
  }
  // if there was an error loading the layer, exit function
  if (!layer) return;
  // add layer to the map
  layer.setMap(getMap());
  return layer;
};

export const restyleVectorLayer = (layerId) => {
  const layer = getVectorLayer(layerId);
  if (layer) {
    const { style } = vectorLayersConfig[layerId];
    layer.setStyle(style);
  }
};

// ? may need to make this an async function to ensure everything runs in the correct order

export const showVectorLayers = (viewLevel, uiState) => {
  const { utilitiesOpen } = uiState;
  config.vectorLayers.forEach((layer) => {
    const { layerId, level, onWithUtilities, toggleable } = layer;
    // if layer's level matches the zoom level
    if (viewLevel === level) {
      // if not in utilites mode OR we are but this layer is enabled when utilities are on
      if (!utilitiesOpen || (utilitiesOpen && onWithUtilities)) {
        // if not a toggleable layer OR it is toggleable and it is currently toggled
        if (!toggleable || (toggleable && uiState[layerId])) {
          toggleVectorLayer(layerId, true);
        }
      }
    }
  });
};

export const hideVectorLayers = (viewLevel, uiState) => {
  const { utilitiesOpen } = uiState;
  config.vectorLayers.forEach((layer) => {
    const { layerId, level, onWithUtilities, toggleable } = layer;
    // if layer's level matches the zoom level
    if (viewLevel === level) {
      // if not in utilites mode OR we are but this layer is enabled when utilities are on
      if (!utilitiesOpen || (utilitiesOpen && onWithUtilities)) {
        // if not a toggleable layer OR it is toggleable and it is currently toggled
        if (!toggleable || (toggleable && uiState[layerId])) {
          toggleVectorLayer(layerId);
        }
      }
    }
  });
};

export const setSelectedFeatureOnMap = async (geojson) => {
  const map = getMap();
  let layer = getVectorLayer('clickSelection');
  if (!layer) layer = await createVectorLayer('clickSelection');
  toggleVectorLayer('clickSelection', true);
  // remove all existing features
  layer.forEach((feature) => layer.remove(feature));
  if (geojson) {
    // add selected feature geometry
    layer.addGeoJson(geojson);
    // get bounds box for feature
    const [west, south, east, north] = bbox(geojson);
    // zoom to feature
    map.fitBounds({ west, south, east, north }, 20);
  }
};

export const styleSelectedFeature = async (id, category) => {
  if (['building', 'parking'].includes(category)) {
    category = 'main';
  }
  let layer = getVectorLayer(category);

  // if layer doesn't exist yet, toggle it  and wait for it to load
  if (!layer) layer = await toggleVectorLayer(category, true);

  if (layer) {
    const feature = layer.getFeatureById(id);
    if (feature) {
      // set feature to "selected" style
      layer.overrideStyle(feature, vectorStyles.featureSelection);
    }
  }
};

export const revertFeatureStyle = (id, category) => {
  if (['building', 'parking'].includes(category)) {
    category = 'main';
  }
  const layer = getVectorLayer(category);
  if (layer) {
    const feature = layer.getFeatureById(id);
    if (feature) {
      layer.revertStyle(feature);
    }
  }
};
