import { createAsyncThunk } from '@reduxjs/toolkit';
import { closeModal } from '../slices/modal';
import { openSnackbar } from '../slices/snackbar';
import loading from '../utils/loading';

export const acknowledgeDisclaimer = createAsyncThunk(
  'disclaimer/acknowledge',
  async ({ content }, thunkAPI) => {
    const loadingMsg = 'acknowledging disclaimer';
    loading.start(loadingMsg);
    try {
      const resp = await fetch('/api/disclaimer', {
        method: 'post',
        body: JSON.stringify({ content }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      if (!resp.ok) throw new Error(`Disclaimer acknowledge failed with status ${resp.status}`);
      thunkAPI.dispatch(closeModal());
    } catch (e) {
      console.log('ERROR: unable to acknowledge disclaimer', e);
      thunkAPI.dispatch(openSnackbar({ msg: 'Sorry, we were unable to confirm your acknowledgement. Please refresh the page and try again.' }));
    } finally {
      loading.end(loadingMsg);
    }
  },
);
