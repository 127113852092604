import { createSlice } from '@reduxjs/toolkit';
import config from '../config';
import { getUrlState } from '../utils/appSetup/setStateFromUrl';

const { initial } = config.mapSetup;

const { lat, lng, zoom } = getUrlState();

const initialState = {
  campus: null,
  level: null,
  lat: Number(lat) || initial.position.lat,
  lng: Number(lng) || initial.position.lng,
  zoom: Number(zoom) || initial.zoom,
};

export const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    setView: (state, { payload: { level, zoom, lat, lng, campus } }) => {
      state.campus = campus;
      state.level = level;
      state.zoom = Number(zoom);
      state.lat = Number(lat);
      state.lng = Number(lng);
    },
    setViewLevel: (state, { payload: level }) => {
      state.level = level;
    },
    setCampus: (state, { payload: campus }) => {
      state.campus = campus;
    },
  },
});

export const { setView, setViewLevel, setCampus } = viewSlice.actions;

export const selectView = (state) => state.view;
export const selectCampus = (state) => state.view.campus;
export const selectViewLevel = (state) => state.view.level;

export default viewSlice.reducer;
