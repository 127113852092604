import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const styles = {
  heading: {
    fontSize: '1.4em',
    fontWeight: 400,
    marginTop: '0.5em !important',
    color: '#cc0033',
    marginLeft: '3vw',
    marginBottom: '5px',
  },
  departments: {
    background: 'white',
  },
  deptsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 2vw',
    alignItems: 'center',
  },
  department: {
    fontFamily: 'Arial,sans-serif',
    color: '#5f6a72',
    width: '23%',
    margin: '5px',
    fontVariant: 'petite-caps',
    fontSize: '16px',
    fontWeight: 'bold',
  },
};

const DepartmentsSection = ({ departments }) => (
  <Grid item xs={12} sx={styles.departments}>
    <Box>
      <h3 style={styles.heading}>Space Assignments</h3>
      <Box sx={styles.deptsWrapper}>
        {departments
          .filter((dp) => dp)
          .map((dept) => <Typography key={dept} sx={styles.department}>{dept}</Typography>)}
      </Box>
    </Box>
  </Grid>
);

export default DepartmentsSection;
