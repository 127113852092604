import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import useMobileCheck from '../../hooks/useMobileCheck';
// import MapPageMobile from '../layout/mobile/MenuPageMobile';

const RedirectOnLoad = () => {
  // const isMobile = useMobileCheck();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/map');
  }, []);

  return '';
};

export default RedirectOnLoad;
