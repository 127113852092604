import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import config from '../../../config';

const { buildingImagesBucket } = config;

const getStyles = ({ show }) => ({
  mainImg: {
    width: '100%',
    objectFit: 'cover',
    height: show ? '200px' : '0px',
  },
});

const Image = ({ type, staticMapUrl, id, name, handleImageError }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    try {
      fetch(`${buildingImagesBucket}/${id}/00.jpg`, {
        method: 'HEAD',
      }).then((resp) => {
        if (resp && resp.status && resp.status === 200) setShow(true);
        else {
          setShow(false);
          handleImageError();
        }
      });
    } catch (e) {
      setShow(false);
      handleImageError();
    }
  }, [staticMapUrl, type, id, handleImageError]);

  const styles = getStyles({ show });

  if (!show) return '';

  const handleImgError = () => setShow(false);

  if (show) {
    switch (type) {
      case 'building':
        return (
          <img
            src={`${buildingImagesBucket}/${id}/00.jpg`}
            aria-label={name}
            onError={handleImgError}
            alt={name}
            style={styles.mainImg}
          />
        );
      case 'staticMap':
        return (
          <img
            src={staticMapUrl}
            aria-label="Mini Map"
            alt="Mini Map"
          />
        );
      default:
        return <Box />;
    }
  }
};

export default Image;
