import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { selectDisclaimerContent } from '../../slices/disclaimer';
import { acknowledgeDisclaimer } from '../../thunks/acknowledgeDisclaimer';

const DisclaimerModal = ({ onClose, forceConfirm }) => {
  const content = useSelector(selectDisclaimerContent);
  const dispatch = useDispatch();

  if (!content) return '';

  const handleAcknowledge = () => dispatch(acknowledgeDisclaimer(content));

  const buttonText = forceConfirm ? 'acknowledge' : 'close';
  const handleClick = forceConfirm ? handleAcknowledge : onClose;
  return (
    <>
      <DialogTitle>Disclaimer</DialogTitle>
      <DialogContent>
        <DialogContentText dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={handleClick} color="primary">
          {buttonText}
        </Button>
      </DialogActions>
    </>
  );
};

export default DisclaimerModal;
