import defaultConfig from './default';
import prodConfig from './production';

function config() {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return { ...defaultConfig, ...prodConfig };
    default:
      return defaultConfig;
  }
}

export default config();
