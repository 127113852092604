import qs from 'query-string';
import { openSnackbar } from '../../slices/snackbar';
import store from '../../store';

export default async function queryFeaturesByExtent({ lat, lng, zoom, networks, parcel }) {
  try {
    const queryString = qs.stringify({ lat, lng, zoom, networks });
    let url = `/api/identify?${queryString}`;
    if (parcel) url += '&parcel=true';

    const resp = await fetch(url);

    if (resp.status !== 200) {
      throw new Error(resp.status);
    }

    const features = await resp.json();

    return features;
  } catch (e) {
    console.log('Error querying features via click query', e);
    store.dispatch(openSnackbar({ msg: 'Error querying features at user click location. Please try again.' }));
  }
}
