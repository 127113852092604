import { openSnackbar } from '../../slices/snackbar';
import store from '../../store';

export const fetchParcelsVector = async () => {
  try {
    const { url } = await fetch('/api/parcels').then((resp) => resp.json());
    const data = await fetch(url).then((resp) => resp.json());
    return data;
  } catch (e) {
    console.log('Error fetching parcel vector data', e);
    store.dispatch(openSnackbar({ msg: 'Error fetching parcel vector data' }));
  }
};

export const fetchParcelDetails = async (id) => {
  try {
    const parcel = await fetch(`/api/parcels/${id}`).then((resp) => resp.json());
    // if no feature found, abort
    if (!parcel) return;

    return parcel;
  } catch (e) {
    console.log('Error fetching parcel feature w/ id of ', id, e);
    store.dispatch(openSnackbar({ msg: 'Error fetching parcel details' }));
  }
};
