import store from '../../store';
import { getMap } from '../map/mapRef';
import { getClusterer } from '../layers/clusterLayers';
import getViewLevel from '../boundsChange/getViewLevel';

// TODO: be aware of potential performance issues due to this
export default function disableFeaturesOutsideofBounds() {
  const map = getMap();
  const bounds = map.getBounds();
  const zoom = map.getZoom();
  const currentLevel = getViewLevel(zoom);
  const ocfToggled = store.getState().ui.ocf;

  // if map has finished loading
  if (bounds) {
    /* ---------------------- Building Labels ---------------------- */
    if (currentLevel === 'features') {
      const mainLabelClusterer = getClusterer('mainLabels');
      if (mainLabelClusterer) {
        const { clusterer } = mainLabelClusterer;
        const markers = clusterer.getMarkers();
        markers.forEach((marker) => {
          // if marker is out of bounds
          if (!bounds.contains(marker.getPosition())) {
            // remove marker from the map
            marker.setVisible(false);
          } else {
            // otherwise, show marker on map
            marker.setVisible(true);
          }
        });
      }
    } else if (ocfToggled) {
      /* ---------------------- Off Campus Facilities ---------------------- */
      const ocfClusterer = getClusterer('ocf');
      if (ocfClusterer) {
        const { clusterer } = ocfClusterer;
        const markers = clusterer.getMarkers();
        markers.forEach((marker) => {
          // if marker is out of bounds
          if (!bounds.contains(marker.getPosition())) {
            // remove marker from the map
            marker.setVisible(false);
          } else {
            // otherwise, show marker on map
            marker.setVisible(true);
          }
        });
        if (zoom < 14) {
          setTimeout(() => {
            const clusters = Array.from(document.getElementsByClassName('OCF-marker-label')).map((el) => el.firstElementChild);
            const markers = Array.from(document.getElementsByClassName('cluster-markers-label'));
            const els = [...clusters, ...markers];
            for (const el of els) {
              const id = el.getAttribute('data-id');
              // hide/show labels but keep main campus cluster marker labels hidden
              if (zoom > 8) {
                if (!['NWK', 'CAM', 'NB'].includes(id)) el.classList.add('displayBlock');
              } else if (!['NWK', 'CAM', 'NB'].includes(id)) el.classList.remove('displayBlock');
            }
          }, 100);
        }
      }
    }
  }
}
