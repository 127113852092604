import { useState } from 'react';

// Material UI Components
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Collapse from '@mui/material/Collapse';

import Show from '../Show';
import MobileMenu from './MobileMenu';
import HeaderMenuItems from './HeaderMenuItems';

// images
import logo from '../../assets/RU_LINE_WHITE.png';

const styles = {
  root: {
    width: '100vw',
    height: '100%',
    minWidth: '310px',
    display: 'flex',
    backgroundColor: '#cc0033',
    color: 'white',
    justifyContent: { xs: 'flex-start', sm: 'space-between' },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: { xs: 1, sm: 2 },
    flexGrow: { xs: 1, sm: 0 },
  },
  logoLink: {
    lineHeight: '10px',
  },
  title: {
    marginLeft: 2,
  },
  menuIcon: {
    color: 'white',
  },
  mobileMenuContainer: {
    position: 'absolute',
    display: { xs: 'block', sm: 'none' },
  },
};

const Header = () => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuOpen, setMobileMenuState] = useState(false);

  const handleMobileMenuClick = () => setMobileMenuState(!mobileMenuOpen);
  const mobileMenuLabel = `${mobileMenuOpen ? 'Close' : 'Open'} Main Menu`;

  return (
    <Box id="header">
      <Box sx={styles.root}>
        <Show test={isXS}>
          <Tooltip title={mobileMenuLabel}>
            <IconButton
              aria-label={mobileMenuLabel}
              onClick={handleMobileMenuClick}
            >
              <MenuIcon sx={styles.menuIcon} />
            </IconButton>
          </Tooltip>
        </Show>
        <Box sx={styles.logoWrapper}>
          <a href="/" style={styles.logoLink}><img src={logo} style={styles.logo} width={isXS ? '150px' : '275px'} alt="Rutgers Logo" /></a>
          <Typography component="h1" sx={styles.title} aria-label="Rutgers Campus Map">Campus Map</Typography>
        </Box>
        <Show test={!isXS}>
          <HeaderMenuItems />
        </Show>
      </Box>
      <Collapse in={isXS && mobileMenuOpen} sx={styles.mobileMenuContainer}>
        <MobileMenu onClick={handleMobileMenuClick} handleClose={handleMobileMenuClick} />
      </Collapse>
    </Box>
  );
};

export default Header;
