/* eslint-disable react/no-multi-comp */

import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';

import FeedbackModal from './FeedbackModal';
import DownloadMapsModal from './DownloadMapsModal';
import UtilitiesLegendModal from './UtilitiesLegendModal';
import DisclaimerModal from './DisclaimerModal';
import UtilitiesSearchModal from './UtilitiesSearchModal';

import { closeModal, selectModalCategory, selectModalOpen } from '../../slices/modal';

function getContent(category) {
  switch (category) {
    case 'FEEDBACK':
      return FeedbackModal;
    case 'DISCLAIMER':
      return DisclaimerModal;
    case 'DOWNLOAD_MAPS':
      return DownloadMapsModal;
    case 'UTILITY_LEGEND':
      return UtilitiesLegendModal;
    case 'UTILITIES_SEARCH':
      return UtilitiesSearchModal;
    default:
      return '';
  }
}

const styles = {
  paper: {
    overflowY: 'visible',
  },
};

const ModalRouter = ({ fullWidth, forceConfirm }) => {
  const dispatch = useDispatch();
  const category = useSelector(selectModalCategory);
  const open = useSelector(selectModalOpen);

  if (!category) return '';

  const handleClose = (e, reason) => {
    // don't allow backdrop click or escape key to close modal if forceConfirm is true
    if (forceConfirm && reason) return;
    dispatch(closeModal());
  };

  const ModalContent = getContent(category);

  return (
    <Dialog
      maxWidth={false}
      open={open}
      fullWidth={fullWidth}
      onClose={handleClose}
      sx={styles.paper}
    >
      <ModalContent
        onClose={handleClose}
        // forceConfirm field is optional
        forceConfirm={forceConfirm}
      />
    </Dialog>
  );
};

export default ModalRouter;
