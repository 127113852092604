import bbox from '@turf/bbox';
import config from '../config';
import { districts } from '../config/locations';
import { setInfoDrawerFeatures } from '../slices/infoDrawer';
import { setSearchResults, setQuery } from '../slices/search';
import { openSnackbar } from '../slices/snackbar';
import { toggleSection, toggleUtility } from '../slices/ui';
import store from '../store';
import { getSyncId } from './getSyncId';
import { fetchParcelDetails } from './internalAgolData/fetchParcels';
import fetchUtilityDetails from './internalAgolData/fetchUtilityDetails';
import { addTileLayer } from './layers/rasterLayers';
import { revertFeatureStyle, styleSelectedFeature } from './layers/vectorLayers';
import loading from './loading';
import { getMap } from './map/mapRef';
import { turnOnUtilities } from './workflows/toggleUtilities';
import { closeDrawer } from '../thunks/drawerClose';

export const getFeatureDetails = async ({ id, category }) => {
  const baseUrl = `${config.syncDataBucket}/${getSyncId()}`;
  switch (category) {
    case 'building':
      return fetch(`${baseUrl}/buildings/details/${id}.json`).then((resp) => resp.json());
    case 'parking':
      return fetch(`${baseUrl}/parking/details/${id}.json`).then((resp) => resp.json());
    case 'utility':
      return fetchUtilityDetails(id);
    case 'parcel':
      return fetchParcelDetails(id);
    default:
  }
};

const selectFeature = async ({ id, category, campus, district, data, onLoad }) => {
  const loadingMsg = 'Fetching feature details';
  loading.start(loadingMsg);

  const map = getMap();

  const { infoDrawer, ui: { utilitiesOpen } } = store.getState();
  const { features, currentIndex } = infoDrawer;
  const existingSelection = features[currentIndex];

  store.dispatch(setQuery(''));
  store.dispatch(setSearchResults([]));

  if (district) {
    const { position, zoom } = districts[district];
    map.setCenter(position);
    map.setZoom(zoom);
    return loading.end(loadingMsg);
  }

  // if feature is a main campus or an Off Campus Facility
  if (campus) {
    store.dispatch(closeDrawer());
    // get bounding box of the boundary of the campus or off campus faciility
    const url = `${config.syncDataBucket}/${getSyncId()}/boundaries/details/${campus}.json`;
    const { bounds } = await fetch(url).then((x) => x.json());
    map.setZoom(18);
    map.fitBounds(bounds);
    return loading.end(loadingMsg);
  }

  if (!data) {
    data = await getFeatureDetails({ id, category });
  }

  // TODO: alert user that something went wrong
  // abort if no data
  if (!data) return loading.end(loadingMsg);

  // get feature bounds
  const [west, south, east, north] = bbox(data.geometry);

  // zoom to feature
  if (onLoad) {
    window.google.maps.event.addListenerOnce(map, 'idle', () => {
      // console.log('%cdelayed fit bounds on load', 'color:purple;');
      map.fitBounds({ west, south, east, north }, 20);
    });
  } else {
    map.fitBounds({ west, south, east, north }, 20);
  }

  // wait for map to settle down, then open info drawer
  window.google.maps.event.addListenerOnce(map, 'idle', () => store.dispatch(setInfoDrawerFeatures([data])));

  // if selecting a utility and not in utilities mode, trigger it
  if (category === 'utility') {
    const { ui } = store.getState();
    const { utilitiesOpen, utilities } = ui;
    if (!utilitiesOpen) {
      store.dispatch(toggleSection('utilitiesOpen'));
      openSnackbar({ msg: 'Entering utilities mode' });
      turnOnUtilities();
    }

    const { network_name } = data;
    // if selecting a utility and utility's layer isn't toggled on, toggle it on
    const networkVisible = utilities[network_name];
    if (!networkVisible) {
      store.dispatch(toggleUtility(network_name));
      addTileLayer(network_name);
    }
  }

  // make sure to reset style of any previously selected features
  if (existingSelection) {
    const { id, category } = existingSelection;
    // console.log('%creverting style', 'color: blue', id, category);
    revertFeatureStyle(id, category);
  }

  // set style of feature to "selected" style
  if (data && !utilitiesOpen) {
    const { id, category } = data;
    // console.log('%cstyle as selected', 'color: red', id, category);
    styleSelectedFeature(id, category);
  }

  loading.end(loadingMsg);
};

// set selectFeature function on window in order to add to onclick handler within HTML for clustered markers
window.__selectFeature = selectFeature;

export default selectFeature;
