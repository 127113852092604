import { createSlice } from '@reduxjs/toolkit';
import { utilityList } from '../config/layers/raster';
import { getUrlState } from '../utils/appSetup/setStateFromUrl';

const initialState = {
  walk: false,
  bike: false,
  bus: false,
  parking: false,
  informationCenter: false,
  academic: false,
  studentLife: false,
  housing: false,
  healthCare: false,
  athletic: false,
  campusLife: null,
  sidebar: true,
  selected: null,
  utilitiesOpen: false,
  utilitiesLegendSidebar: false,
  emergencyPhones: false,
  underConstructionState: true,
  retail: false,
  dining: false,
  // set every utility to false by default
  utilities: Object.fromEntries(utilityList.map(({ layerId }) => [layerId, false])),
  parcel: false,
  aerialImagery: false,
  satellite: false,
  utilityLegend: false,
  ocf: false, // off campus facilities
};

// overwrite any values that were set in the URL's query params
const initialStateFromUrl = { ...initialState, ...getUrlState() };

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialStateFromUrl,
  reducers: {
    toggleLayer: (state, { payload: category }) => {
      state[category] = !state[category];
    },
    toggleSidebar: (state, { payload: toggled }) => {
      state.sidebar = toggled;
    },
    toggleSection: (state, { payload: section }) => {
      state[section] = !state[section];
    },
    toggleUtilitiesSidebar: (state, { payload }) => {
      state.utilitiesLegendSidebar = payload;
    },
    toggleUtility: (state, { payload: utilityId }) => {
      state.utilities[utilityId] = !state.utilities[utilityId];
    },
    toggleUi: (state, { payload: id }) => {
      state[id] = !state[id];
    },
    setSelected: (state, { payload: selected }) => {
      state.selected = selected;
    },
    setUi: (state, { payload: { id, value } }) => {
      state[id] = value;
    },
  },
});

export const {
  setStateFromUrl,
  toggleLayer,
  toggleSidebar,
  toggleSection,
  toggleUtilitiesSidebar,
  toggleUtility,
  toggleUi,
  setUi,
  setSelected,
} = uiSlice.actions;

// selectors

export const getUiState = (state) => state.ui;
export const getUiStateById = (id) => (state) => state.ui[id];
export const getSelected = (state) => state.ui.selected;
export const getDestinationsState = ({ ui: { informationCenter, academic, studentLife, housing, healthCare, athletic } }) => ({ informationCenter, academic, studentLife, housing, healthCare, athletic });
export const getUtilitiesState = ({ ui: { utilities } }) => utilities;
export const getUtilitiesOpen = ({ ui: { utilitiesOpen } }) => utilitiesOpen;
export const getBikeState = ({ ui: { bike } }) => bike;
export const getBusState = ({ ui: { bus } }) => bus;
export const selectSidebarOpen = ({ ui: { sidebar } }) => sidebar;
export const selectOffCampusFacilitiesState = ({ ui: { ocf } }) => ocf;
export const selectZoom = ({ ui: { zoom } }) => zoom;

export default uiSlice.reducer;
