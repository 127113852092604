import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Show from '../Show';
import { getContrastColor } from '../../utils/accessibility/colorContrast';

const getStyles = ({ color }) => ({
  // search results
  resultWrapper: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #ddd',
    justifyContent: 'space-evenly',
    minHeight: '65px',
  },
  abbrWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '40%',
    minWidth: '120px',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  abbr: {
    fontVariant: 'all-petite-caps',
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: getContrastColor(color),
  },
  abbrBg: {
    width: '30px',
    height: '30px',
    borderRadius: '100px',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    background: color,

  },
  layerName: {
    fontVariant: 'all-petite-caps',
  },
  idWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '50%',
  },
  id: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px',
  },
  resultHeading: {
    fontSize: 'medium',
    fontVariant: 'all-petite-caps',
    fontWeight: 'bold',
  },
});

const abbreviations = {
  Communications: { abbr: 'c', color: '#e69900' },
  CombinedSewer: { abbr: 'cs', color: '#99e600' },
  CompressedGas: { abbr: 'cg', color: '#c300ff' },
  Electric: { abbr: 'e', color: '#a80000' },
  Fuel: { abbr: 'f', color: '#f6eb14' },
  HeatingandCooling: { abbr: 'hc', color: '#6fb0ff' },
  Stormwater: { abbr: 'sw', color: '#096735' },
  Water: { abbr: 'w', color: '#005dcf' },
};

const UtilitiesSearchResult = ({ option, ...props }) => {
  const { network_name, layer_name, source_id, aim_asset_number, rcms_id } = option;
  const { abbr, color } = abbreviations[network_name];
  const styles = getStyles({ color });
  return (
    <Box sx={styles.resultWrapper} {...props}>
      <Box sx={styles.abbrWrapper}>
        <Box sx={styles.abbrBg}>
          <span style={styles.abbr}>{abbr}</span>
        </Box>
        <Typography style={styles.layerName}>{layer_name}</Typography>
      </Box>
      <Box sx={styles.idWrapper}>
        <Show test={source_id}>
          <Typography sx={styles.id}>
            {source_id}<span style={styles.resultHeading}>SourceID</span>
          </Typography>
        </Show>
        <Show test={aim_asset_number}>
          <Typography sx={styles.id}>
            {aim_asset_number}<span style={styles.resultHeading}>AIMAssetNumber</span>
          </Typography>
        </Show>
        <Show test={rcms_id}>
          <Typography sx={styles.id}>
            {rcms_id}<span style={styles.resultHeading}>RCMS_ID</span>
          </Typography>
        </Show>
      </Box>
    </Box>
  );
};

export default UtilitiesSearchResult;
