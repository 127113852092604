import emptyIcon from '../../../assets/empty_icon.png';
import selectFeature from '../../selectFeature';
import createLabelContent from './createLabelContent';

const createLabelMarkers = (geojson, { underConstructionState, underConstructionLevel }) => {
  const { google, MarkerWithLabel } = window;

  // initialize markers array to send to clusterer
  const markers = [];
  /* --------- ADD BUILDING LABELS --------- */
  geojson.features.forEach((feature) => {
    // const { properties, geometry } = feature;
    const { properties } = feature;
    const center = { lat: properties.lat, lng: properties.lng };
    const { id, name, category, labelRank, underConstruction } = properties;
    let { categories } = properties;

    try {
      // if building is a SUPT building (which has a labelRank of 0 via the data sync process), don't create a label
      if (!labelRank) return;
      // add categories (to be used for adding icons to label)
      categories = category === 'building' ? categories || [] : ['parking'];
      // create html content for label
      const { labelClass, labelContent } = createLabelContent({ id, name, category, categories, underConstruction });

      // Build label
      const marker = new MarkerWithLabel({
        id,
        position: center,
        labelContent,
        labelClass,
        labelRank: underConstructionLevel && underConstructionState && underConstruction ? labelRank + 20 : labelRank,
        labelAnchor: new google.maps.Point(50, (Math.round((name.length + (categories.length * 3)) / 20) * 10)),
        icon: {
        // using local asset (won't show)
          url: emptyIcon,
          size: new google.maps.Size(0, 0),
        },
        alt: { id, name, category, categories, underConstruction },
      });

      // add click listener to select feature when label is clicked on
      marker.addListener('click', () => selectFeature({ id, category }));

      markers.push(marker);
    } catch (error) {
      console.warn(`ERROR creating marker label for ${category} feature w/ id of ${id}\n`, properties, error);
    }
  });
  return markers;
};

export default createLabelMarkers;
