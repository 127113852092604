import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUiState, setSelected } from '../slices/ui';
import { resetInfoDrawerState, selectInfoDrawerCurrentIndex, selectInfoDrawerFeatures } from '../slices/infoDrawer';
import { revertFeatureStyle, setSelectedFeatureOnMap } from '../utils/layers/vectorLayers';

export const closeDrawer = createAsyncThunk(
  'infoDrawer/closeDrawer',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setSelected(null));

    const uiState = getUiState(thunkAPI.getState());
    const { utilitiesOpen } = uiState;
    const currentIndex = selectInfoDrawerCurrentIndex(thunkAPI.getState());
    const features = selectInfoDrawerFeatures(thunkAPI.getState());

    const feature = features[currentIndex];
    if (feature) {
      const { id, category } = feature;
      if (utilitiesOpen) {
        revertFeatureStyle(id, category);
        setSelectedFeatureOnMap();
      } else {
        switch (category) {
          case 'building':
          case 'parking':
          case 'parcel':
            revertFeatureStyle(id, category);
            break;
          default:
        }
      }
    }

    thunkAPI.dispatch(resetInfoDrawerState());
    thunkAPI.dispatch(setSelected(null));
  },
);
