export default (zoom = 8, lat = 40.2488462127653, lng = -74.70583984375003, satellite) => ({
  center: { lat: Number(lat), lng: Number(lng) },
  zoom: Number(zoom),
  maxZoom: 21,
  disableDefaultUI: true,
  mapTypeId: satellite ? window.google.maps.MapTypeId.SATELLITE : window.google.maps.MapTypeId.ROADMAP,
  streetViewControl: true,
  rotateControl: true,
  rotateControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
  },
  gestureHandling: 'greedy',
  minZoom: 8,
  clickableIcons: false,
  styles: [
    {
      featureType: 'administrative.locality',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
});
