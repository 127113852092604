import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    open: false,
    alertType: null,
    msg: '',
  },
  reducers: {
    openSnackbar: (state, { payload: { alertType, msg } }) => {
      state.open = true;
      state.alertType = alertType;
      state.msg = msg;
    },
    closeSnackbar: (state) => {
      state.open = false;
      state.alertType = null;
      state.msg = '';
    },
  },
});

// action creators
export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

// selectors
export const getSnackbarState = (state) => state.snackbar;

export default snackbarSlice.reducer;
