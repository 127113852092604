import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';

const skipNavDrawerClass = 'skipNavDrawer';

const SkipNav = () => {
  const testFocus = () => {
    const wrapper = document.getElementById('skipnav');
    if (wrapper.classList.contains(skipNavDrawerClass)) wrapper.classList.remove(skipNavDrawerClass);
  };

  const testBlur = () => {
    const wrapper = document.getElementById('skipnav');
    wrapper.classList.add(skipNavDrawerClass);
  };

  const clickHandler = ({ target }) => {
    const id = target.getAttribute('data-id');
    // if it is the "skip to map" button, use custom selector since Google Maps doesn't clearly identify the selectable map div
    const el = id === 'map' ? document.querySelector('#map-canvas [tabindex="0"]') : document.getElementById(id);
    el.focus();
  };

  return (
    <Drawer open anchor="top" id="skipnav" variant="persistent" className={skipNavDrawerClass}>
      <Paper>
        <Button
          className="skipnav-button"
          onFocus={testFocus}
          data-id="search"
          onBlur={testBlur}
          onClick={clickHandler}
        >
          Skip to Search
        </Button>
        <Button
          className="skipnav-button"
          onFocus={testFocus}
          data-id="map"
          onBlur={testBlur}
          onClick={clickHandler}
        >
          Skip to Map
        </Button>
      </Paper>
    </Drawer>
  );
};

export default SkipNav;
