import { createTheme } from '@mui/material/styles';
import { Red, LightGrey } from './config/colors';

const theme = createTheme({
  palette: {
    primary: Red,
    secondary: LightGrey,
  },
  typography: {
    h2: {
      fontSize: '1.5rem',
      fontAlign: 'center',
      fontFamily: 'Arial,sans-serif',
      fontWeight: 'lighter',
      marginTop: '0.83em',
      marginBottom: '0.5em',
    },
  },
  components: {
    // EXAMPLE
    // MuiToolbar: {
    //   variants: [
    //     {
    //       props: { variant: 'thin' },
    //       style: {
    //         { minHeight: { xs: '0px' }},
    //       },
    //     },
    //   ],
    //   styleOverrides: {
    //     root: {
    //       background: 'blue',
    //     },
    //   },
    // },
  },
});

export default theme;
