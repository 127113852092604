import { addMarkerManagedLayer } from '../../layers/markerLayers';
import { getMap } from '../mapRef';

import { districts } from '../../../config/locations';
import markerConfig from '../../../config/layers/marker';

function createDistrictLabel(details) {
  const { google, MarkerWithLabel } = window;
  const { id, title, labelPosition, position, zoom } = details;
  const map = getMap();

  const icon = {
    path: google.maps.SymbolPath.CIRCLE,
    scale: 10,
    fillOpacity: 0,
    fillColor: '#cc0033',
    strokeWeight: 0,
    labelOrigin: new google.maps.Point(0, 40),
  };

  // clickable region
  const shape = {
    coords: [1, 1, 1, 100, 100, 100, 100, 1],
    type: 'poly',
  };

  const labelContent = `<button tabindex="0" style="max-width: 100px;" class="district-label" aria-label="Zoom to ${title}">${title}</button>`;

  // Build label
  const districtLabel = new MarkerWithLabel({
    position: labelPosition,
    labelContent,
    value: id,
    icon,
    shape,
    title,
  });

  districtLabel.addListener('click', () => {
    map.panTo(position);
    map.setZoom(zoom);
  });
  return districtLabel;
}

export default function addDistrictMarkers() {
  const districtMarkers = Object.values(districts).map(createDistrictLabel);
  const { level } = markerConfig.districts;
  addMarkerManagedLayer('districts', districtMarkers, level.min, level.max);
}
