const msgs = ['Loading Application...'];

export default {
  start: (loadingMsg) => {
    const msg = `${loadingMsg}...`;
    msgs.push(msg);
    document.querySelector('#loading-text h1').textContent = `${msg}...`;
    document.getElementById('loading-overlay').classList.add('loading');
  },
  end: (loadingMsg) => {
    const msg = `${loadingMsg}...`;
    const index = msgs.indexOf(msg);
    if (index > -1) msgs.splice(index, 1);
    const { length } = msgs;
    if (!length) document.getElementById('loading-overlay').classList.remove('loading');
    else document.querySelector('#loading-text h1').textContent = msgs[length - 1];
  },
};
