import { renderToString } from 'react-dom/server';
import { InfoLabelIcon, StudentLifeLabelIcon, HealthcareLabelIcon, HousingLabelIcon, AcademicLabelIcon, AthleticLabelIcon, ParkingLabelIcon } from '../../../components/Sidebar/SidebarIcons';
import BUCMarker from '../../../components/Sidebar/BUCMarker';

// helper function to create icons for labels
const getCategoryIcons = (categories = []) => renderToString((
  <>
    {categories.map((category) => {
      switch (category) {
        case 'academic':
          return <AcademicLabelIcon key={`${category}-icon`} />;
        case 'healthCare':
          return <HealthcareLabelIcon key={`${category}-icon`} />;
        case 'housing':
          return <HousingLabelIcon key={`${category}-icon`} />;
        case 'studentLife':
          return <StudentLifeLabelIcon key={`${category}-icon`} />;
        case 'informationCenter':
          return <InfoLabelIcon key={`${category}-icon`} />;
        case 'athletic':
          return <AthleticLabelIcon key={`${category}-icon`} />;
        case 'parking':
          return <ParkingLabelIcon key={`${category}-icon`} />;
        case 'underConstruction':
          return (
            <>
              <BUCMarker key={`${category}-icon`} />
              <br />
            </>
          );
        default:
          return null;
      }
    })}
  </>
));

export default getCategoryIcons;
