import store from '../../store';
import { openSnackbar } from '../../slices/snackbar';

export default async function fetchUtilityDetails(id) {
  try {
    const result = await fetch(`/api/utilities/${id}`).then((resp) => resp.json());
    if (!result) return;
    return result;
  } catch (e) {
    console.log('ERROR requesting utility feature details w/ id of', id, e);
    store.dispatch(openSnackbar({ msg: 'Error fetching utility details' }));
  }
}
