import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUiStateById, getUtilitiesOpen, toggleUi } from '../slices/ui';
import config from '../config';
import { removeMarkerLayer, showMarkerLayer } from '../utils/layers/markerLayers';
import { getMap } from '../utils/map/mapRef';
import { addTileLayer, removeTileLayer } from '../utils/layers/rasterLayers';
import { restyleVectorLayer, toggleVectorLayer } from '../utils/layers/vectorLayers';
import { reDrawClusterLayer } from '../utils/layers/clusterLayers';
import { toggleOffBusesForCampus, toggleOnBusesForCampus } from '../utils/buses/busLayers';
import { selectCampus } from '../slices/view';

const { destinations } = config;

const destinationsSet = new Set(destinations.map(({ id }) => id));

const isDestination = (category) => destinationsSet.has(category) && category;

export const toggleLayerThunk = createAsyncThunk(
  'ui/toggleLayer',
  async (category, thunkAPI) => {
    // update the state of the toggle
    thunkAPI.dispatch(toggleUi(category));
    // get the new toggled state
    const toggled = getUiStateById(category)(thunkAPI.getState());

    if (process.env.REACT_APP_LOCAL) console.log(`toggling ${category}`);

    switch (category) {
      case 'parking':
      case isDestination(category):
        restyleVectorLayer('main');
        break;
      case 'walk':
      case 'bike':
        if (toggled) await addTileLayer(category);
        else removeTileLayer(category);
        break;
      case 'bus': {
        const campus = selectCampus(thunkAPI.getState());
        if (toggled) toggleOnBusesForCampus(campus);
        else toggleOffBusesForCampus(campus);
        break;
      }
      case 'emergencyPhones':
      case 'dining':
      case 'retail':
        if (toggled) await showMarkerLayer(category);
        else removeMarkerLayer(category);
        break;
      case 'underConstructionState': {
        reDrawClusterLayer('mainLabels', toggled);
        break;
      }
      case 'parcel': {
        const utilitiesOpen = getUtilitiesOpen(thunkAPI.getState());
        if (utilitiesOpen) {
          if (toggled) addTileLayer(category);
          else removeTileLayer(category);
        } else if (toggled) await toggleVectorLayer(category, true);
        else toggleVectorLayer(category);
        break;
      }
      default:
    }

    window.google.maps.event.trigger(getMap(), 'bounds_changed');
  },
);
