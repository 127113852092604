import store from '../../../store';
import { isWithinBUCLevel } from '../../boundsChange/getPhoneBUCLevel';
import { getMap } from '../../map/mapRef';
import getCategoryIcons from './getCategoryIcons';

export default function createLabelContent({ id, name, category, categories, isCluster, underConstruction }) {
  // if underConstruction is true, add underConstruction to the categories array
  if (underConstruction) {
    const { underConstructionState } = store.getState().ui;
    const zoom = getMap().getZoom();
    const firstCategory = categories[0];
    const withinBucLevel = isWithinBUCLevel(zoom);
    if (withinBucLevel && underConstructionState && firstCategory !== 'underConstruction') {
      categories.unshift('underConstruction');
    } else if ((!withinBucLevel || !underConstructionState) && firstCategory === 'underConstruction') {
      categories.shift();
    }
  }
  // this triggers the selectFeature function in /src/utils/selectFeature.js
  const clusterClickHandler = `__selectFeature({ id: '${id}', category: '${category}' })`;
  const classes = ['building-labels'];
  if (categories.length) classes.push('category-label');
  // ? this isn't currently being used anymore, probably remove it
  if (isCluster) classes.push('main-cluster-label');

  const labelClass = classes.join(' ');

  // create anchor tag for label with icons (if it has categories)
  const labelContent = `
    <button
      tabindex="0"
      class="${labelClass}"
      aria-label="Select ${name}" data-id="${id}"
      onclick="${clusterClickHandler}"
    >
    ${categories.length ? `${getCategoryIcons(categories)}` : ''}${name}
    </button>
  `;
  return { labelClass, labelContent };
}
