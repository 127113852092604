import config from '../../config';

const { zoomLevel } = config;

// camden: { features: 13, districts: 13.5 },
// newBrunswick: { features: 14, districts: 12 },
// newark: { features: 16, districts: 14 },

export default function getViewLevel(zoom) {
  if (zoom >= zoomLevel.features.min) return 'features';
  if (zoom >= zoomLevel.districts.min) return 'districts';
  return 'state';
}
