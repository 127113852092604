import { openSnackbar } from '../../slices/snackbar';
import store from '../../store';
import queryFeaturesByExtent from './queryFeaturesByExtent';

// click query (when in utilities mode)
export default async function utilityClickQuery({ zoom, lat, lng, parcel, networks = [] }) {
  try {
    if (!Array.isArray(networks)) networks = [networks];
    const features = await queryFeaturesByExtent({ lat, lng, zoom, networks, parcel });
    return features;
  } catch (e) {
    console.log('ERROR: click query to AGOL failed', e);
    store.dispatch(openSnackbar({ msg: 'Error querying click location. Please try again.' }));
  }
}
