import { getMap } from '../../map/mapRef';
import emptyIcon from '../../../assets/empty_icon.png';
import calculator from './calculator';
import createLabelMarkers from './createLabelMarkers';
import store from '../../../store';
import { isWithinBUCLevel } from '../../boundsChange/getPhoneBUCLevel';

const createLabelClusterer = (data) => {
  const { StyledMarkerClusterer } = window;
  const map = getMap();

  const { ui: { underConstructionState } } = store.getState();
  const zoom = map.getZoom();
  const underConstructionLevel = isWithinBUCLevel(zoom);
  // create markers only if we are within zoom.

  const markers = createLabelMarkers(data, { underConstructionState, underConstructionLevel });

  const clusterer = new StyledMarkerClusterer(map, markers, {
    // empty image so no marker will show, just the label
    imagePath: emptyIcon,
    clusterClass: 'building-labels',
    gridSize: 90,
    zoomOnClick: false,
    styles: [{ height: 70, width: 100 }],
  });

  // set Marker Clusterer calculator callback function
  clusterer.setCalculator(calculator);
  return { markers, clusterer };
};

export default createLabelClusterer;
