import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import UtilityLegendSection from './UtilityLegendSection';
import getUtiltiesLegend from '../../utils/internalAgolData/getUtilitiesLegend';
import { getUtilitiesState } from '../../slices/ui';

const styles = {
  root: {
    width: '100%',
  },
};

let exists = false;

const UtilityLegend = () => {
  const utilities = useSelector(getUtilitiesState);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);

  // little fix to prevent memory leak if component unmounts prior to completing saga
  useEffect(() => {
    exists = true;
    return () => { exists = false; };
  }, []);

  // load in legend icons for each network
  useEffect(() => {
    const activeNetworks = [];
    Object.entries(utilities).forEach(([network, toggled]) => {
      if (toggled) activeNetworks.push(network);
    });
    if (activeNetworks.length) {
      setLoading(true);

      getUtiltiesLegend(activeNetworks)
        .then((data) => {
          if (exists) {
            if (data) setSections(data);
            else {
              // TODO: alert user that something went wrong
            }
            setLoading(false);
          }
        });
    } else setSections([]);
  }, [utilities]);

  if (loading) return 'Loading Legend...';

  return (
    <div style={styles.root}>
      {sections.map((data, i) => <UtilityLegendSection data={data} i={i} key={data.network} />)}
    </div>
  );
};

export default UtilityLegend;
