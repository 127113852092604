import { setUi } from '../../slices/ui';
import { setCampus, setViewLevel } from '../../slices/view';
import store from '../../store';
import getCampus from '../boundsChange/getCampus';
import getViewLevel from '../boundsChange/getViewLevel';
import { toggleOffBusesForCampus, toggleOnBusesForCampus } from '../buses/busLayers';
import { toggleClusterLayer, reDrawClusterLayer, getClusterer } from '../layers/clusterLayers';
import { showMarkerLayer, removeMarkerLayer } from '../layers/markerLayers';
import { hideTileLayers, showTileLayers } from '../layers/rasterLayers';
import { hideVectorLayers, showVectorLayers } from '../layers/vectorLayers';
import { getMap } from '../map/mapRef';
import syncUrlWithState from '../syncUrl';
import { addClickBoxListener, removeClickBoxListener } from './utilityClickBoxListener';
import { isWithinPhoneLevel, isWithinBUCLevel } from '../boundsChange/getPhoneBUCLevel';
import { closeDrawer } from '../../thunks/drawerClose';

const showHide = (level, uiState, map = null) => {
  const { utilitiesOpen, ocf, dining, retail } = uiState;
  // * adding features
  if (map) {
    showVectorLayers(level, uiState);
    if (level === 'features') {
      showTileLayers();
      if (dining) showMarkerLayer('dining');
      if (retail) showMarkerLayer('retail');
      if (utilitiesOpen) {
        addClickBoxListener();
      } else { // * if not in utilities mode
        toggleClusterLayer('mainLabels', true);
      }
    } else if (ocf) toggleClusterLayer('ocf', true);
  } else { // * removing features
    hideVectorLayers(level, uiState);
    if (level === 'features') {
      hideTileLayers();
      if (dining) removeMarkerLayer('dining');
      if (retail) removeMarkerLayer('retail');
      if (utilitiesOpen) {
        removeClickBoxListener();
      } else { // * if not in utilities mode
        toggleClusterLayer('mainLabels', false);
      }
    } else if (ocf) toggleClusterLayer('ocf', false);
  }
};

// track whether OCF clusters are currently being updated to prevent memory leak
let ocf_clusters_updating = false;

const addBoundsChangeListener = (map) => map.addListener('bounds_changed', async () => {
  const { view: prev, ui: uiState } = store.getState();
  const zoom = map.getZoom();
  const center = map.getCenter().toJSON();
  const currentCampus = getCampus(zoom);
  const currentLevel = getViewLevel(zoom);
  const isPrevZoomWithinPhoneLevel = isWithinPhoneLevel(uiState.zoom);
  const isCurrZoomWithinPhoneLevel = isWithinPhoneLevel(zoom);
  const isPrevZoomWithinBUCLevel = isWithinBUCLevel(uiState.zoom);
  const isCurrZoomWithinBUCLevel = isWithinBUCLevel(zoom);

  const change = {
    campus: prev.campus !== currentCampus,
    level: prev.level !== currentLevel,
    emPhoneLevel: isPrevZoomWithinPhoneLevel !== isCurrZoomWithinPhoneLevel,
    underConstructionZoomLevel: isPrevZoomWithinBUCLevel !== isCurrZoomWithinBUCLevel,
  };

  if (process.env.REACT_APP_LOCAL) {
    // if (change.campus) console.log('campus changed from', prev.campus, 'to', currentCampus);
    if (change.level) console.log('level changed from', prev.level, 'to', currentLevel);
  }

  if (change.emPhoneLevel) {
    if (isCurrZoomWithinPhoneLevel && uiState.emergencyPhones) showMarkerLayer('emergencyPhones');
    else removeMarkerLayer('emergencyPhones');
  }

  // only redraw if zooming in/out & toggled on
  if (change.underConstructionZoomLevel && uiState.underConstructionState) {
    // add icon & rank when zooming in & toggled on
    // remove icon & rank when zooming out & toggled on
    const isAdding = isCurrZoomWithinBUCLevel && uiState.underConstructionState;
    if (!uiState.utilitiesOpen) reDrawClusterLayer('mainLabels', isAdding);
  }

  if (change.campus) {
    store.dispatch(setCampus(currentCampus));
    // show/hide bus routes on the map (if toggled)
    if (uiState.bus) {
      toggleOffBusesForCampus(prev.campus);
      toggleOnBusesForCampus(currentCampus);
    }
  }

  if (change.level) {
    store.dispatch(setViewLevel(currentLevel));
    if (currentLevel !== 'features') store.dispatch(closeDrawer());
    showHide(prev.level, uiState);
    showHide(currentLevel, uiState, map);
  }

  if (uiState.ocf && zoom < 14 && !ocf_clusters_updating) {
    // console.log('\n\nOCF TRIGGER\n\n')
    ocf_clusters_updating = true;
    window.google.maps.event.addListenerOnce(map, 'idle', async () => {
      // toggle on/off OCF labels
      await toggleClusterLayer('ocf', false);
      await toggleClusterLayer('ocf', true);
      window.google.maps.event.addListenerOnce(map, 'clusters_drawn', () => {
        const map = getMap();
        const zoom = map.getZoom();
        if (zoom > 8 && zoom < 14) {
          setTimeout(() => {
            const labels = document.getElementsByClassName('OCF-marker-label');
            // console.log('adding labels', labels);
            for (const el of labels) {
              const id = el.firstElementChild.getAttribute('data-id');
              // but also, keep main campus labels hidden
              if (!['NWK', 'CAM', 'NB'].includes(id)) el.classList.add('displayBlock');
            }
            ocf_clusters_updating = false;
          }, 100);
        } else if (zoom <= 8) {
          setTimeout(() => {
            const labels = document.getElementsByClassName('OCF-marker-label');
            // console.log('removing labels', labels);
            for (const el of labels) {
              const id = el.firstElementChild.getAttribute('data-id');
              // but also, keep main campus labels hidden
              if (!['NWK', 'CAM', 'NB'].includes(id)) el.classList.remove('displayBlock');
            }
            ocf_clusters_updating = false;
          }, 100);
        }
      });
    });
  }

  store.dispatch(setUi({ id: 'zoom', value: zoom }));

  // hacky fix for clusters not repainting on zoom
  if (currentLevel === 'features') {
    window.google.maps.event.addListenerOnce(map, 'clusters_drawn', () => {
      const mainLabelClusterer = getClusterer('mainLabels').clusterer;
      if (mainLabelClusterer) {
        mainLabelClusterer.repaint();
      }
    });
  }

  syncUrlWithState(center);
});

export default addBoundsChangeListener;
