// set of functions to set text to be black or white depending on background color

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}

function setContrast({ r, g, b }) {
  // http://www.w3.org/TR/AERT#color-contrast
  const o = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
  return (o > 125) ? 'black' : 'white';
}

export const getContrastColor = (hex) => setContrast(hexToRgb(hex));

// setContrast(hexToRgb('#00ffff'));
