import isMainCampus from '../../isMainCampus';
import { mainCampuses } from '../../../config/locations';

// track whether main campus has been added already (NB/NWK have seperate points bc of districts)
const mainCampusDuplicateTracker = new Set();

export default function createOfcMarker({ campus, site_id, latLng }) {
  const { google, MarkerWithLabel } = window;
  let icon = {
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 5,
    strokeWeight: 0,
    fillOpacity: 1,
    fillColor: '#007fac',
    // labelOrigin: new google.maps.Point(0, 50),
  };

  // variable to be set to a main campus position (different from centroid)
  let position;

  // if it is a main campus, handle it differently
  if (isMainCampus(site_id)) {
    // if the main campus has already been added, disregard it;
    if (mainCampusDuplicateTracker.has(site_id)) return;
    // get the position currently being used to for the main campus (as opposed to its true centroid)
    ({ position } = Object.values(mainCampuses).find((campus) => campus.abbr === site_id));
    // set icon styles to reflect that it is a main campus
    icon = {
      ...icon,
      fillColor: 'red',
      scale: 0,
    };
    // add campus to the set to track that it's been added already (NB/NWK have seperate points bc of districts)
    mainCampusDuplicateTracker.add(site_id);
  }

  const text = campus;

  const labelContent = `<button tabindex="0" class="cluster-markers-label" aria-label="Zoom to ${campus}" data-id="${site_id}">${text}</button>`;

  const options = {
    position: position || latLng,
    labelContent,
    id: site_id,
    campus,
    labelAnchor: new google.maps.Point(55, -5),
    labelClass: 'OCF-marker-label',
    icon,
    title: campus,
  };

  // Build label
  const marker = new MarkerWithLabel(options);

  return marker;
}
