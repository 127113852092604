import Box from '@mui/material/Box';

const styles = {
  root: {
    padding: '20px',
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  attribute: {
    padding: '5px 10px',
    width: '220px',
    display: 'flex',
    flexDirection: 'column',
  },
};

const fields = [
  { id: 'currentOwner', name: 'Current Owner', agolId: 'CurrentOwn' },
  { id: 'previousOwner', name: 'Previous Owner', agolId: 'PreviousOw' },
  { id: 'pin', name: 'PIN', agolId: 'PIN' },
  { id: 'pamsPin', name: 'PAMS_PIN', agolId: 'PAMS_PIN' },
  { id: 'block', name: 'Block', agolId: 'BLOCK' },
  { id: 'lot', name: 'Lot', agolId: 'LOT' },
  { id: 'mun', name: 'MUN', agolId: 'MUN' },
  { id: 'municipality', name: 'Municipality', agolId: 'Municipali' },
  { id: 'acreage', name: 'Acreage', agolId: 'Acreage' },
  { id: 'deedDoc', name: 'Deed Document', agolId: 'Deed_Doc' },
  { id: 'deedBook', name: 'Deed Book', agolId: 'DeedBook' },
  { id: 'deedPage', name: 'Deed Page', agolId: 'DeedPage' },
  { id: 'taxMap', name: 'Tax Map', agolId: 'TaxMap' },
  { id: 'transDate', name: 'Transaction Date', agolId: 'TransDate', date: true },
  { id: 'bogVsBot', name: 'BOGvsBOT', agolId: 'BOGvsBOT' },
];

const ParcelContent = ({ details }) => (
  <Box sx={styles.root}>
    <Box id="main-details" sx={styles.section}>
      {fields.map(({ id, name, agolId }) => (
        <Box sx={styles.attribute} key={id}>
          <strong>{name}</strong>{details[agolId]}
        </Box>
      ))}
    </Box>
  </Box>
);

export default ParcelContent;
