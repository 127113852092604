import { addMarkerManagedLayer } from '../../layers/markerLayers';
import { getMap } from '../mapRef';

import { mainCampuses } from '../../../config/locations';
import { isMobile } from '../../browserAndDeviceTests';

export default function addCampusMarkers() {
  const { google, MarkerWithLabel } = window;
  const campusMarkers = [];

  Object.values(mainCampuses).forEach((details) => {
    const { title, position, value, zoom, mobileZoom } = details;

    const icon = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 10,
      fillOpacity: 1.0,
      fillColor: '#cc0033',
      strokeWeight: 1,
      strokeColor: '#cc0033',
      labelOrigin: new google.maps.Point(0, 3),
    };

    // clickable region
    const shape = {
      coords: [1, 1, 1, 100, 100, 100, 100, 1],
      type: 'poly',
    };

    const labelContent = `<button type="button" tabindex="0" class="campus-marker-label" aria-label="Zoom to ${title}">${title}</button>`;

    // Build label
    const marker = new MarkerWithLabel({
      position,
      labelContent,
      id: value,
      labelAnchor: new google.maps.Point(title.length * 4, -15),
      labelClass: 'campus-marker-with-label',
      icon,
      shape,
      title,
    });

    google.maps.event.addListener(marker, 'click', () => {
      const map = getMap();
      map.setOptions({ center: position, zoom: isMobile() ? mobileZoom : zoom });
    });

    campusMarkers.push(marker);
  });

  addMarkerManagedLayer('campuses', campusMarkers);
}
