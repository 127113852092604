export default [
  {
    title: 'Rutgers University-Camden',
    id: 'Rutgers-University-Camden',
  },
  {
    title: 'Rutgers University-Newark: Overview',
    id: 'Rutgers-University-at-Newark-Overview',
  },
  {
    title: 'Rutgers University-Newark',
    id: 'Rutgers-University-Newark',
  },
  {
    title: 'Rutgers Biomedical and Health Sciences at Newark',
    id: 'Rutgers-Biomedical-and-Health-Sciences-at-Newark',
  },
  {
    title: 'Rutgers University-New Brunswick: Overview',
    id: 'Rutgers-University-New-Brunswick-Overview',
  },
  {
    title: 'Rutgers University-New Brunswick: Busch',
    id: 'Rutgers-University-New-Brunswick-Busch',
  },
  {
    title: 'Rutgers University-New Brunswick: College Avenue',
    id: 'Rutgers-University-New-Brunswick-College-Avenue',
  },
  {
    title: 'Rutgers University-New Brunswick: Cook/Douglass',
    id: 'Rutgers-University-New-Brunswick-Cook-Douglass',
  },
  {
    title: 'Rutgers University-New Brunswick: Livingston',
    id: 'Rutgers-University-New-Brunswick-Livingston',
  },
  {
    title: 'Rutgers University-New Brunswick: Downtown',
    id: 'Rutgers-University-New-Brunswick-Downtown',
  },
];
