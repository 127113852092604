import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { openModal } from '../slices/modal';

const styles = {
  footer: {
    fontSize: 12,
    textAlign: 'center',
    color: '#5f6a72',
  },
  disclaimer: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline',
    margin: 0,
    padding: 0,
    marginLeft: 5,
    fontSize: '1em',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
};

const Footer = () => {
  const dispatch = useDispatch();

  const handleDiclaimerClick = (e) => {
    e.preventDefault();
    dispatch(openModal({ category: 'DISCLAIMER' }));
  };

  const currentYear = new Date().getFullYear();

  return (
    <Typography sx={styles.footer}>
      Copyright ©${currentYear}, Rutgers, The State University of New Jersey, an equal opportunity, affirmative action institution. All rights reserved.
      <button type="button" style={styles.disclaimer} onClick={handleDiclaimerClick}>Read Map Disclaimer</button>
    </Typography>
  );
};

export default Footer;
