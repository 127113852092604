import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Paper from '@mui/material/Paper';

import TitleBar from './TitleBar';
import CampusLife from './DrawerContent/CampusLife';
import Building from './DrawerContent/Building';
import Parking from './DrawerContent/Parking';
import UtilityDrawer from './DrawerContent/Utility/UtilityDrawer';
import Parcels from './DrawerContent/Parcels';
import Bus from './DrawerContent/Bus';
import DefaultInfoDrawer from './DrawerContent/DefaultInfoDrawer';

import Show from '../Show';

import { setSelected } from '../../slices/ui';

import { setSelectedFeatureOnMap } from '../../utils/layers/vectorLayers';
import { focusDrawerControls } from '../../utils/accessibility/drawerNavigation';

import rastersLayers from '../../config/layers/raster';

const styles = {
  root: {
    width: '100vw',
    // gridArea: 'infodrawer',
    overflowY: 'scroll',
    maxHeight: { sm: '36vh', md: 'auto' },
  },
  title: {
    textAlign: 'center !important',
  },
  close: {
    top: '0px',
    right: '20px',
    fontSize: '1em',
    position: 'absolute',
    marginRight: '-1.4em',
    color: '#fff',
    webkitAppearance: 'none',
    borderRadius: '0px',
  },
  back: {
    top: '0px',
    left: '24px',
    color: '#fff',
    fontSize: '1.5em',
    marginLeft: '-1em',
    padding: '6px',
    webkitAppearance: 'none',
    borderRadius: '0px',
  },
};

const getContentTemplate = (category) => {
  switch (category) {
    case 'dining':
    case 'retail':
      return CampusLife;
    case 'building':
      return Building;
    case 'parking':
      return Parking;
    case 'utility':
      return UtilityDrawer;
    case 'parcel':
      return Parcels;
    case 'bus':
      return Bus;
    default:
      return DefaultInfoDrawer;
  }
};

const InfoDrawer = () => {
  const { color, features, currentIndex } = useSelector(({ infoDrawer }) => infoDrawer);

  const content = features[currentIndex];
  const utilitiesOpen = useSelector(({ ui: { utilitiesOpen } }) => utilitiesOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    focusDrawerControls();
    // scroll to top of infodrawer to fix weird scrolling bug
    const el = document.getElementById('infodrawer');
    if (el) el.scroll(0, 0);
  }, []);

  if (!content) return '';

  const { category, details, geometry, layer_name, network_name } = content;
  const id = content.id || details.id;
  let { name } = details;

  if (category === 'utility') {
    // create human-friendly version of network name and layer name
    name = `${rastersLayers[network_name].title}: ${layer_name.split('_')[1]}`;
  }
  const DrawerContent = getContentTemplate(category);

  useEffect(() => {
    if (category === 'utility') {
      dispatch(setSelected([category, id]));
    } else if (category !== 'dining' && category !== 'retail') dispatch(setSelected(id));
    // add feauture / zoom to if in utilities mode
    if (utilitiesOpen && category !== 'bus') setSelectedFeatureOnMap(geometry);
  }, [category, id]);

  return (
    <Show test={content}>
      <Paper sx={styles.root} id="infodrawer">
        <TitleBar color={details.color || color} name={name} />
        <DrawerContent details={details} />
      </Paper>
    </Show>
  );
};

export default InfoDrawer;
