import { useState } from 'react';
import { useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { openSnackbar } from '../../slices/snackbar';

const styles = {
  textField: {
    mx: 1,
    width: '90%',
  },
  dialog: {
    maxWidth: '400px',
  },
};

const FeedbackDialogue = ({ onClose }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  const handleEmailChange = ({ target: { value } }) => setEmail(value);
  const handleCommentChange = ({ target: { value } }) => setComment(value);

  const handleSubmit = () => {
    fetch('/form', {
      method: 'post',
      body: JSON.stringify({ email, comment }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    }).then((resp) => {
      if (resp.ok) {
        dispatch(openSnackbar({ alertType: 'success', msg: 'Your feedback has been submitted. Thank you.' }));
        return onClose();
      }
      throw new Error(`Feedback form returned with a ${resp.status}`);
    }).catch((error) => {
      console.error('Error sending feedback:\n', error);
      dispatch(openSnackbar({ alertType: 'error', msg: 'Uh oh, looks like something went wrong. Please try again.' }));
    });
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
      <DialogContent sx={styles.dialog}>
        <TextField
          id="email"
          sx={styles.textField}
          placeholder="Contact email"
          margin="dense"
          required
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
        <TextField
          id="comment"
          sx={styles.textField}
          placeholder="Thank you for your feedback"
          margin="dense"
          multiline
          rows="8"
          required
          name="comment"
          value={comment}
          onChange={handleCommentChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
      <DialogContent sx={styles.dialog}>
        <h2><u>Accessibility Information</u></h2>
        <p>Rutgers is an equal access/equal opportunity institution. Individuals with disabilities are encouraged to direct suggestions, comments, or complaints concerning any accessibility issues with Rutgers web sites to <a href="mailto:accessibility@rutgers.edu">accessibility@rutgers.edu</a> or complete the <a href="https://accessibility.rutgers.edu/barrierform" target="_blank" rel="noopener noreferrer">Report Accessibility Barrier or Provide Feedback Form.</a></p>
      </DialogContent>
    </>
  );
};

export default FeedbackDialogue;
